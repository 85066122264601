import {RenderData} from "../render_data/render_data";
import {WindowFragment} from "../window/window_fragment";
import {Segment, ShapeDirection} from "../render_data/enum_render";
import {drawLine} from "../mask/mask_shape";
import {maskArch} from "../mask/mask_arch";
import {PanelFragment} from "../panel/panel_fragment";
import {drawTriangleFill, drawTriangle, drawUpperTriangle} from "../mask/mask_triangle";
import {drawCircle, drawOval} from "../mask/mask_circular";
import {drawBuffer} from "../mask/mask_buffer";

//Render a selected octagon
export const renderSelectOctagon = (window: WindowFragment, renderData: RenderData) =>{
    renderSelectBuffer(window, renderData);
}

//Render a selected hexagon
export const renderSelectHexagon = (window: WindowFragment, renderData: RenderData) =>{
    renderSelectBuffer(window, renderData);
}

//Render a selected buffer
const renderSelectBuffer = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;

    drawBuffer(window.mask_buffer.inner, renderData, renderData.viewport.select_color);

    context!.lineWidth = renderData.viewport.select_outline_width;
    context!.strokeStyle = renderData.viewport.select_outline_color;  
    drawBuffer(window.mask_buffer.outer, renderData);
}

//Render a selected oval window
export const renderSelectOval = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;

    const circle = window.mask_circle;

    //Draw the inner oval
    drawOval(
        circle.center.x,
        circle.center.y,
        circle.inner_radius.x,
        circle.inner_radius.y,
        renderData,
        renderData.viewport.select_color
    );

    context!.lineWidth = renderData.viewport.select_outline_width;
    context!.strokeStyle = renderData.viewport.select_outline_color;  
    drawOval(
        circle.center.x,
        circle.center.y,
        circle.outer_radius.x,
        circle.outer_radius.y,
        renderData,        
    );
}

//Render a selected circle window
export const renderSelectCircle = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;

    //Shorthand
    const circle = window.mask_circle;

    //Draw the inner circle
    drawCircle(
        circle.center.x,
        circle.center.y,
        circle.inner_radius.x,
        renderData,
        renderData.viewport.select_color
    )

    context!.lineWidth = renderData.viewport.select_outline_width;
    context!.strokeStyle = renderData.viewport.select_outline_color;  

    drawCircle(
        circle.center.x,
        circle.center.y,
        circle.outer_radius.x,
        renderData,        
    );
}

//Rendere a selecteds straight right triangle / rake
export const renderSelectStraightRightTriangle = (window: WindowFragment, renderData: RenderData) =>{
    renderSelectTriangle(window, renderData);
    renderSelectLower(window, renderData);
}

//Render a selected right triangle
export const renderSelectRightTriangle = (window: WindowFragment, renderData: RenderData) =>{
    renderSelectTriangle(window, renderData);
}

//Render a selected Peak
export const renderSelectPeak = (window: WindowFragment, renderData: RenderData) =>{
    renderSelectTriangle(window, renderData);
    renderSelectLower(window, renderData);
}

//Render a selected triangle
export const renderSelectTriangle = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;

    drawTriangleFill(renderData, window.mask_triangle.inner_triangle, renderData.viewport.select_color);

    context!.lineWidth = renderData.viewport.select_outline_width;
    context!.strokeStyle = renderData.viewport.select_outline_color;

    if(window.model.second_height >0){
        drawUpperTriangle(renderData, window.mask_triangle.outer_triangle);
    }
    else{
        drawTriangle(renderData, window.mask_triangle.outer_triangle);
    }    
}

//Render a selected arch
export const renderSelectArch = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;
    
    context!.lineWidth = renderData.viewport.select_outline_width;
    context!.strokeStyle = renderData.viewport.select_outline_color;

    renderArchOutline(window, renderData);
    drawLine(window.model.line[Segment.BOTTOM], renderData);
    rendereArchFill(window, renderData);
}

//Render a standard selection for windows
export const renderSelectStandard = (window: WindowFragment, renderData: RenderData) =>{
    
    const context = renderData.viewport.context;

    //Draw the outline around the window
    context!.lineWidth = renderData.viewport.select_outline_width;
    context!.strokeStyle = renderData.viewport.select_outline_color;    
    context!.strokeRect(
        window.model.line[Segment.LEFT].x1,
        window.model.line[Segment.TOP].y1, 
        window.model.line[Segment.RIGHT].x1 - window!.model.line[Segment.LEFT].x1, 
        window.model.line[Segment.BOTTOM].y1 - window!.model.line[Segment.TOP].y1
    );
    
    if(window.active_panel_id >= 0){
        const panel: PanelFragment = window.panel[window.active_panel_id];

        //Select the panel of the window
        context!.fillStyle = renderData.viewport.select_color;
        context!.fillRect(          

            panel.model.line[Segment.LEFT].x1,
            panel.model.line[Segment.TOP].y1,
            panel.model.line[Segment.RIGHT].x1 - panel.model.line[Segment.LEFT].x1,
            panel.model.line[Segment.BOTTOM].y1 - panel.model.line[Segment.TOP].y1           
        );
    }
}

//Render a straight arch selection
export const renderSelectStraightArch = (window: WindowFragment, renderData: RenderData) =>{
    renderSelectArch(window, renderData);
    renderSelectLower(window, renderData);
}

//Render a half arch selection
export const renderSelectHalfArch = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;
    
    rendereArchFill(window, renderData);
    drawTriangleFill(renderData, window.mask_arch.inner_triangle, renderData.viewport.select_color);   
    
    //Draw the arch outline
    context!.lineWidth = renderData.viewport.select_outline_width;
    context!.strokeStyle = renderData.viewport.select_outline_color;    
    renderArchOutline(window, renderData);
    drawLine(window.model.line[Segment.BOTTOM], renderData);
    //Draw the sidee 
    if(window.model.shape_direction === ShapeDirection.LEFT){
        drawLine(window.model.line[Segment.RIGHT], renderData);
    }
    else{
        drawLine(window.model.line[Segment.LEFT], renderData);
    }
}

//Render a selected straight half arch
export const renderSelectStraightHalfArch = (window: WindowFragment, renderData: RenderData) =>{
    renderSelectHalfArch(window, renderData);
    renderSelectLower(window, renderData);
}

//Render the outline of an arch
const renderArchOutline = (window: WindowFragment, renderData: RenderData) =>{
    maskArch(
        window.mask_arch.center_x,
        window.mask_arch.center_y,
        window.mask_arch.radius - window.mask_arch.offset,
        window.mask_arch.radian_start,
        window.mask_arch.radian_end,
        renderData
    )
}

//Render the fill of an arch
const rendereArchFill = (window: WindowFragment, renderData: RenderData) =>{
    maskArch(
        window.mask_arch.center_x,
        window.mask_arch.center_y,
        window.mask_arch.inner_radius,
        window.mask_arch.inner_start,
        window.mask_arch.inner_end,
        renderData,
        renderData.viewport.select_color
    )   
}

//Render a lower selection
const renderSelectLower = (window: WindowFragment, renderData: RenderData) =>{
    
    const context = renderData.viewport.context;

    const panel = window.panel[0]; //Get the only panel in the window

    //Short way of getting dimensions for the lower window
    const second_top = window.model.line[Segment.TOP].y1 + window.model.second_height;        
    
    const inner_left = panel.model.line[Segment.LEFT].x1;
    const inner_right = panel.model.line[Segment.RIGHT].x1;
    const inner_top = second_top + window.mask_lower.offset_left;
    const inner_bottom = panel.model.line[Segment.BOTTOM].y1;

    //Fill the window portion with select rectangle
    context!.fillStyle = renderData.viewport.select_color;
    context!.fillRect(
        inner_left,
        inner_top,
        inner_right - inner_left,
        inner_bottom - inner_top
    );
    
    context!.lineWidth = renderData.viewport.select_outline_width;
    context!.strokeStyle = renderData.viewport.select_outline_color;    
    
    const left = window.model.line[Segment.LEFT].x1;
    const right = window.model.line[Segment.RIGHT].x1;
    const bottom = window.model.line[Segment.BOTTOM].y1;

    //Draw the outer 3 sided rectangle
    context!.beginPath();
    context!.moveTo(left, second_top);
    context!.lineTo(left, bottom);
    context!.lineTo(right, bottom);
    context!.lineTo(right, second_top);    
    context!.stroke();
}


