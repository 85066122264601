import {RenderData} from "../../render/render_components/render_data/render_data";
import {
    changePanelWidth, 
    changePanelHeight,
    changeSecondHeight
} from "../../render/render_components/window/functions_window";
import {changePanelOpen} from "../../render/render_components/panel/functions_panel";
import {ConstrainValues} from "../../constraints/constrain_value";
import {validatePosts} from "../../constraints/functions_validate_posts";
import {validateWindows} from "../../constraints/functions_validate_window";

const WindowProperties = (
    id_object: string, 
    object_value: string,
    key_id: number,
    renderData: RenderData,
    constrainValues: ConstrainValues
) =>{

    switch(id_object){
        case "input_width":
            changePanelWidth(renderData, object_value);
            validatePosts(renderData);
            validateWindows(constrainValues, renderData);            
        break;
        case "input_height":
            changePanelHeight(renderData, object_value);
            validatePosts(renderData);
            validateWindows(constrainValues, renderData);
        break;
        case "change_direction":
            const window = renderData.window[renderData.active_fragment_id];
            const panel = window.panel[window.active_panel_id];
            changePanelOpen(panel, key_id);
            renderData.render_frame = true;
        break;
        case "input_second_height":
            changeSecondHeight(renderData, object_value);
            validateWindows(constrainValues, renderData);            
        break;
    }    
}

export default WindowProperties;