import {WindowFragment, Line, initLine} from "../window/window_fragment";
import {PanelFragment, initPanelFragment} from "./panel_fragment";
import {Segment, PanelType, OpenDirection} from "../render_data/enum_render";
import {getScaleCoord} from "../model/matrix_functions";
import { 
    getWidthInch, 
    getHeightInch,    
} from "../viewport/functions_viewport";
import {RenderData} from "../render_data/render_data";

//Create a panel fragment
const createPanelFragment = (
    renderData: RenderData,
    window: WindowFragment,     
    type: PanelType,
    top: number,
    bottom: number,
    left: number,
    right: number
) =>{    
    const panel: PanelFragment = initPanelFragment();
    
    //Top
    panel.model.line[Segment.TOP].x1 = left;
    panel.model.line[Segment.TOP].y1 = top;
    panel.model.line[Segment.TOP].x2 = right;
    panel.model.line[Segment.TOP].y2 = top;
    //Bottom
    panel.model.line[Segment.BOTTOM].x1 = left;
    panel.model.line[Segment.BOTTOM].y1 = bottom;
    panel.model.line[Segment.BOTTOM].x2 = right;
    panel.model.line[Segment.BOTTOM].y2 = bottom;
    //Left
    panel.model.line[Segment.LEFT].x1 = left;
    panel.model.line[Segment.LEFT].y1 = top;
    panel.model.line[Segment.LEFT].x2 = left;
    panel.model.line[Segment.LEFT].y2 = bottom;
    //Right
    panel.model.line[Segment.RIGHT].x1 = right;
    panel.model.line[Segment.RIGHT].y1 = top;
    panel.model.line[Segment.RIGHT].x2 = right;
    panel.model.line[Segment.RIGHT].y2 = bottom;  

    //Set the default glass for the panel
    const record_outer = renderData.config_values.glass.get(panel.data.glass.outer.default_value);
    panel.data.glass.outer.value = record_outer!.description;
    panel.data.glass.outer.id = record_outer!.id;
    const record_inner = renderData.config_values.glass.get(panel.data.glass.inner.default_value);
    panel.data.glass.inner.value = record_inner!.description;
    panel.data.glass.inner.id = record_inner!.id;

    //Set the default grid type for the panel
    const record_grid = renderData.config_values.grid_type.get(panel.data.grid.grid_type.default_value);
    panel.data.grid.grid_type.value = record_grid!.description;
    panel.data.grid.grid_type.id = record_grid!.id;

    //Set the default grid pattern for the panel
    const record_pattern = renderData.config_values.grid_pattern.get(panel.data.grid.grid_pattern.default_value);
    panel.data.grid.grid_pattern.value = record_pattern!.description;
    panel.data.grid.grid_pattern.id = record_pattern!.id;    

    panel.type = type;
    panel.id = window.panel.length;
    window.panel[panel.id] = panel;

    updatePanelInch(renderData, window, panel);    
}

//Create a single panel window
export const createOnePanel = (renderData: RenderData, window: WindowFragment) =>{
    //Destroy any existing panels and posts
    clearPanels(window);
    
    createPanelFragment(
        renderData,
        window,        
        PanelType.FULL,
        window.mask_window.top,
        window.mask_window.bottom,
        window.mask_window.left,
        window.mask_window.right
    )
}

//Create a two-panel window
export const createTwoPanel = (renderData: RenderData, window: WindowFragment) =>{
    clearPanels(window);
    
    //Get the center of the window
    const center_x = (window.mask_window.left + window.mask_window.right) /2;

    //Create the post in the center
    createPost(
        window,
        window.mask_window.top,
        window.mask_window.bottom,
        center_x,
        center_x
    );    

    //Create the left panel
    createPanelFragment(
        renderData,
        window,        
        PanelType.LEFT_OF_TWO,
        window.mask_window.top,
        window.mask_window.bottom,
        window.mask_window.left,
        center_x
    );

    //Create the right panel
    createPanelFragment(
        renderData,
        window,        
        PanelType.RIGHT_OF_TWO,
        window.mask_window.top,
        window.mask_window.bottom,        
        center_x,
        window.mask_window.right
    );
}

//Create a three-panel window
export const createThreePanel = (renderData: RenderData, window: WindowFragment) =>{
    clearPanels(window);

    //Find the divisions of the window    
    const third = (window.mask_window.right - window.mask_window.left) /3;
    const left_x = third + window.mask_window.left;
    const right_x = left_x + third;
    
    //Create the left post
    createPost(
        window,
        window.mask_window.top,
        window.mask_window.bottom,
        left_x,
        left_x
    );

    //Create the right post
    createPost(
        window,
        window.mask_window.top,
        window.mask_window.bottom,
        right_x,
        right_x
    );

    //Create left panel
    createPanelFragment(
        renderData,
        window,        
        PanelType.LEFT_OF_THREE,
        window.mask_window.top,
        window.mask_window.bottom,
        window.mask_window.left,
        left_x
    )

    //Create center panel
    createPanelFragment(
        renderData,
        window,        
        PanelType.CENTER_OF_THREE,
        window.mask_window.top,
        window.mask_window.bottom,
        left_x,
        right_x,
    )

    //Create right panel
    createPanelFragment(
        renderData,
        window,        
        PanelType.RIGHT_OF_THREE,
        window.mask_window.top,
        window.mask_window.bottom,
        right_x,
        window.mask_window.right
    )
}

//Create four panel window
export const createFourPanel = (renderData: RenderData, window: WindowFragment) =>{
    clearPanels(window);

    //Shorthand for inner window dimensions
    const left = window.mask_window.left;
    const right = window.mask_window.right;
    const top = window.mask_window.top;
    const bottom = window.mask_window.bottom;
    
    //Calculate the division and the left position of the panels
    const division = (right - left) /4;
    const left_1 = left;
    const left_2 = left + division;
    const left_3 = left + (division * 2);
    const left_4 = left + (division * 3);

    //Create posts from left to right
    createPost(
        window,
        top,
        bottom,
        left_2,
        left_2
    )
    createPost(
        window,
        top,
        bottom,
        left_3,
        left_3
    )
    createPost(
        window,
        top,
        bottom,
        left_4,
        left_4
    )
    
    //Create the four panels from left to right
    createPanelFragment(
        renderData,
        window,
        PanelType.LEFT_OF_FOUR,
        top,
        bottom,
        left_1,
        left_2
    );
    createPanelFragment(
        renderData,
        window,
        PanelType.LEFT_CENTER_OF_FOUR,
        top,
        bottom,
        left_2,
        left_3
    );
    createPanelFragment(
        renderData,
        window,
        PanelType.RIGHT_CENTER_OF_FOUR,
        top,
        bottom,
        left_3,
        left_4
    );
    createPanelFragment(
        renderData,
        window,
        PanelType.RIGHT_OF_FOUR,
        top,
        bottom,
        left_4,
        right
    );
}

//Create panels top and bottom for a single hung
export const createTopBottomPanel = (renderData: RenderData, window: WindowFragment) =>{
    clearPanels(window);

    const center_y = (window.mask_window.top + window.mask_window.bottom) /2;

    //Create the center post
    createPost(
        window,
        center_y,
        center_y,
        window.mask_window.left,
        window.mask_window.right
    );

    //Create Top Panel
    createPanelFragment(
        renderData,
        window,        
        PanelType.TOP_OF_TWO,
        window.mask_window.top,
        center_y,
        window.mask_window.left,
        window.mask_window.right
    )

    //Create Bottom panel
    createPanelFragment(
        renderData,
        window,        
        PanelType.BOTTOM_OF_TWO,
        center_y,
        window.mask_window.bottom,
        window.mask_window.left,
        window.mask_window.right
    )

}

//Scale fragments, based on the width and or height 
export const changePanelFragments = (
    renderData: RenderData,
    window: WindowFragment,
    x_current_min: number,
    x_current_max: number,
    y_current_min: number,
    y_current_max: number
) =>{    
    
    //Set the current window dimensions as the new mins and maxes
    const x_new_min = window.mask_window.left;
    const x_new_max = window.mask_window.right;
    const y_new_min = window.mask_window.top;
    const y_new_max = window.mask_window.bottom;    
    
    //Iterate through all the panels
    window.panel.forEach(panel =>{                   
        //Iterate through all the lines and set their coords
        panel.model.line.forEach(line =>{
            
            line.x1 = getScaleCoord(x_current_min, x_current_max, x_new_min, x_new_max, line.x1);
            line.x2 = getScaleCoord(x_current_min, x_current_max, x_new_min, x_new_max, line.x2);
            line.y1 = getScaleCoord(y_current_min, y_current_max, y_new_min, y_new_max, line.y1);
            line.y2 = getScaleCoord(y_current_min, y_current_max, y_new_min, y_new_max, line.y2);
        })
        
        updatePanelInch(renderData, window, panel);
    });
    
    //Iterate through all the posts and set their coords
    window.post.forEach(post =>{
        post.y1 = getScaleCoord(y_current_min, y_current_max, y_new_min, y_new_max, post.y1);
        post.y2 = getScaleCoord(y_current_min, y_current_max, y_new_min, y_new_max, post.y2);
        post.x1 = getScaleCoord(x_current_min, x_current_max, x_new_min, x_new_max, post.x1);
        post.x2 = getScaleCoord(x_current_min, x_current_max, x_new_min, x_new_max, post.x2);
    })
}

//Update the model inch for the panel
export const updatePanelInch = (renderData: RenderData, window: WindowFragment, panel: PanelFragment) =>{  
    //The panel inch display_width and display_height need to represent the width and height within the window.
    //Essentially, the width and height without the frame.
    //To do this, we figure out what percent of the mask window the panel takes up
    //and then multiply that against the window fragment width and height respectively.

    //Get the absolute pixel panel height and width
    const panel_width = panel.model.line[Segment.RIGHT].x1 - panel.model.line[Segment.LEFT].x1;
    const panel_height = panel.model.line[Segment.BOTTOM].y1 - panel.model.line[Segment.TOP].y1;
    //Get the absolute pixel window height and width
    const window_width = window.model.line[Segment.RIGHT].x1 - window.model.line[Segment.LEFT].x1;
    const window_height = window.model.line[Segment.BOTTOM].y1 - window.model.line[Segment.TOP].y1;
    //Get the window mask width and height
    const mask_width = window.mask_window.right - window.mask_window.left;
    const mask_height = window.mask_window.bottom - window.mask_window.top;

    //Calculate the ratio for the panel within the window mask
    const ratio_width = panel_width / mask_width;
    const ratio_height = panel_height / mask_height;
    
    //Scale the panel based on the ratio values
    const scale_width = window_width * ratio_width;
    const scale_height = window_height * ratio_height;

    panel.model_inch.width = getWidthInch(panel_width, renderData);
    panel.model_inch.height = getHeightInch(panel_height, renderData);
    panel.model_inch.display_width = getWidthInch(scale_width, renderData);
    panel.model_inch.display_height = getHeightInch(scale_height, renderData);

    const deci = 10000;
    panel.model_inch.width = Math.round(panel.model_inch.width * deci) / deci;
    panel.model_inch.height = Math.round(panel.model_inch.height * deci) / deci;
    panel.model_inch.display_width = Math.round(panel.model_inch.display_width * deci) / deci;
    panel.model_inch.display_height = Math.round(panel.model_inch.display_height * deci) / deci;

}

//Change the open direction of a panel
export const changePanelOpen = (panel: PanelFragment, direction: OpenDirection) =>{
    panel.mask_open.direction = direction;
}

//Set the open direction of a panel
export const setPanelOpen = (window: WindowFragment, panel_type: PanelType, direction: OpenDirection) =>{
    let panel: PanelFragment = window.panel[panel_type];

    panel.mask_open.direction = direction;
}

//Update the lines for all panels
export const updatePanelLines = (
    new_coordinate_value: number, //The new value of the coordinate
    adjust_line: Line, //Which lines need to be adjust to the new value
    renderData: RenderData, //Render data object, needed for updating the panel inch
    window: WindowFragment //The parent window fragment
) =>{

    const compare_line: Line = {
        x1: adjust_line.x1,
        x2: adjust_line.x2,
        y1: adjust_line.y1,
        y2: adjust_line.y2
    }
    let is_horizontal = false;
    
    //Check if the comparing line is horizontal or not
    if((adjust_line.y1 - adjust_line.y2) ===0){
        is_horizontal = true;
    }
    
    //Iterate through all the panels
    window.panel.forEach(panel =>{
        //Iterate through all the lines of the panel
        panel.model.line.forEach(line =>{ 
            
            //Note, the coordinates only need to match one y or x value respectively
            //If it's horizontal, check/change the y axis values
            if(is_horizontal){
                //Update any coordinate with a matching y value
                if(line.y1 === compare_line.y1){
                    line.y1 = new_coordinate_value;                    
                }
                if(line.y2 === compare_line.y2){
                    line.y2 = new_coordinate_value;
                }
            }
            //It's vertical, check/change the x axis values
            else{
                //Update any coordinate with a matching x value
                if(line.x1 === compare_line.x1){
                    line.x1 = new_coordinate_value;
                }
                if(line.x2 === compare_line.x1){
                    line.x2 = new_coordinate_value;
                }                    
            }

            updatePanelInch(renderData, window, panel);
        })        
    })

    //Iterate through all the posts, basically doing the same thing as the panel lines
    window.post.forEach(post =>{
        if(is_horizontal){
            if(post.y1 === compare_line.y1){
                post.y1 = new_coordinate_value;
            }
            if(post.y2 === compare_line.y2){
                post.y2 = new_coordinate_value;
            }
        }
        else{
            if(post.x1 === compare_line.x1){
                post.x1 = new_coordinate_value;
            }
            if(post.x2 === compare_line.x2){
                post.x2 = new_coordinate_value;
            }
        }
    })
}

//Get an array of option id's from the option map
export const getOptionArray = (panel: PanelFragment): number[] =>{
    let id_array: number[] = [];

    //Iterate through all the options of the panel and stuff their id's into the array
    panel.data.option.forEach(option =>{
        id_array[id_array.length] = option.id;
    })

    return id_array;
}

//Clear the grid on the panel
export const clearPanelGrid = (renderData: RenderData, panel: PanelFragment) =>{        
    panel.data.grid.dvl_height = 0;
    panel.data.grid.dvl_width = 0;
    panel.mask_grid.grid_x = 0;
    panel.mask_grid.grid_y = 0;
    
    renderData.render_frame = true;
}

//Clear all panels and associated parts
const clearPanels = (window: WindowFragment) =>{
    //Destroy panels and posts
    window.panel = [];
    window.post = [];    
}

//Create a post
const createPost = (
    window: WindowFragment,
    top: number,
    bottom: number,
    left: number,
    right: number 
) =>{
    let post: Line = initLine();

    post.y1 = top;
    post.y2 = bottom;
    post.x1 = left;
    post.x2 = right;
    
    window.post[window.post.length] = post;
}