import {RenderData} from "../render_data/render_data";
import {WindowFragment} from "./window_fragment";
import {Segment} from "../render_data/enum_render";
import {packBuffer, scaleBuffer, translateBuffer} from "../model/vertex_buffer";
import {
    initVertexPoint, 
    getInterceptHorizontal,
    getSlopeInterceptVertical,
    getSlopeInterceptHorizontal
} from "../model/vertex_point";
import {Buffer, Model} from "../model/model";

//Update an octagon window
export const updateOctagon = (window: WindowFragment) =>{
    /* Note:
    The octagon model is constructed from the bottom right point and linked in a counter-clockwise order.
    */

    //Offset to acount for drawing off the canvas
    const offset =2;

    //Shorthand outer dimensions
    const top = window.model.line[Segment.TOP].y1 + offset
    const bottom = window.model.line[Segment.BOTTOM].y1 - offset;
    const left = window.model.line[Segment.LEFT].x1 + offset
    const right = window.model.line[Segment.RIGHT].x1 - offset;

    //Shorthand inner dimensions
    const panel = window.panel[0];
    const inner_top = panel.model.line[Segment.TOP].y1 + offset;
    const inner_bottom = panel.model.line[Segment.BOTTOM].y1 - offset;
    const inner_left = panel.model.line[Segment.LEFT].x1 + offset;
    const inner_right = panel.model.line[Segment.RIGHT].x1 - offset;

    //Shorthand buffers
    const outer = window.mask_buffer.outer;
    const inner = window.mask_buffer.inner;

    //Calc the center, width and height of the inner and outer
    const center_x = (left + right) /2;
    const center_y = (top + bottom) /2;
    const width = right - left;
    const height = bottom - top;
    const inner_width = inner_right - inner_left;
    const inner_height = inner_bottom - inner_top;

    //Pack the inner and outer buffer
    packBuffer(Buffer[Model.OCTAGON], outer);
    packBuffer(Buffer[Model.OCTAGON], inner);

    //Scale the buffers
    scaleBuffer(width, height, outer);
    scaleBuffer(inner_width, inner_height, inner);

    //Translate the buffers to the center of the window
    translateBuffer(center_x, center_y, outer);
    translateBuffer(center_x, center_y, inner);
    
    //Adjust some of the vertices to correct their angles when scaled
    if(width > height){
        //Get where the inner point with the outer slope intercepts the left inner side
        const intercept = getSlopeInterceptVertical(
            outer.vertex[1],
            outer.vertex[2],
            inner.vertex[1],
            inner_left
        );

        //Set the necessary vertices so they match the outer angles
        inner.vertex[2].y = intercept.y;
        inner.vertex[3].y = bottom - intercept.y;
        inner.vertex[6].y = bottom - intercept.y;
        inner.vertex[7].y = intercept.y;        
    }
    else if(height > width){
        const intercept = getSlopeInterceptHorizontal(
            outer.vertex[3],
            outer.vertex[4],
            inner.vertex[3],
            inner_top
        );

        //Set the necessary vertices so they match the outer angles        
        inner.vertex[0].x = right - intercept.x;
        inner.vertex[1].x = intercept.x;
        inner.vertex[4].x = intercept.x;
        inner.vertex[5].x = right - intercept.x;        
    }

}

//Update a hexagon window
export const updateHexagon = (window: WindowFragment) =>{            
    /* Note:
    The hexagon model is constructed from the furthest right point and linked in a clockwise order.    
    */

    //Offset to account for drawing off the canvas
    const offset =2;
    
    //Shorthand outer dimensions
    const top = window.model.line[Segment.TOP].y1;
    const bottom = window.model.line[Segment.BOTTOM].y1;
    const left = window.model.line[Segment.LEFT].x1 + offset;
    const right = window.model.line[Segment.RIGHT].x1 - offset;
    
    //Shorthand inner dimensions
    const panel = window.panel[0];
    const inner_top = panel.model.line[Segment.TOP].y1;
    const inner_bottom = panel.model.line[Segment.BOTTOM].y1;
    const inner_left = panel.model.line[Segment.LEFT].x1 + offset;
    const inner_right = panel.model.line[Segment.RIGHT].x1 - offset;

    //Shorthand buffers
    const outer = window.mask_buffer.outer;    
    const inner = window.mask_buffer.inner;

    //Calc the center, height and width of inner and outer 
    const center_x = (left + right) /2;
    const center_y = (top + bottom) /2;
    const width = right - left;
    const height = bottom - top; 
    const inner_width = inner_right - inner_left;
    const inner_height = inner_bottom - inner_top;

    //This scale is used to match the top of the hexagon to the top and bottom border
    const scale_height = 1.15; //The true scale is 1.1547, but we use 1.15 to account for drawing off the canvas    

    //Pack the inner and outer buffer
    packBuffer(Buffer[Model.HEXAGON], outer);    
    packBuffer(Buffer[Model.HEXAGON], inner);

    //Scale the outer buffers    
    scaleBuffer(width, height * scale_height, outer);
    scaleBuffer(inner_width, inner_height * scale_height, inner);  
    
    //Translate the buffers to the center of the window
    translateBuffer(center_x, center_y, outer);
    translateBuffer(center_x, center_y, inner);

    const hex_height = outer.vertex[1].y - outer.vertex[5].y;

    if(width > hex_height){
        //Short handing
        const v1 = inner.vertex[2];
        const v2 = outer.vertex[2];
        const v3 = outer.vertex[3];

        //Create a point which is offset by the delta of the inner and outer lower vertices
        const v4 = initVertexPoint();
        v4.x = v1.x - v2.x + v3.x;
        v4.y = v1.y - v2.y + v3.y;

        //Get the intercept with the shifted line intersects with the center y
        const intercept = getInterceptHorizontal(v4, v1, center_y);
        
        //Move the 3 points that constitute the outer side of the hexagon
        inner.vertex[3].x = intercept.x;
        inner.vertex[0].x = right - intercept.x;        

    }
    else if (hex_height > width){
        //Calculate the distance from the inner and outer side angle points
        const delta_x = inner.vertex[3].x - outer.vertex[3].x;

        //Copy the outer top left point and move it over by the delta x
        const v1 = inner.vertex[3];
        const v2 = outer.vertex[4];
        const v3 = initVertexPoint();
        v3.x = v2.x + delta_x;
        v3.y = v2.y;
        
        //Find where the inner side angle and the copied point intercept the inner top
        const intercept = getInterceptHorizontal(v1, v3, inner.vertex[4].y);
        const offset_x = intercept.x - left;

        //Adjus the 4 points (all the points that aren't the outer angles)
        inner.vertex[2].x = intercept.x;
        inner.vertex[4].x = intercept.x;
        inner.vertex[1].x = right - offset_x;
        inner.vertex[5].x = right - offset_x;        
    }
}

//Update a circle window
export const updateCircle = (window: WindowFragment) =>{   
    //Note: circles draw based off the x axis
    //Since they can't be stacked, it's not really important, but in case in comes up in the future
    
    setCircle(window);
}

//Update an oval window
export const updateOval = (window: WindowFragment) =>{
    setCircle(window);
}

//Set the values of a circle
const setCircle = (window: WindowFragment) =>{
        //Short hand for dimensions
        const left = window.model.line[Segment.LEFT].x1;
        const right = window.model.line[Segment.RIGHT].x1;
        const top = window.model.line[Segment.TOP].y1;
        const bottom = window.model.line[Segment.BOTTOM].y1;
    
        const circle = window.mask_circle;
    
        //Offset for accounting for drawing off the canvas
        const offset = 2;        
    
        //Set the radius for the inner and outer circle
        const outer_radius_x = ((right - left) /2) - offset;
        const outer_radius_y = ((bottom - top) /2) - offset;    
        
        circle.outer_radius.x = outer_radius_x;
        circle.outer_radius.y = outer_radius_y;
        circle.inner_radius.x = outer_radius_x - window.mask_window.gap;
        circle.inner_radius.y = outer_radius_y - window.mask_window.gap;
        
        //Set the center of the circle
        window.mask_circle.center.x = (left + right) /2;
        window.mask_circle.center.y = (top + bottom) /2;
}