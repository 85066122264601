import {useState, useEffect, Fragment, ReactNode} from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import {OrderList} from "../data/order/order_list";
import {OrderDetail} from "../data/order/order_detail";
import {getOrderListCopy} from "../data/copy_data";
import {getOrderString} from "../data/order/functions_detail_display";

interface Props{
    orderDetail: OrderDetail, 
    itemKey: number, 
    handleRemoveButton: (key: number) =>void,
    orderList: OrderList,
    setOrderList: React.Dispatch<React.SetStateAction<OrderList>>
}

//Return the accordion body of an accordion item
export const OrderListBody = ({
    orderDetail,
    itemKey, 
    handleRemoveButton,
    orderList,
    setOrderList
}: Props) =>{
    

    const COMMENT_SIZE = 5; //The number of rows allowed in the comment box
    //ID's for the various controls
    const ID_QUANTITY = "quantity";
    const ID_COMMENT = "comment";

    const order_string: string[] = orderDetail.order_string;
    const [quantity, setQuantity] = useState<string>("");
    const [comment, setComment] = useState<string>("");

    useEffect (() =>{
        setQuantity(String(orderList.list.get(itemKey)!.orderDetail.quantity))
        setComment(orderList.list.get(itemKey)!.orderDetail.comment);
    }, [orderList])
    
    
    //Handle copying the button to the clipboard
    const handleCopyButton = () =>{
        
        //Get the order string
        let clipboard = order_string.join("\n");
        //Add the comment if there is one
        if(orderDetail.comment !== ""){
            clipboard += "\nComment:\n" + orderDetail.comment;
        }

        //Write to the clipboard
        navigator.clipboard.writeText(clipboard);
    }

    //Handle a text box losing focus
    const handleBlur = (key: number, element_id: string, value: string) =>{
        processTextValue(key, element_id, value);
    }

    //Process a text value if it is valid, otherwise revert it
    const processTextValue = (key: number, element_id: string, value: string) =>{
        
        //Get a copy of the order list
        const order_list = getOrderListCopy(orderList);
        
        //Get the detail associated with this accordion item
        const detail = order_list.list.get(key)!.orderDetail;
        detail.order_string = getOrderString(detail);

        //Set the quantity and detail values based on which control passed in the value
        if(element_id === "quantity"){
            detail.quantity = Number(value);
        }
        else if(element_id === "comment"){
            detail.comment = value;
        }                
        
        //Set the order list
        setOrderList(order_list);        
    }

    //Handle a key down event on a text control
    const handleKeyDown = (key: number, element_id: string, value: string, event: React.KeyboardEvent<HTMLElement>) =>{        
        if(event.key === 'Enter'){
            processTextValue(key, element_id, value);
        }
    }

    //Handle when a text change is made
    const handleQuantityChange = (value: string) =>{
        
        const value_number = Number(value);
        if(!isNaN(value_number)){
            if(!value.includes(".")){
                if(value_number >0){
                    setQuantity(value);
                }
            }
        }
    }

    //Handle when the comment text is changed
    const handleCommentChange = (value: string) =>{
        setComment(value);
    }

    return(
        <Fragment>
            <div className="d-flex justify-content-end align-items-end">                
                <Form.Label className = "me-2">Quantity</Form.Label>
                <Form.Control 
                    type="text"
                    style={{width: "60px"}}
                    className="me-2"
                    value={quantity}
                    onChange={(event) => handleQuantityChange(event.target.value)}
                    onBlur={(event) => handleBlur(itemKey, ID_QUANTITY, event.target.value)}
                    onKeyDown={(event) => handleKeyDown(itemKey, ID_QUANTITY, event.currentTarget.value, event)}
                />

                <Button
                    onClick={ () =>handleRemoveButton(itemKey)}
                >
                    Remove
                </Button>
                <Button
                    onClick={handleCopyButton}
                    style={{marginLeft: '0.5rem'}}
                >
                    Copy To Clipboard
                </Button>
            </div>
            {order_string.map((line, index) =>(
                <Fragment key={index}>
                    {line}
                    <br />
                </Fragment>
            ))}
            <br/>
            <Form.Label>Comment:</Form.Label>
            <Form.Control
                as="textarea"
                rows={COMMENT_SIZE}
                value={comment}
                onChange={(event) => handleCommentChange(event.target.value)}
                onBlur={(event) => handleBlur(itemKey, ID_COMMENT, event.target.value)}
                onKeyDown={(event) => handleKeyDown(itemKey, ID_COMMENT, event.currentTarget.value, event)}
            />            
        </Fragment>
    )
}