import {ConstrainValues} from "./constrain_value"
import {RenderData} from "../render/render_components/render_data/render_data"
import {getAngle, VertexPoint, initVertexPoint} from "../render/render_components/model/vertex_point"
import {WindowFragment} from "../render/render_components/window/window_fragment"
import {ShapeDirection} from "../render/render_components/render_data/enum_render"

//Limit the angle of any angle that is not 90 degrees
export const limitAngle = (constrainValues: ConstrainValues, renderData: RenderData) =>{
    //Iterate through all the windows and check their angles, based on the window type
    renderData.window.forEach(window =>{
        switch(window.data.product_type.value){
            case "L Rake":            
            case "R Rake":
            case "Peak":
                checkTop(window, renderData, constrainValues);
            break;
            case "Triangle":
                checkTop(window, renderData, constrainValues);
                checkSide(window, renderData, constrainValues);
            break;
            case "R Triangle":            
            case "L Triangle":
                checkSide(window, renderData, constrainValues);
            break;
            case "Octagon":
            case "Hexagon":
                checkBuffer(window, renderData, constrainValues);
            break;
        }
    })
}

//Check the top angle of an standard triangle
const checkTop = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{
    const outer = window.mask_triangle.outer_triangle;            
    checkError(outer.left, outer.right, outer.upper, renderData, constrainValues);
}

//Check the side angle of a standard triangle
const checkSide = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{
    const outer = window.mask_triangle.outer_triangle;
    //Check the left side
    if(window.model.shape_direction === ShapeDirection.LEFT){
        checkError(outer.upper, outer.right, outer.left, renderData, constrainValues);
    }
    //Check the right side
    else if(window.model.shape_direction === ShapeDirection.RIGHT){
        checkError(outer.upper, outer.left, outer.right, renderData, constrainValues);
    }
    else{ //Check both sides
        checkError(outer.upper, outer.right, outer.left, renderData, constrainValues);
        checkError(outer.upper, outer.left, outer.right, renderData, constrainValues);
    }
}

//Check all angles in the buffer
const checkBuffer = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{

    const size = window.mask_buffer.outer.vertex.length;    
    //Iterate through all the vertices in the buffer
    for(let counter =0; counter < window.mask_buffer.outer.vertex.length; counter ++){
        //Indices of the veritices
        let index_1 = counter -1;
        let index_2 = counter;
        let index_3 = counter +1

        //Modify the index value if it is below or above the index range
        if(index_1 < 0){
            index_1 = index_1 + size;            
        }
        if(index_3 >= size){
            index_3 = index_3 - (size);
        }

        const vertex = window.mask_buffer.outer.vertex;
               
        //Check if there is an error between the three points on the buffer        
        checkError(
            vertex[index_1], 
            vertex[index_2], 
            vertex[index_3], 
            renderData, 
            constrainValues
        );        
    }
}

//Check if there is an error between two points and add it to the error point list if necessary
const checkError = (
    v1: VertexPoint,
    v2: VertexPoint,
    intersect: VertexPoint,
    renderData: RenderData,
    constrainValues: ConstrainValues
) =>{
    
    //Get the angle
    const angle = getAngle(v1, v2, intersect);
    
    //If the angle is less than the allowed angle
    if(angle < constrainValues.limit.min_angle){
        //Copy the vertex
        const vertex = initVertexPoint();        
        vertex.x = intersect.x;
        vertex.y = intersect.y;

        //Add the vertex to the list of errored vertices
        renderData.error.angle.vertex[renderData.error.angle.vertex.length] = vertex;
    }
}

