import {RenderData} from "../render_data/render_data";
import {Segment, OpenDirection} from "../render_data/enum_render";
import {renderModel, Model, Buffer} from "../model/model";
import {WindowFragment, Line} from "../window/window_fragment";
import {changePanelFragments} from "../panel/functions_panel";
import {PanelFragment} from "../panel/panel_fragment";
import {VertexPoint} from "../model/vertex_point";

//Draw the absolute border of the window
export const maskBorder = (window: WindowFragment, renderData: RenderData) =>{
    renderData.viewport.context!.lineWidth = window.line_width;
    renderData.viewport.context!.strokeStyle = window.line_style;        
    
    renderData.viewport.context!.strokeRect(                       
        window.model.line[Segment.LEFT].x1,
        window.model.line[Segment.TOP].y1,
        window.model.line[Segment.RIGHT].x1 - window.model.line[Segment.LEFT].x1,
        window.model.line[Segment.BOTTOM].y1 - window.model.line[Segment.TOP].y1
    );
}

//Draw a default window mask
export const maskWindow = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;
    
    //Clear the window area before drawing on it
    context!.clearRect(
        window.mask_window.left,
        window.mask_window.top,
        window.mask_window.right - window.mask_window.left,
        window.mask_window.bottom - window.mask_window.top
    );

    //Color in the window
    context!.fillStyle = window.mask_window.color_window;
    context!.fillRect(
        window.mask_window.left,
        window.mask_window.top,
        window.mask_window.right - window.mask_window.left,
        window.mask_window.bottom - window.mask_window.top
    );
    
    context!.lineWidth = window.line_width;
    context!.strokeStyle = window.line_style;        
    
    //Draw the inner window border
    context!.strokeRect(
        window.mask_window.left,
        window.mask_window.top,
        window.mask_window.right - window.mask_window.left,
        window.mask_window.bottom - window.mask_window.top
    );
}

//Update the dimensions of the window mask
export const updateMaskWindow = (window: WindowFragment, renderData: RenderData) =>{    
    
    const gap = window.mask_window.gap;
    
    let border_top =0;
    let border_bottom =0;
    let border_left =0;
    let border_right =0;

    //If the fragment borders the top viewport
    if(window.model_inch.top === 0){
        border_top = window.mask_window.gap/2;
    }
    //if the fragment bordes the bottom viewport
    if(window.model_inch.bottom === renderData.viewport.inch_height){
        border_bottom = window.mask_window.gap/2;

    }
    //If the fragment borders the left viewport
    if(window.model_inch.left === 0){
        border_left = window.mask_window.gap/2;
    }    
    //If the fragment borders the right viewport
    if(window.model_inch.right === renderData.viewport.inch_width){
        border_right = window.mask_window.gap/2;
    }
    
    //Store the current min and max valuese, before changing them
    const x_current_min = window.mask_window.left;
    const x_current_max = window.mask_window.right;
    const y_current_min = window.mask_window.top;
    const y_current_max = window.mask_window.bottom;
    
    //Set the new mask values
    window.mask_window.top = window.model.line[Segment.TOP].y1 + gap + border_top;
    window.mask_window.bottom = window.model.line[Segment.BOTTOM].y1 - (gap + border_bottom);
    window.mask_window.left = window.model.line[Segment.LEFT].x1 + gap + border_left;    
    window.mask_window.right = window.model.line[Segment.RIGHT].x1 - (gap + border_right);
    
    //Change the panel fragments, based on the adjusted amount of the window mask     
    changePanelFragments(
        renderData,
        window,
        x_current_min,
        x_current_max,
        y_current_min,
        y_current_max
    );
}

//Draw a grid
export const maskGrid = (renderData: RenderData, window: WindowFragment, panel: PanelFragment) =>{
    
    const context = renderData.viewport.context;
    context!.lineWidth = panel.mask_grid.line_width;
    context!.strokeStyle = panel.mask_grid.line_style;    
    
    //Store the dimensions for ease
    const left = panel.model.line[Segment.LEFT].x1;
    const right = panel.model.line[Segment.RIGHT].x1;
    const top = panel.model.line[Segment.TOP].y1;
    const bottom = panel.model.line[Segment.BOTTOM].y1;
    
    const grid_x = panel.mask_grid.grid_x;
    const grid_y = panel.mask_grid.grid_y;

    //Determine the width and height of the window space within the frame mask
    const width = right - left;
    const height = bottom - top;
    
    //Determine the step in pixels for drawing grid lines
    const step_x = width / (grid_x);
    const step_y = height / (grid_y);
    
    context!.beginPath();
    
    //Draw grid lines along the y axis
    for(let counter = left; counter < right; counter += step_x){
        context!.moveTo(counter, top);
        context!.lineTo(counter, bottom);        
    }    
    //Draw grid lines along the x axis
    for(let counter = top; counter < bottom; counter += step_y){
        context!.moveTo(left, counter);
        context!.lineTo(right, counter);
    }

    context!.stroke();
}

//Draw an arrow for the open direction of the window
export const maskOpen = (panel: PanelFragment, renderData: RenderData) =>{    
        
    const context = renderData.viewport.context;
    
    context!.lineWidth = panel.mask_open.line_width;
    context!.strokeStyle = panel.mask_open.line_style;

    //Get the center point of the panel
    const x = (panel.model.line[Segment.LEFT].x1 + panel.model.line[Segment.RIGHT].x1) /2;
    const y = (panel.model.line[Segment.TOP].y1 + panel.model.line[Segment.BOTTOM].y1) /2;

    //Draw the open direction of the panel
    if(panel.mask_open.direction !== OpenDirection.NONE){
        switch(panel.mask_open.direction){
            case OpenDirection.UP:
                renderModel(renderData, x, y, Buffer[Model.ARROW_DOWN], -0.5);
            break;
            case OpenDirection.DOWN:
                renderModel(renderData, x, y, Buffer[Model.ARROW_DOWN], 0.5);
            break;
            case OpenDirection.LEFT:
                renderModel(renderData, x, y, Buffer[Model.ARROW_RIGHT], -0.5);
            break;
            case OpenDirection.RIGHT:
                renderModel(renderData, x, y, Buffer[Model.ARROW_RIGHT], 0.5);
            break;
        }
    }
}

export const maskPost = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;

    //Use the window lines and frame color to set the context
    context!.lineWidth = window.line_width;
    context!.strokeStyle = window.line_style;
    context!.fillStyle = renderData.all_window.color_frame;
    
    //Iterate through all the posts
    window.post.forEach(post =>{
        const offset = window.post_width /2;
        let left = post.x1;
        let right = post.x2;
        let top = post.y1;
        let bottom = post.y2;

        //Find if it's a horizontal or vertical post
        if(left === right){
            left -= offset;
            right += offset;
        }
        else{
            top -= offset;
            bottom += offset;
        }

        //Draw the outline of the post
        context!.strokeRect(
            left,
            top,
            right - left,
            bottom - top
        );
        
        //Fill in the post
        context!.fillRect(
            left,
            top,
            right - left,
            bottom - top
        );
    });
}

//Draw a screen for a standard window/panel
export const maskScreen = (renderData: RenderData, panel: PanelFragment) =>{
    const context = renderData.viewport.context;
    context!.fillStyle = panel.mask_screen.screen_color;
    context!.fillRect(
        panel.model.line[Segment.LEFT].x1,
        panel.model.line[Segment.TOP].y1,
        panel.model.line[Segment.RIGHT].x1 - panel.model.line[Segment.LEFT].x1,
        panel.model.line[Segment.BOTTOM].y1 - panel.model.line[Segment.TOP].y1           
    )
}



