import {useEffect, useState} from "react";

interface MouseCoords{
    x: number,
    y: number
}
const initMouseCoords ={
    x: 0,
    y: 0
} 

export const MousePosition = (global: boolean = false): [{x: number, y: number}, (event: MouseEvent) => void] =>{
    
    const [mouseCoords, setMouseCoords] = useState <MouseCoords>(initMouseCoords);
    
    const handleCursorMovement = (event: MouseEvent): void =>{
        //@ts-ignore
        let rect = event.target.getBoundingClientRect();
        setMouseCoords({
            x: event.clientX - rect.left,
            y: event.clientY - rect.top
        });
    }
    
    useEffect(() =>{
        if(global){
            window.addEventListener("mousemove", handleCursorMovement);
            return () => {window.removeEventListener("mousemove", handleCursorMovement);};
        }
    }, [global]);

    return [mouseCoords, handleCursorMovement];
}
