//3x3 Matrix
export interface Matrix{
    c0: number,    c1: number,    c2: number,
    c3: number,    c4: number,    c5: number
    c6: number,    c7: number,    c8: number
}

//Get an ID Matrix
export const initMatrix = () =>({
    c0: 1,  c1: 0,  c2: 0,
    c3: 0,  c4: 1,  c5: 0,
    c6: 0,  c7: 0,  c8: 1
})
