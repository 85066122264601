import {ADDRESS_SERVER} from "../../../../../settings";

//Indexes for pulling information fro the JSON object
const KEY_ID: string = "id_parameter";
const KEY_LABEL: string = "description";

//Endpoint addresses to retrieve information from the server
enum Endpoint{
    PRODUCT_LINE = "product_line",
    FRAME_TYPE = "frame_type",
    FRAME_COLOR = "frame_color",
    PRODUCT_TYPE = "product_type",    
    TREATMENT = "glass_treatment",
    GLASS = "glass",
    GRID_TYPE = "grid_type",
    GRID_PATTERN = "grid_pattern",
    SCREEN = "screen",
    OPTION ="option"
}

//Individual record data; name and id basically    
export interface RecordData{ 
    id: number;
    description: string;
}

//All the parametere in the config values
export interface ConfigValues{
    product_line: Map<string, RecordData>;
    frame_type: Map<string, RecordData>;
    frame_color: Map<string, RecordData>;
    product_type: Map<string, RecordData>;
    treatment: Map<string, RecordData>;
    glass: Map<string, RecordData>;
    grid_type: Map<string, RecordData>;
    grid_pattern: Map<string, RecordData>;
    screen: Map<string, RecordData>;
    option: Map<string, RecordData>;
}

//Initialize all the maps
export const initConfigValues = ():ConfigValues =>({
    product_line: new Map<string, RecordData>(),
    frame_type: new Map<string, RecordData>(),
    frame_color: new Map<string, RecordData>(),
    product_type: new Map<string, RecordData>(),
    treatment: new Map<string, RecordData>(),
    glass: new Map<string, RecordData>(),
    grid_type: new Map<string, RecordData>(),
    grid_pattern: new Map<string, RecordData>(),
    screen: new Map<string, RecordData>(),
    option: new Map<string, RecordData>()    
})

//Seed the values into the configValue object from the server
export const seedConfigValues = async (configValue: ConfigValues): Promise<ConfigValues> =>{
    seedValues(configValue.product_line, await getConfigValues(Endpoint.PRODUCT_LINE));    
    seedValues(configValue.frame_type, await getConfigValues(Endpoint.FRAME_TYPE));
    seedValues(configValue.frame_color, await getConfigValues(Endpoint.FRAME_COLOR));
    seedValues(configValue.product_type, await getConfigValues(Endpoint.PRODUCT_TYPE));
    seedValues(configValue.treatment, await getConfigValues(Endpoint.TREATMENT));
    seedValues(configValue.glass, await getConfigValues(Endpoint.GLASS));    
    seedValues(configValue.grid_type, await getConfigValues(Endpoint.GRID_TYPE));
    seedValues(configValue.grid_pattern, await getConfigValues(Endpoint.GRID_PATTERN)); 
    seedValues(configValue.screen, await getConfigValues(Endpoint.SCREEN));
    seedValues(configValue.option, await getConfigValues(Endpoint.OPTION));
        
    return configValue;
}

//Seed values into a configurator parameter
const seedValues = (parameter: Map<string, RecordData>, record: any) =>{    
    record.forEach((record: any) =>{
        const id = record[KEY_ID];
        const description = record[KEY_LABEL];
        parameter.set(description, {id, description});
    })
    
}

//Get configurator values from the server
const getConfigValues = async(endpoint: Endpoint) =>{
    try {        

        const response = await fetch(ADDRESS_SERVER + endpoint);        
        
        const jsonData = await response.json();        

        return jsonData;
    } catch (error: any) {
        console.error(error.message)        
    }
}