import {OrderDetail, DetailPanel, DetailWindow} from "./order_detail";
import {OpenDirection, OpenDirectionName} from "../../render/render_components/render_data/enum_render";

//Get an output of order detail in an array format
export const getOrderString = (orderDetail: OrderDetail): string[] =>{
    const list: string[] = [];
    
    addString(list, "Quantity: " + orderDetail.quantity);

    //If it's a callout
    if(orderDetail.is_callout){
        addString(list, "Callout: " + orderDetail.callout);
    }    
    addString(list, "Net Width: " + orderDetail.width);
    addString(list, "Net Height: " + orderDetail.height);

    //Add base values
    addString(list, "Product Line: " + orderDetail.product_line);
    addString(list, "Frame Type: " + orderDetail.frame_type);
    addString(list, "Frame Color: " + orderDetail.frame_color);     
    
    //Set what panel information will need to be displayed
    determinePanelInfo(orderDetail);

    let window_count =1;
    //Iterate through the windows    
    orderDetail.window.forEach(window =>{               
        
        //Display information for multiple windows if necessary
        if(orderDetail.window.length >1){
            addString(list, "Window (" + window_count + ")" )
            addString(list, "Width: " + window.width);
            addString(list, "Height: " + window.height);
            addString(list, "Upper Left Corner: " + window.upper_left_x + ", " + window.upper_left_y);
            window_count ++;
        }
        
        //Display second height
        if(window.second_height >0){
            addString(list, "Second Height: " + window.second_height);            
        }

        //Display product type
        addString(list, "Product Type: " + window.product_type);    
               
        //Display panel information that applies to the entire window
        const panel = window.panel[0];
        
        //Display glass for the window
        if(!window.display_panel_glass) displayGlass(panel, list);        

        //Display grid for the window
        if(!window.display_panel_grid){
            if(panel.grid.has_grid){
                displayWindowGrid(window, list);
            }
        } 
        
        //Display screen for the window
        if(!window.display_panel_screen) displayScreen(panel, list);               
        
        //If the panels need to be displayed individually for some reason or another
        if(window.display_all_panel){
            let panel_count =0;
            
            //Iterate through all the panels
            window.panel.forEach(panel =>{
                panel_count ++;
                addString(list, "Panel (" + panel_count + ")");
                
                if(window.display_panel_size) displaySize(panel, list);
                if(window.display_panel_glass) displayGlass(panel, list);
                if(window.display_panel_direction) displayDirection(panel, list);
                if(window.display_panel_grid) displayPanelGrid(panel, list);
                if(window.display_panel_screen) displayScreen(panel, list);
                if(window.display_panel_option) displayOption(panel, list);
            })
        }       
    })       

    return list;
}

//Display size information for a panel
const displaySize = (panel: DetailPanel, list: string[]) =>{
    addString(list, "Width: " + panel.width);
    addString(list, "Height: " + panel.height);                
}

//Display grid information for a panel
const displayPanelGrid = (panel: DetailPanel, list: string[]) =>{    
    const dimension = panel.grid.dvl_width + " x " + panel.grid.dvl_height;
    
    displayGrid(panel, dimension, list);
}

//Display grid information for a window
const displayWindowGrid = (window: DetailWindow, list: string[]) =>{    
    const panel = window.panel[0];
    
    //Calculate the overall window grid pattern for standard windows
    let width =0;
    let height =0;
    //If it's not a single hung window with a grid
    if(window.product_type !== "Single Hung"){
        window.panel.forEach(panel =>{
            width += panel.grid.dvl_width;
        })
        height = panel.grid.dvl_height;
    }
    else{
        window.panel.forEach(panel =>{
            height += panel.grid.dvl_height;
        })
        width = panel.grid.dvl_width;
    }

    const dimension = width + " x " + height;    

    displayGrid(panel, dimension, list);
}

//Dispaly grid information
const displayGrid = (panel: DetailPanel, dimension: string, list: string[]) =>{      
    let grid_string = "Grid: " + panel.grid.grid_pattern + " ";

    switch(panel.grid.grid_pattern){
        case "Standard":
            grid_string += dimension;
        break;        
        case "Margin":
        case "Diagram":
        case "Sunburst":
            grid_string += "See Comments";            
        break;        
    }

    addString(list, grid_string);
}

//Display glass information for a panel
const displayGlass = (panel: DetailPanel, list: string[]) =>{
    let outer_glass = "Outer Glass: ";
    if(panel.outer_glass.tempered){
        outer_glass += "Tempered ";
    }
    outer_glass += panel.outer_glass.glass;

    let inner_glass = "Inner Glass: ";
    if(panel.inner_glass.tempered){
        inner_glass += "Tempered ";
    }
    inner_glass += panel.inner_glass.glass;

    addString(list, outer_glass);
    addString(list, inner_glass);
}

//Display options for a panel
const displayOption = (panel: DetailPanel, list: string[]) =>{
    //If there are any options in the panel
    if(panel.option.length >0){
        let option_string = "Options: ";
            
        //Iterate through all the option values, except the last one in the array
        for(let counter=0; counter < panel.option.length -1; counter++){
            option_string += panel.option[counter] + ", ";
        }        
        
        //Add the last option
        option_string += panel.option[panel.option.length -1];    

        addString(list, option_string);
    }    
}

//Display screen information for a panel
const displayScreen = (panel: DetailPanel, list: string[]) =>{
    if(panel.has_screen){
        addString(list, "Has Screen");
    }
}

//Display direction information for a panel
const displayDirection = (panel: DetailPanel, list: string[]) =>{    
    //If the panel has an open direction, display it
    if(panel.open_direction !== OpenDirectionName[OpenDirection.NONE]){
        addString(list, "Open Direction: " + panel.open_direction);
    }    
}

//Just a shorthand way for handling the addition of strings to the array
const addString = (list: string[], value: string) =>{    
    list[list.length] = value;
}


//Set what information if any needs to be displayed per panel
const determinePanelInfo = (orderDetail: OrderDetail) =>{    
        
    //Iterate through all the windows
    orderDetail.window.forEach(window =>{
        window.display_panel_glass = determineAllGlass(window);
        window.display_panel_grid = determineAllGrid(window);
        window.display_panel_screen = determineAllScreen(window);
        window.display_panel_size = determineAllSize(window);
        window.display_panel_direction = determineAllDirection(window);
        window.display_panel_option = determineAllOption(window);        

        //If any of the display all's are set, then set the flag that at least something needs displayed from all the panels
        if(
            window.display_panel_glass ||
            window.display_panel_grid ||
            window.display_panel_screen ||
            window.display_panel_size ||
            window.display_panel_direction ||
            window.display_panel_option
        )
        {
            window.display_all_panel = true;
        }
    })
}

//Determine if all the panel sizes need to be displayed
const determineAllSize = (window: DetailWindow): boolean =>{
    //Iterate through all the panels
    const width = window.panel[0].width;
    const height = window.panel[0].height;
    
    for(let counter=0; counter < window.panel.length; counter++){
        const panel = window.panel[counter];
        
        if(width !== panel.width) return true;
        if(height !== panel.height) return true;
    }
    
    return false;
}

//Determine if all the panel glass needs to be displayed
const determineAllGlass = (window: DetailWindow): boolean =>{    
    const inner_glass = window.panel[0].inner_glass;
    const outer_glass = window.panel[0].outer_glass;

    for(let counter=0; counter < window.panel.length; counter++){
        const panel = window.panel[counter];

        if(inner_glass.glass !== panel.inner_glass.glass) return true;
        if(inner_glass.tempered !== panel.inner_glass.tempered) return true;
        if(outer_glass.glass !== panel.outer_glass.glass) return true;
        if(outer_glass.tempered !== panel.outer_glass.tempered) return true;
    }
    
    return false;
}

//Determine if all the panel screens should be dispalyed
const determineAllScreen = (window: DetailWindow): boolean =>{
    const has_screen = window.panel[0].has_screen;

    //Iterate through all the panels
    for(let counter=0; counter < window.panel.length; counter++){
        const panel = window.panel[counter];
        if(has_screen !== panel.has_screen) return true;
    }
    
    return false;
}

//Determine if all the panel grids should be displayed
const determineAllGrid = (window: DetailWindow): boolean =>{           
    
    const panel = window.panel[0];
    
    const grid_type = panel.grid.grid_type;
    const has_grid = panel.grid.has_grid;
    const dvl_width = panel.grid.dvl_width;
    const dvl_height = panel.grid.dvl_height;
    const grid_pattern = panel.grid.grid_pattern;
    
    //Iterate through all the panels of a window; have to use a for next loop, for each doesen't allow for breaking    
    for(let counter =0; counter < window.panel.length; counter++)        {
        const panel = window.panel[counter];
        
        if(grid_type !== panel.grid.grid_type) return true;
        if(has_grid !== panel.grid.has_grid) return true;
        if(dvl_width !== panel.grid.dvl_width) return true;
        if(dvl_height !== panel.grid.dvl_height) return true;
        if(grid_pattern !== panel.grid.grid_pattern) return true;
    }
    
    return false;
}

//Determine if all the panels should display direction
const determineAllDirection = (window: DetailWindow): boolean =>{
    if(window.product_type === "Single Hung") return true;    
    if(window.product_type === "OXO") return true;

    return false;
}

//Determine if all the panels should display options
const determineAllOption = (window: DetailWindow): boolean =>{
    
    //Iterate throug the panels
    for(let counter=0; counter < window.panel.length; counter++){
        const panel = window.panel[counter];
        //We're currently just displaying options for panels if any of the panels has options applied
        if(panel.option.length >0) return true
    }    
    
    return false;
}