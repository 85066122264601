import {RenderData} from "../render_data/render_data";
import {Segment} from "../render_data/enum_render";
import {WindowFragment, Line} from "../window/window_fragment";
import {VertexPoint} from "../model/vertex_point";

//Clear the entire window area
export const clearWindow = (renderData: RenderData, window: WindowFragment) =>{
    const context = renderData.viewport.context;

    let bottom = window.model.line[Segment.BOTTOM].y1 - window.model.line[Segment.TOP].y1;

    //Account for pixel anomolies with stacked windows
    if(bottom != renderData.viewport.height){
        bottom -=1;
    }
    
    if(window.model.second_height >0){ //If there is a secondary height
        bottom = window.model.second_height - window.model.line[Segment.TOP].y1 -1; //Clear to the second height only
    }
        
    //clear the area of the arch
    context!.clearRect(
        window.model.line[Segment.LEFT].x1,
        window.model.line[Segment.TOP].y1,
        window.model.line[Segment.RIGHT].x1 - window.model.line[Segment.LEFT].x1,
        bottom
    )
}

//Draw the frame of a straight lower
export const drawInnerLower = (renderData: RenderData, window: WindowFragment) =>{
    
    const context = renderData.viewport.context;
    context!.strokeStyle = window.mask_window.line_style;
    context!.lineWidth = window.mask_window.line_width;

    const panel = window.panel[0]; //Get the only panel in the window
    
    //Short way of getting dimensions for the lower window
    const second_top = window.model.line[Segment.TOP].y1 + window.model.second_height;    
    
    //Get the inner dimensions of the lower and draw it
    const left = panel.model.line[Segment.LEFT].x1;
    const right = panel.model.line[Segment.RIGHT].x1;        
    const top = second_top + window.mask_lower.offset_left;
    const bottom = panel.model.line[Segment.BOTTOM].y1   
        
    context!.clearRect(
        left,
        top,
        right - left,
        bottom - top
    );    

    //Fill the window color
    context!.fillStyle = window.mask_window.color_window;
    context!.fillRect(
        left,
        top,
        right - left,
        bottom - top
    );
    
    //Draw the inner 3 sided rectangle
    context!.beginPath();
    context!.moveTo(left, top);
    context!.lineTo(left, bottom);
    context!.lineTo(right, bottom);
    context!.lineTo(right, top);    
    context!.stroke();
}

//Just a simple way to draw a line or two, shouldn't be used for multiple lines
export const drawLine = (line: Line, renderData: RenderData) =>{
    const context = renderData.viewport.context;

    context!.beginPath();
    context!.moveTo(line.x1, line.y1);
    context!.lineTo(line.x2, line.y2);
    context!.stroke();
}

//Draw a line using coordinates
export const drawLineCoord = (x1: number, y1: number, x2: number, y2: number, renderData: RenderData) =>{
    const context = renderData.viewport.context;
    
    context!.beginPath();
    context!.moveTo(x1, y1);
    context!.lineTo(x2, y2);
    context!.stroke();
}

//Draw a line using points
export const drawLinePoint = (start: VertexPoint, end: VertexPoint, renderData: RenderData) =>{
    const context = renderData.viewport.context;
    
    context!.beginPath();
    context!.moveTo(start.x, start.y);
    context!.lineTo(end.x, end.y);
    context!.stroke();
}