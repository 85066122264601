import {RenderData} from "../render_data/render_data";
import {WindowFragment} from "./window_fragment";
import {Segment, ShapeDirection} from "../render_data/enum_render";
import {Triangle} from "../model/model_data";
import {
    initVertexPoint, 
    VertexPoint,     
    getLineLength,
    getInterceptVertical
} from "../model/vertex_point";
import {
    initVertexBuffer, 
    addVertex, 
    scaleTranslateBuffer, 
    translateBuffer, 
    VertexBuffer
} from "../model/vertex_buffer"
import {initSecondHeight} from "./functions_shape_window";
import {getHeightInch} from "../viewport/functions_viewport";


//Update a straight right triangle; these are rakes
export const updateStraightRightTriangle = (window: WindowFragment, renderData: RenderData) =>{
    //If a second height doesn't exist, initialize it
    if(window.model.second_height <=0){
        initSecondHeight(window);
    }
    
    updateRightTriangle(window, renderData);
    
    const offset = 2; //Adjust for lines at the edge of the canvas

    //Adjust the side angle vertex point to intersect with the inner side    
    const inner_triangle = window.mask_triangle.inner_triangle;
    const panel = window.panel[0];
    if(window.model.shape_direction === ShapeDirection.LEFT){
        const intercept_side = getInterceptVertical(inner_triangle.upper, inner_triangle.left, panel.model.line[Segment.LEFT].x1);
        inner_triangle.left = intercept_side;
        inner_triangle.right.y = intercept_side.y;
    }
    else{
        const intercept_side = getInterceptVertical(inner_triangle.upper, inner_triangle.right, panel.model.line[Segment.RIGHT].x1);
        inner_triangle.right = intercept_side;
        inner_triangle.left.y = intercept_side.y;        
    }
    
    const second_height = window.model.second_height;
    window.mask_lower.offset_left = inner_triangle.left.y - second_height;
    window.mask_lower.offset_right = inner_triangle.right.y - second_height;

    const deci = 10000;
    window.model_inch.second_height = getHeightInch(window.model.second_height, renderData);
    window.model_inch.second_height = Math.round(window.model_inch.second_height * deci) / deci;
}

//Update a right triangle; note, this is triangle shape, not position
export const updateRightTriangle = (window: WindowFragment, renderData: RenderData) =>{
    
    //The offset is to account for some pixel anomolies
    const offset = 2;

    //Short hand values
    const top = window.model.line[Segment.TOP].y1 + offset;
    let bottom = window.model.line[Segment.BOTTOM].y1
    let left = window.model.line[Segment.LEFT].x1;
    let right = window.model.line[Segment.RIGHT].x1;

    //Assign the second height as the bottom if it exists
    if(window.model.second_height > 0){
        bottom = window.model.second_height;
    }

    //Set the tall side depending on the direction of the right triangle
    let outer_tall_side = 0;
    if(window.model.shape_direction === ShapeDirection.LEFT){
        outer_tall_side = right;
        left += offset;    
    }
    else{
        outer_tall_side = left;
        right -= offset;
    }

    const outer = window.mask_triangle.outer_triangle;
    const inner = window.mask_triangle.inner_triangle;
    
    //Set the outer triangle vertices
    outer.upper.x = outer_tall_side;
    outer.upper.y = top;
    outer.left.x = left;
    outer.left.y = bottom;
    outer.right.x = right;
    outer.right.y = bottom;    

    //Set the gap
    let gap = window.mask_window.gap * 1.5;
    let offset_scale = gap;
    if(bottom !== renderData.viewport.height){
        gap = window.mask_window.gap;
        offset_scale = window.mask_window.gap * 1.15 //For some reason this looks more accurate, despite measurements showing otherwise
    }

    setInnerTriangle(window, outer, inner, offset_scale);

}

//Update a peak window
export const updatePeak = (window: WindowFragment, renderData: RenderData) =>{   
    
    if(window.model.second_height <=0){
        initSecondHeight(window);
    }
    
    //Construct the triangle top
    updateTriangle(window, renderData);    
    
    //Shorthand for getting values
    const panel = window.panel[0];    
    const inner = window.mask_triangle.inner_triangle;
    const left = panel.model.line[Segment.LEFT].x1;
    const right = panel.model.line[Segment.RIGHT].x1;
    const second_height = window.model.second_height;

    //Get the new inner left and right by finding the intercepts for left and right
    const inner_left = getInterceptVertical(inner.upper, inner.left, left);
    const inner_right = getInterceptVertical(inner.upper, inner.right, right);

    //Set the intercepts as the new left and right
    inner.left = inner_left;
    inner.right = inner_right;

    window.mask_lower.offset_left = inner.left.y - second_height;
    window.mask_lower.offset_right = inner.right.y - second_height;

    const deci = 10000;
    window.model_inch.second_height = getHeightInch(window.model.second_height, renderData);
    window.model_inch.second_height = Math.round(window.model_inch.second_height * deci) / deci;
}

//Update a triangle window
export const updateTriangle = (window: WindowFragment, renderData: RenderData) =>{
    
    //The offset is to account for some pixel anomolies
    const offset = 2;
    
    //Short hand for window dimensions
    const left = window.model.line[Segment.LEFT].x1 + offset;
    const right = window.model.line[Segment.RIGHT].x1 - offset;
    const top = window.model.line[Segment.TOP].y1;
    let bottom = window.model.line[Segment.BOTTOM].y1;
    
    if(window.model.second_height >0){
        bottom = window.model.second_height;
    }

    //Short hand for mask triangles
    const outer = window.mask_triangle.outer_triangle;
    const inner = window.mask_triangle.inner_triangle;
    
    //Set the gap
    let gap = window.mask_window.gap * 1.5;
    let offset_scale = gap;
    if(bottom !== renderData.viewport.height){
        gap = window.mask_window.gap;
        offset_scale = window.mask_window.gap * 1.15 //For some reason this looks more accurate, despite measurements showing otherwise
    }    

    //Set the outer triangle points to the dimensions of the window
    outer.upper.x = (left + right) /2;
    outer.upper.y = top;
    outer.left.x = left;
    outer.left.y = bottom;
    outer.right.x = right;
    outer.right.y = bottom;    
    
    setInnerTriangle(window, outer, inner, offset_scale);
}

//Set the inner triangle vertices
const setInnerTriangle = (window: WindowFragment, outer: Triangle, inner: Triangle, offset_scale: number) =>{
        //Create a vertex buffer and add the outer triangle vertices
        const buffer = initVertexBuffer();
        addVertex(outer.upper.x, outer.upper.y, buffer);
        addVertex(outer.right.x, outer.right.y, buffer);
        addVertex(outer.left.x, outer.left.y, buffer);
        
        //Get the scale factor        
        const scale = getScaleFactor(outer, offset_scale);     
    
        //Scale the buffer
        scaleTranslateBuffer(scale, scale, buffer, window);    
        
        //Set the inner triangle from the buffer
        setTriangleFromBuffer(inner, buffer);              
        
        //Find the distance to the inner bottom or second height
        const panel = window.panel[0];
        const bottom = panel.model.line[Segment.BOTTOM].y1;                
        let inner_bottom = bottom;
        if(window.model.second_height >0){            
            const gap = (window.mask_window.gap * 1.5) /2; //For some reason this lines it up better
            inner_bottom = window.model.second_height - gap;
        }
        
        const distance_bottom = inner_bottom - inner.right.y; //Can be right or left y, it's the same value

        //Determine the distance to the inner side
        let distance_side =0;                
        if(window.model.shape_direction === ShapeDirection.LEFT){           
            distance_side = panel.model.line[Segment.RIGHT].x1 - inner.right.x;            
        }
        else if(window.model.shape_direction === ShapeDirection.RIGHT){
            distance_side = panel.model.line[Segment.LEFT].x1 - inner.left.x
        }

        //Translate the buffer and set the inner triangle from it        
        translateBuffer(distance_side, distance_bottom, buffer);
        setTriangleFromBuffer(inner, buffer);        
}

//Set a triangle from a buffer, going to points in a clockwise motion
const setTriangleFromBuffer = (triangle: Triangle, buffer: VertexBuffer) =>{
    triangle.upper = buffer.vertex[0];
    triangle.right = buffer.vertex[1];
    triangle.left = buffer.vertex[2];
}

//Get the scale factor for a triangle, based on a gap distance
const getScaleFactor = (outer: Triangle, gap: number) =>{
    
    // Calculate the side lengths
    const side_a = getLineLength(outer.upper, outer.left);
    const side_b = getLineLength(outer.left, outer.right);
    const side_c = getLineLength(outer.right, outer.upper);
    
    // Use Heron's formula to calculate the semi-perimeter
    const s = (side_a + side_b + side_c) / 2;

    // Calculate the area using Heron's formula
    const area = Math.sqrt(s * (s - side_a) * (s - side_b) * (s - side_c));

    // Calculate the inradius
    const inradius = area / s;

    // Calculate the scale factor
    const scale = 1 - (gap / inradius);

    return scale;
}


//************ This isn't being used at the moment, but probably hang on to it for now
//Get the center point of a triangle
const getTriangleCentroid = (triangle: Triangle): VertexPoint =>{
    const centroid = initVertexPoint();

    centroid.x = (triangle.upper.x + triangle.left.x + triangle.right.x) /3;
    centroid.y = (triangle.upper.y + triangle.left.y + triangle.right.y) /3;

    return centroid;
}