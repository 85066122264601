
//Vertex Point
export interface VertexPoint{
    x: number,
    y: number,
    w: number
}

//Initialize a point
export const initVertexPoint =(): VertexPoint =>({
    x: 0,
    y: 0,
    w: 1
})

//Get the length of a line between points
export const getLineLength = (start: VertexPoint, end: VertexPoint): number =>{
    return Math.sqrt((end.x - start.x)**2 + (end.y - start.y)**2);    
}

//Get the distance between two parallel lines
export const getLineDistance = (start1: VertexPoint, end1: VertexPoint, start2: VertexPoint, end2:VertexPoint): number =>{    
    const direction1 = getDirectionVector(start1, end1);
    
    //Calculate coefficients
    const a = direction1.y;
    const b = -direction1.x;
    const c1 = a * start1.x + b * start1.y;
    const c2 = a * start2.x + b * start2.y;

    const distance = Math.abs(c2 - c1) / Math.sqrt(a * a + b * b);

    return distance;
}


//Get a point intercept from the slope of a line and a point on that line
export const getSlopeInterceptVertical = (a1: VertexPoint, a2: VertexPoint, b1: VertexPoint, x: number): VertexPoint =>{
    //Maths:
    /*
    b = y1 - mx1
    y = mx + b
    */
    
    const intercept = initVertexPoint();

    const line = getLineEquation(a1, a2);
    const b = b1.y - (line.slope * b1.x);

    const y = (line.slope * x) + b;

    intercept.x = x;
    intercept.y = y;

    return intercept;
}

//Get a point intercept from the slope of a line and a point on that line
export const getSlopeInterceptHorizontal = (a1: VertexPoint, a2: VertexPoint, b1: VertexPoint, y: number): VertexPoint =>{
    //Maths:
    /*
    b = y1 - mx1
        y - b
    x = -----
          m
    */
    
    const intercept = initVertexPoint();

    const line = getLineEquation(a1, a2);
    const b = b1.y - (line.slope * b1.x);

    const x = (y - b) / line.slope;

    intercept.x = x;
    intercept.y = y;
    
    return intercept;
}

//Get the coordinates where a line intercepts a vertical line
export const getInterceptVertical = (a1: VertexPoint, a2: VertexPoint, x: number): VertexPoint =>{
    const intercept: VertexPoint = initVertexPoint();
    const line = getLineEquation(a1, a2);
    
    intercept.x = x;
    intercept.y = line.slope * x + line.intercept;

    return intercept;
}

//Get the coordinates where a line intercepts a horizontal line
export const getInterceptHorizontal = (a1: VertexPoint, a2: VertexPoint, y: number): VertexPoint =>{
    const intercept = initVertexPoint();
    const line = getLineEquation(a1, a2);

    intercept.x = (y - line.intercept) / line.slope;
    intercept.y = y;

    return intercept;
}

//Get the intercept of two lines which are constructed from 2 vertex points
export const getLineIntersect = (a1: VertexPoint, a2: VertexPoint, b1: VertexPoint, b2: VertexPoint): VertexPoint =>{
    /* Equations
    
    x = b2 - b1
        --------
        m1 - m2
    
    y = m1 * x + b1
        
    */
    
    //Get the line equations
    const line_1 = getLineEquation(a1, a2);
    const line_2 = getLineEquation(b1, b2);

    //Calculate the x and y values
    const x = (line_2.intercept - line_1.intercept) / (line_1.slope - line_2.slope);
    const y = line_1.slope * x + line_1.intercept;

    //Create the intersect point and return it
    const intersect = initVertexPoint();
    intersect.x = x;
    intersect.y = y;

    return intersect;
}

//Get the angle between two lines
export const getAngle = (v1: VertexPoint, v2: VertexPoint, intersect: VertexPoint): number =>{
    /* Equation
    Distace Values where C is the intersect
    AB
    AC
    BC
                    AB^2 + AC^2 - BC^2
    cos(theta) = ------------------------
                        2 * AB * AC
    */
    
    //Get the distances
    const AB = getDistance(v1, v2);
    const AC = getDistance(v1, intersect);
    const BC = getDistance(v2, intersect);

    //Shorthanding for easier reading
    const num = AB**2 + AC**2 - BC**2;
    const den = 2 * AB * AC;    

    //Perform the calculations
    const cos_theta = num / den;
    const radians = Math.acos(cos_theta);
    const degrees = 180 - (radians * (180 / Math.PI)); //Convert to degrees and subtract it from 180 to get the angle between sides

    return degrees;
}

//Get the distance between two vertices
const getDistance = (v1: VertexPoint, v2: VertexPoint): number =>{
    const x = Math.pow(v2.x - v1.x, 2);
    const y = Math.pow(v2.y - v1.y, 2);
    
    return Math.sqrt(x + y);
}

//Get the equation of a line in the form of slope intercept
const getLineEquation = (a1: VertexPoint, a2: VertexPoint) =>{
    const m = (a2.y - a1.y) / (a2.x - a1.x);

    const b = a1.y - (m * a1.x);

    return {slope: m, intercept: b};
}

//Get a direction vector between points
const getDirectionVector = (a: VertexPoint, b: VertexPoint): VertexPoint =>{
    const vector: VertexPoint = initVertexPoint();
    vector.x = b.x - a.x;
    vector.y = b.y - a.y;
    return vector;
}











//************************* These aren't being used at the moment; though I may want to hang on to the normal vector function */

/*
//Get an inner offset vertex based on a primary and secondary vertex point
const getInnerOffset = (primary: VertexPoint, secondary: VertexPoint, center: VertexPoint, offset: number): VertexPoint =>{    
    //d   offset value
    //nAX normal x value for A'
    //nAY normal y value for A'

    //A' = (x1 + d * nAX, y1 + d * nAY)
    

    //Calculate the normals
    const normal = getNormal(primary, secondary);
        
    //Initialize the point and traverse the primary along the normal
    const vertex: VertexPoint = initVertexPoint();
    //vertex.x = (primary.x + offset * normal.x);
    vertex.x = primary.x;
    vertex.y = (primary.y + offset * normal.y);
    
    return vertex;
}


//Get a normal from two points
const getNormal = (p1: VertexPoint, p2: VertexPoint) =>{    
    
    //Calculating Normals:

    //           y2 - y1                            x1 - x2
    //------------------------------- , -------------------------------
    //SQRT((x2 - x1)^2 + (y2 - y1)^2)   SQRT((x2 - x1)^2 + (y2 - y1)^2)       
    
    //Just short handing to make it easier to read
    const x1 = p1.x;
    const x2 = p2.x;
    const y1 = p1.y;
    const y2 = p2.y;

    const denom = Math.sqrt(((x2 - x1)**2) + ((y2 - y1)**2));

    let x = (y2 - y1) / denom;
    let y = (x1 - x2) / denom;

    return {x, y}
}
*/