import Form from "react-bootstrap/Form";
import {useState, useEffect} from "react";
import {RenderData} from "../render/render_components/render_data/render_data";
import {ControlRadio} from "../controls/control_radio";
import {ConstrainValues} from "../constraints/constrain_value";
import ControlDropDown from "../controls/control_drop_down";

interface WindowGridProps{ //Props for the Window Grid object    
    id_item: string;    
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, id_key: number) => void;
    renderData: RenderData;
    constrainValues: ConstrainValues;    
}

const DROP_DOWN_MAX_WIDTH = "150px";

const WindowGridForm = ({id_item, DataHandler, renderData, constrainValues}: WindowGridProps) => {

    const INPUT_MAX_WIDTH = "150px"; //Max size of the input text boxes

    //Use States for dvl width and dvl height
    const [dvl_width, setWidth] = useState<string>("");
    const [dvl_height, setHeight] = useState<string>("");
    const [selected, setSelected] = useState<number>(-1);
    const [grid_pattern, setGridPattern] = useState<number>(-1);

    //Handle a text box losing focus
    const handleBlur = (id: string, value: string) =>{
        processTextValue(id, value);
    }

    //Process a text value if it is valid, otherwise revert it
    const processTextValue = (id: string, value: string) =>{
        DataHandler(id_item, "", id, value, -1);
    }

    //Handle a key down event on a text control
    const handleKeyDown = (id: string, value: string, event: React.KeyboardEvent<HTMLElement>) =>{        
        if(event.key === 'Enter'){
            processTextValue(id, value);
        }
    }

    
    //Handle the text boxes, when values are changed/inputted
    const handleTextChange = (input_id: string, input_value: string) =>{
        const value_number = Number(input_value);
        if(!isNaN(value_number)){ //Value must be a number or empty                        
            if(input_id === "dvl_width"){
                setWidth(input_value);
            }
            else if(input_id === "dvl_height"){
                setHeight(input_value);
            }            
        }   
    }

    //Update the controls when renderData changes
    useEffect(() =>{        
        if(renderData.active_fragment_id >=0){ //If a fragment is selected
            const window = renderData.window[renderData.active_fragment_id];               
            const panel = window.panel[window.active_panel_id];

            setWidth(String(panel.mask_grid.grid_x));
            setHeight(String(panel.mask_grid.grid_y));

            setSelected(panel.data.grid.grid_type.id);
            setGridPattern(panel.data.grid.grid_pattern.id);
        }
        else{            
            setWidth("");
            setHeight("");            
            setSelected(-1);
            setGridPattern(-1);
        }        
    }, [renderData])

    //Determine if the apply all is checked
    const isApplyAll = (): boolean =>{
        return renderData.all_window.apply_all_grid;        
    }

    //Handle when the check box
    const handleApplyAll = (is_checked: boolean) =>{
        DataHandler(id_item, "apply_all", "apply_all", String(is_checked), -1);
    }
    
    return(
        <Form>
            <Form.Check 
                type="checkbox"
                label="Apply To All"
                checked={isApplyAll()}
                onChange={(event) => handleApplyAll(event.target.checked)}
            />
            <div key="grid_type" className="mb-3">
                <ControlRadio
                    id_item={id_item}                    
                    group_name="group_grid_type"                
                    DataHandler={DataHandler}
                    parameter={renderData.config_values.grid_type}
                    id_select={selected}
                    constrain={constrainValues.grid_type.parameter}                    
                />            
            </div>
            <div className="mb-3" style={{maxWidth: DROP_DOWN_MAX_WIDTH}}>                
                <Form.Label>Grid Pattern</Form.Label>
                <ControlDropDown 
                    id_item={id_item}
                    id_object="grid_pattern"
                    DataHandler={DataHandler}
                    parameter={renderData.config_values.grid_pattern}
                    constrain={constrainValues.grid_pattern.parameter}
                    id_select={grid_pattern}
                />
            </div>

            {!constrainValues.control.grid_dvl.is_hidden && (
                <>
                    <Form.Group className="mb-3">                    
                        <Form.Label>DVL Width</Form.Label>
                        <Form.Control 
                            id="dvl_width"
                            type="text" 
                            placeholder="Enter DVL Width" 
                            style={{width: INPUT_MAX_WIDTH}}
                            value={dvl_width}
                            onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                            onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                            onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)} 
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>DVL Height</Form.Label>
                        <Form.Control 
                            id="dvl_height"
                            type="text" 
                            placeholder="Enter DVL Height" 
                            style={{width: INPUT_MAX_WIDTH}} 
                            value={dvl_height}
                            onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                            onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                            onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)} 
                        />
                    </Form.Group>
                </>
            )}
        </Form>
    );
}

export default WindowGridForm;