import {RenderData} from "../../render/render_components/render_data/render_data";

const Screen = (id_object: string, object_value: string, renderData: RenderData) =>{
    
    const window = renderData.window[renderData.active_fragment_id];
    const panel = window.panel[window.active_panel_id];

    switch(id_object){
        case "group_screen":
            if(object_value === "With Screen"){        
                panel.data.has_screen = true;
            }
            else{
                panel.data.has_screen = false;
            }

            renderData.render_frame = true;
        break;
        case "screen_only":
            if(object_value === "true"){
                renderData.data.is_screen_only = true;
            }
            else{
                renderData.data.is_screen_only = false;
            }
        break;
    }
}

export default Screen;