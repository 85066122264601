import {RenderData} from "../render_data/render_data";
import {WindowFragment} from "../window/window_fragment";
import {clearWindow} from "./mask_shape";
import {drawBuffer} from "./mask_buffer";

//Draw an octagon window
export const maskOctagon = (window: WindowFragment, renderData: RenderData) =>{
    drawMaskBuffer(window, renderData);
}

//Draw a hexagon window
export const maskHexagon = (window: WindowFragment, renderData: RenderData) =>{
    drawMaskBuffer(window, renderData);
}

//Draw a mask buffer
const drawMaskBuffer = (window: WindowFragment, renderData: RenderData) =>{
    clearWindow(renderData, window);
    
    //Draw the frame color
    drawBuffer(window.mask_buffer.outer, renderData, renderData.all_window.color_frame);
    
    //Blot out the inner window
    drawBuffer(window.mask_buffer.inner, renderData, "rgba(256,256,256,1");

    //Draw the inner window color
    drawBuffer(window.mask_buffer.inner, renderData, window.mask_window.color_window);
    
    //Draw the outer and inner borders
    drawBuffer(window.mask_buffer.outer, renderData);
    drawBuffer(window.mask_buffer.inner, renderData);
}

//Draw an oval window
export const maskOval = (window: WindowFragment, renderData: RenderData) =>{
    clearWindow(renderData, window);

    const circle = window.mask_circle;

    //Draw the frame color oval
    drawOval(
        circle.center.x,
        circle.center.y,
        circle.outer_radius.x,
        circle.outer_radius.y,
        renderData,
        renderData.all_window.color_frame
    );
    
    //Blot out the center
    drawOval(
        circle.center.x,
        circle.center.y,
        circle.inner_radius.x,
        circle.inner_radius.y,
        renderData,
        "rgba(256,256,256,1)"
    );

    //Draw the inner window
    drawOval(
        circle.center.x,
        circle.center.y,
        circle.inner_radius.x,
        circle.inner_radius.y,
        renderData,
        window.mask_window.color_window
    );

    //Draw the outer outlinee
    drawOval(
        circle.center.x,
        circle.center.y,
        circle.outer_radius.x,
        circle.outer_radius.y,
        renderData
    );

    //Draw the inner outline
    drawOval(
        circle.center.x,
        circle.center.y,
        circle.inner_radius.x,
        circle.inner_radius.y,
        renderData
    );    
}

//Draw an oval
export const drawOval = (
    center_x: number,
    center_y: number,
    radius_x: number,
    radius_y: number,
    renderData: RenderData,
    fillStyle?: string
) =>{
    const context = renderData.viewport.context;
    context!.beginPath();
    context!.ellipse(
        center_x,
        center_y,
        radius_x,
        radius_y,
        0,
        0,
        2 * Math.PI
    )

    if(fillStyle){
        context!.fillStyle = fillStyle;
        context!.fill();
    }
    else{
        context!.stroke();
    }
}

//Draw a circle
export const maskCircle = (window: WindowFragment, renderData: RenderData) =>{
    clearWindow(renderData, window);

    //Shorthand
    const circle = window.mask_circle;

    //Draw a circle with the frame color
    drawCircle(
        circle.center.x,
        circle.center.y,
        circle.outer_radius.x,
        renderData,
        renderData.all_window.color_frame
    )
    
    //Blot out the inner circle
    drawCircle(
        circle.center.x,
        circle.center.y,
        circle.inner_radius.x,
        renderData,
        "rgba(256,256,256,1)"
    )

    //Draw the inner circle window color
    drawCircle(
        circle.center.x,
        circle.center.y,
        circle.inner_radius.x,
        renderData,
        window.mask_window.color_window
    )

    //Draw the outer circle
    drawCircle(
        circle.center.x,
        circle.center.y,
        circle.outer_radius.x,
        renderData
    )

    //Draw the inner circle
    drawCircle(
        circle.center.x,
        circle.center.y,
        circle.inner_radius.x,
        renderData
    )
}

export const drawCircle = (
    center_x: number,
    center_y: number,
    radius: number, 
    renderData: RenderData,
    fillStyle?: string
) =>{
    const context = renderData.viewport.context;

    context!.beginPath();
    context!.arc(
        center_x,
        center_y,
        radius,
        0,
        2 * Math.PI
    );
    if(fillStyle){
        context!.fillStyle = fillStyle;
        context!.fill();
    }
    context!.stroke();
}