import {RenderData} from "../../render/render_components/render_data/render_data";
import {setFrameColor} from "../../render/render_components/viewport/functions_viewport";
import {FrameColor} from "../../render/render_components/render_data/enum_render";
import {ConstrainValues} from "../../constraints/constrain_value";
import {constrainFrameColor} from "../../constraints/functions_constrain";

const WindowFrameColor = (    
    object_value: string,
    key_id: number,    
    renderData: RenderData,
    constrainValues: ConstrainValues    
) =>{
    switch(object_value){
        case "Almond":
            setFrameColor(renderData, FrameColor.ALMOND);
        break;
        case "Black Capstock":
            setFrameColor(renderData, FrameColor.BLACK_CAP);
        break;
        case "Bronze":
            setFrameColor(renderData, FrameColor.BRONZE);
        break;
        case "White":
            setFrameColor(renderData, FrameColor.WHITE);
        break;
        default:
            setFrameColor(renderData, FrameColor.DEFAULT);
        break;
    }
    constrainFrameColor(object_value, constrainValues);

    if(key_id === -1 && renderData.data.frame_color.id >0){
        renderData.data.frame_color.value = "";
        renderData.data.frame_color.id =0;
    }
    else{
        renderData.data.frame_color.value = object_value;
        renderData.data.frame_color.id = key_id;    
    }
}

export default WindowFrameColor;