import { ConstrainValues } from "./constrain_value"
import { RenderData } from "../render/render_components/render_data/render_data"
import { WindowFragment, initLine, Line } from "../render/render_components/window/window_fragment"
import { OpenDirection, Segment } from "../render/render_components/render_data/enum_render"
import { PanelFragment } from "../render/render_components/panel/panel_fragment"

//Check for limits on the vents
export const limitVent = (constrainValues: ConstrainValues, renderData: RenderData) =>{
    //Iterate over the windows
    renderData.window.forEach(window =>{
        switch(window.data.product_type.value){
            case "OOX":
            case "OX":
            case "OXO":
            case "OXXO":
            case "XO":
            case "XOO":
            case "XOX":
            case "Single Hung":                
                checkVent(constrainValues, renderData, window);
            break;
        }
    })
}

//Find the vents and check them
const checkVent = (constrainValues: ConstrainValues, renderData: RenderData, window: WindowFragment) =>{
    //Iterate over the panels
    window.panel.forEach(panel =>{        
        //If the open direction of the panel isn't none
        if(panel.mask_open.direction !== OpenDirection.NONE){
            checkMinVent(constrainValues, renderData, panel);
        }
    })
}

//Check the minimum vent length
const checkMinVent = (constrainValues: ConstrainValues, renderData: RenderData, panel: PanelFragment) =>{    
    //If the width is less than the minimum vent length
    if(panel.model_inch.width < constrainValues.limit.min_vent_length){
        addErrorLine(renderData, panel.model.line[Segment.LEFT]);
        addErrorLine(renderData, panel.model.line[Segment.RIGHT]);
    }
    //If the height is less than the minimum vent length
    if(panel.model_inch.height < constrainValues.limit.min_vent_length){
        addErrorLine(renderData, panel.model.line[Segment.TOP]);
        addErrorLine(renderData, panel.model.line[Segment.BOTTOM]);
    }
}

//Add a line as an error
const addErrorLine = (renderData: RenderData, vent_line: Line) =>{
    const vent_error = renderData.error.vent_size;

    const line = initLine();

    line.x1 = vent_line.x1;
    line.x2 = vent_line.x2;
    line.y1 = vent_line.y1;
    line.y2 = vent_line.y2;

    vent_error.line[vent_error.line.length] = line;

}