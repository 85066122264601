import {
    Matrix,     
    initMatrix,    
} from "./matrix_data";
import{
    VertexPoint,
    initVertexPoint
} from "./vertex_point";

//Multiply two matrices together and return a new one with the result
export const multiMatrix = (a: Matrix, b: Matrix): Matrix =>{
    let matrix = initMatrix();

    /*
    |a0  a1  a2|    |b0  b1  b2|
    |a3  a4  a5| x  |b3  b4  b5| =
    |a6  a7  a8|    |b6  b7  b8|
    
    |a0*b0 + a1*b3 + a2*b6      a0*b1 + a1*b4 + a2*b7       a0*b2 + a1*b5 + a2*b8|
    |a3*b0 + a4*b3 + a5*b6      a3*b1 + a4*b4 + a2*b7       a5*b2 + a1*b5 + a2*b8|
    |a6*b0 + a7*b3 + a8*b6      a6*b1 + a7*b4 + a8*b7       a6*b2 + a7*b5 + a8*b8|
    */

    //First Row
    matrix.c0 = (a.c0 * b.c0) + (a.c1 * b.c3) + (a.c2 * b.c6); 
    matrix.c1 = (a.c0 * b.c1) + (a.c1 * b.c4) + (a.c2 * b.c7); 
    matrix.c2 = (a.c0 * b.c2) + (a.c1 * b.c5) + (a.c2 * b.c8);
    //Second Row
    matrix.c3 = (a.c3 * b.c0) + (a.c4 * b.c3) + (a.c5 * b.c6); 
    matrix.c4 = (a.c3 * b.c1) + (a.c4 * b.c4) + (a.c5 * b.c7); 
    matrix.c5 = (a.c3 * b.c2) + (a.c4 * b.c5) + (a.c5 * b.c8);
    //Third Row
    matrix.c6 = (a.c6 * b.c0) + (a.c7 * b.c3) + (a.c8 * b.c6); 
    matrix.c7 = (a.c6 * b.c1) + (a.c7 * b.c4) + (a.c8 * b.c7); 
    matrix.c8 = (a.c6 * b.c2) + (a.c7 * b.c5) + (a.c8 * b.c8);

    return matrix;
}

//Multiply a matrix by a vector and return a new vertex
export const multiMatrixVertex = (m: Matrix, v: VertexPoint): VertexPoint =>{
    let vertex = initVertexPoint();

    /*
    |m0  m1  m2|    |v0|
    |m3  m4  m5| x  |v1| =
    |m6  m7  m8|    |v2|

    |m0*v0 + m1*v1 + m2*v2|
    |m3*v0 + m4*v1 + m5*v2|
    |m6*v0 + m7*v1 + m8*v2|
    */

    vertex.x = (m.c0 * v.x) + (m.c1 * v.y) + (m.c2 * v.w);
    vertex.y = (m.c3 * v.x) + (m.c4 * v.y) + (m.c5 * v.w);
    vertex.w = (m.c6 * v.x) + (m.c7 * v.y) + (m.c8 * v.w);
    
    return vertex;
}

export const getScaleCoord = (current_min: number, current_max: number, new_min: number, new_max: number, coord: number): number =>{

    //Order of operations
    // v1 = coordinate vector
    // s = scalar matrix
    // t1 = translate to 0 matrix
    // t2 = translate to new origin matrix
    //
    // t1 * v1 = vector
    // s * vector = vector
    // t2 * vector = vector

    //Create the scalar matrix
    const scalar: number = (new_max - new_min) / (current_max - current_min);
    let s: Matrix = initMatrix();
    s.c0 = scalar;
    s.c4 = scalar;
    
    //Create the matrix that translates to 0
    const t1: Matrix = initMatrix();
    t1.c2 = -current_min;

    //Create the matrix that translates to the new origin
    const t2: Matrix = initMatrix();
    t2.c2 = new_min;

    //Put the coord in a vector
    let v1: VertexPoint = initVertexPoint();
    v1.x = coord;

    //Translate, scale and translate vector
    let vertex = multiMatrixVertex(t1, v1);
    vertex = multiMatrixVertex(s, vertex);
    vertex = multiMatrixVertex(t2, vertex);

    //The first cell will be the scaled coordinate
    return vertex.x;    
}

//Scale a matrix 
export const getScaleMatrix = (scale_x: number, scale_y: number): Matrix =>{
    let matrix = initMatrix();

    matrix.c0 = scale_x;
    matrix.c4 = scale_y;

    return matrix;
}

//Get a translation matrix
export const getTranslateMatrix = (x: number, y: number): Matrix =>{
    let matrix = initMatrix();

    matrix.c2 =x;
    matrix.c5 =y;

    return matrix;
}



