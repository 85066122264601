import {    
    Matrix
} from "./matrix_data";
import {
    getScaleMatrix,    
    getTranslateMatrix,
    multiMatrixVertex
} from "./matrix_functions";
import {
    VertexPoint,
    initVertexPoint
} from "./vertex_point";

import {WindowFragment} from "../window/window_fragment";
import {Segment} from "../render_data/enum_render";

//Vertex buffer interface
export interface VertexBuffer{
    vertex: VertexPoint[]
}

//Initialize a vertex buffer
export const initVertexBuffer = (): VertexBuffer =>({
    vertex: []
})

//Add a vertex to a vertex buffer
export const addVertex = (x: number, y: number, buffer: VertexBuffer) => {    
    const vertex = initVertexPoint();
    vertex.x = x;
    vertex.y = y;
    
    buffer.vertex[buffer.vertex.length] = vertex;
}

//Scale a vertex buffer on the x and y axis and translate it
//For some reason, this seems to work with objects built around the center of the window or something
export const scaleTranslateBuffer = (scale_x: number, scale_y: number, buffer: VertexBuffer, window: WindowFragment) =>{
    /*
    const x = renderData.viewport.width /2;
    const y = renderData.viewport.height /2;
    */
    const x = (window.model.line[Segment.RIGHT].x1 - window.model.line[Segment.LEFT].x1) /2;
    const y = (window.model.line[Segment.BOTTOM].y1 - window.model.line[Segment.BOTTOM].y1) /2;

    //Get the matrices for scaling to and from the origin
    const t1 = getTranslateMatrix(-x, -y);
    const t2 = getTranslateMatrix(x, y);
    //Get the scale matrix
    const s1 = getScaleMatrix(scale_x, scale_y);

    //Translate, Scale, Translate
    multiMatrixBuffer(buffer, t1);    
    multiMatrixBuffer(buffer, s1);    
    multiMatrixBuffer(buffer, t2);
}

//Scale a vertex buffer with the object centered at 0,0
export const scaleBuffer = (scale_x: number, scale_y: number, buffer: VertexBuffer) =>{
    const scale = getScaleMatrix(scale_x, scale_y);
    multiMatrixBuffer(buffer, scale);
}

//Translate a buffer by an x and y value
export const translateBuffer = (x: number, y: number, buffer: VertexBuffer) =>{
    const t1 = getTranslateMatrix(x, y);
    multiMatrixBuffer(buffer, t1);    
}

//Pack a buffer with vertices from an array in the form of x, y
export const packBuffer = (vertices: number[], buffer: VertexBuffer) =>{
    //The array is expected to be in the form of repeating x y coordinates

    //Step through the array 2 values at a time
    for(let counter =0; counter < vertices.length; counter +=2){
        const x = vertices[counter];
        const y = vertices[counter+1];
        addVertex(x, y, buffer);
    }
}

//Get a copy of a buffer
export const copyBuffer = (buffer: VertexBuffer): VertexBuffer =>{
    const copy = initVertexBuffer();

    //Loop through the buffer and copy it's values
    for(let counter =0; counter < buffer.vertex.length; counter ++){        
        const vertex = buffer.vertex[counter];

        copy.vertex[counter] = initVertexPoint();
        copy.vertex[counter].x = vertex.x;
        copy.vertex[counter].y = vertex.y;
    }

    return copy;
}

//Multiply a matrix against a buffer
const multiMatrixBuffer = (buffer: VertexBuffer, matrix: Matrix) =>{    
    //Iterate through all the vertices and multiply them against the matrix
    
    for(let counter =0; counter < buffer.vertex.length; counter ++){        
        buffer.vertex[counter] = multiMatrixVertex(matrix, buffer.vertex[counter]);
    }
}

