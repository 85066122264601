import {Model, initModel} from "../window/window_fragment";
import {MaskDimension, MaskOpen, MaskGrid, MaskScreen} from "../mask/mask_data";
import {OpenDirection} from "../render_data/enum_render";
import {DataPoint, initDataPoint} from "../render_data/render_data";


interface ModelInch{
    width: number
    height: number    
    display_width: number
    display_height: number
}

interface DataGlass{
    tempered: boolean
    id: number
    value: string
    default_value: string
}

interface PanelGlass{
    inner: DataGlass
    outer: DataGlass
}

const initDataGlass = ():DataGlass =>({
    tempered: false,
    id: -1,
    value: "",
    default_value: "Clear"
})

interface GridData{
    grid_type: DataPoint
    grid_pattern: DataPoint
    has_grid: boolean
    dvl_width: number
    dvl_height: number
}

const initGridData = ():GridData =>({
    grid_type: initDataPoint("Flat DVL"),
    grid_pattern: initDataPoint("No Grid"),
    has_grid: false,
    dvl_width: 0,
    dvl_height: 0
})

interface PanelData{
    glass: PanelGlass
    has_screen: boolean
    grid: GridData
    option: Map<string, DataPoint>    
}

export interface PanelFragment{
    id: number //ID for the panel fragment
    model: Model //Vertices of panel fragment
    model_inch: ModelInch //Width and height of the panel in inches
    type: number //The type of panel (for example, left of three)
    mask_dimension: MaskDimension //Mask for drawing dimension values
    mask_open: MaskOpen //Mask for drawing the opening direction
    mask_grid: MaskGrid //Mask for drawing grids
    data: PanelData //Data for the panel
    mask_screen: MaskScreen //Mask for drawing the screen 
}

export const initPanelFragment = ():PanelFragment =>({
    id: -1,
    model: initModel(),
    model_inch: {
        width: 0,
        height: 0,
        display_width: 0,
        display_height: 0
    },
    type: -1,
    mask_dimension: {
        //font: "20px Arial",
        font: "25px Arial",
        fill_style: "black",
        adjust: 10
    },
    mask_open: {
        direction: OpenDirection.NONE,
        line_width: 2,
        line_style :"rgba(156, 127, 0, 1)"
    },
    mask_grid: {
        grid_x: 0,
        grid_y: 0,
        line_width: 2,
        line_style: "black"
    },
    data:{
        glass: {
            inner: initDataGlass(),
            outer: initDataGlass()
        },
        has_screen: false,
        grid: initGridData(),
        option: new Map<string, DataPoint>()
    },
    mask_screen:{
        screen_color: "rgba(0, 0, 0, 0.25)"
    }
});