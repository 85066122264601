import {RenderData} from "../render/render_components/render_data/render_data";
import {OrderRequest} from "./order/order_request";
import {OrderList} from "./order/order_list";
import {ConstrainValues} from "../constraints/constrain_value";

// Helper function to deep copy an object
function deepCopy<T>(obj: T): T {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
    if (obj instanceof CanvasRenderingContext2D) {
        return obj; // Return the context as is
    }
    if (Array.isArray(obj)) {
        return (obj.map(item => deepCopy(item)) as unknown) as T;
    }
    if (obj instanceof Map) {
        const copiedMap = new Map();
        obj.forEach((value, key) => {
            copiedMap.set(key, deepCopy(value));
        });
        return (copiedMap as unknown) as T;
    }
    const copiedObj: any = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            copiedObj[key] = deepCopy((obj as { [key: string]: any })[key]);
        }
    }
    return copiedObj as T;
}

// Get a copy of the renderData object
export const getRenderDataCopy = (renderData: RenderData): RenderData => {
    return deepCopy(renderData);
};

//Geta a copy of the order request object
export const getOrderRequestCopy = (orderRequest: OrderRequest): OrderRequest =>{
    return deepCopy(orderRequest);
}

//Get a copy of the order list
export const getOrderListCopy = (orderList: OrderList) =>{
    return deepCopy(orderList);
}

//Get a copy of the constrain maps
export const getConstrainCopy = (constrain: ConstrainValues): ConstrainValues =>{
    return deepCopy(constrain);
}