import {VertexPoint, initVertexPoint} from "./vertex_point";

export interface Triangle{
    upper: VertexPoint,
    left: VertexPoint,
    right: VertexPoint
}

//Initialize a triangle
export const initTriangle =(): Triangle =>({
    upper: initVertexPoint(),
    left: initVertexPoint(),
    right: initVertexPoint()
})