import {RenderData} from "../render_data/render_data";
import {Triangle} from "../model/model_data";
import {WindowFragment} from "../window/window_fragment";
import {clearWindow} from "./mask_shape";
import {Segment, ShapeDirection} from "../render_data/enum_render";
import {drawInnerLower, drawLineCoord} from "./mask_shape";

//Draw a straight right triangle; which is a rake
export const maskStraightRightTriangle = (window: WindowFragment, renderData: RenderData) =>{
    maskTriangle(window, renderData);
    drawInnerLower(renderData, window);    
    coverHeightLine(renderData, window);
    drawRightTriangleSide(renderData, window);
}

//Draw a right trianglee
export const maskRightTriangle = (window: WindowFragment, renderData: RenderData) =>{
    maskTriangle(window, renderData);

    drawRightTriangleSide(renderData, window);

    //This is to account for lines drawing off the canvas at the bottom
    let bottom = window.mask_triangle.outer_triangle.right.y; //Can be left or right
    if(bottom === renderData.viewport.height){
        const context = renderData.viewport.context;
        context!.lineWidth = window.mask_window.line_width;
        context!.strokeStyle = window.mask_window.line_style;
        
        bottom -=2;
        const left = window.mask_triangle.outer_triangle.left.x;
        const right = window.mask_triangle.outer_triangle.right.x;

        drawLineCoord(left, bottom, right, bottom, renderData);
    }
}

//Draw a filled in triangle
export const drawTriangleFill = (renderData: RenderData, triangle: Triangle, color: string) =>{
    const context = renderData.viewport.context;

    context!.fillStyle = color;    
    outlineTriangle(renderData, triangle);
    context!.fill();
}

//Draw a triangle window
export const maskTriangle = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;

    clearWindow(renderData, window);

    //Draw the frame color, blot out the inner triangle and draw the inner triangle fill
    drawTriangleFill(renderData, window.mask_triangle.outer_triangle, renderData.all_window.color_frame);
    drawTriangleFill(renderData, window.mask_triangle.inner_triangle, "rgba(256,256,256,1)")
    drawTriangleFill(renderData, window.mask_triangle.inner_triangle, window.mask_window.color_window);
    
    context!.strokeStyle = window.line_style;
    context!.lineWidth = window.line_width;        
    
    let has_bottom = true;
    let has_side = true;
    
    //Draw inner triangle check for second height
    if(window.model.second_height > 0){
        drawUpperTriangle(renderData, window.mask_triangle.inner_triangle);
        has_bottom = false;
    }
    else{
        drawTriangle(renderData, window.mask_triangle.inner_triangle);
    }
    
    //Check if the window borders the bottom and/or side of the viewport
    if(window.model.line[Segment.BOTTOM].y1 !== renderData.viewport.height){
        has_bottom = false;
    }
    if(window.model.shape_direction === ShapeDirection.LEFT){                        
        if(window.model.line[Segment.RIGHT].x1 !== renderData.viewport.width){
            has_side = false;
        }
    }
    else if(window.model.shape_direction === ShapeDirection.RIGHT){
        if(window.model.line[Segment.LEFT].x1 !== 0){
            has_side = false;
        }
    }            

    //It's a stand alone window
    if(has_bottom && has_side){
        drawTriangle(renderData, window.mask_triangle.outer_triangle);
    }
    else{ //It borders another window
        if(!has_bottom && !has_side){ //No bottom and no side
            drawSlant(renderData, window, window.mask_triangle.outer_triangle);
        }
        else if(!has_bottom){ //No bottom
            drawUpperTriangle(renderData, window.mask_triangle.outer_triangle);
        }
        else if(!has_side){ //No side                
            drawAngleSide(renderData, window, window.mask_triangle.outer_triangle);
        }
    }    
}

//Draw a peak triangle
export const maskPeak = (window: WindowFragment, renderData: RenderData) =>{    
    maskTriangle(window, renderData);
    drawInnerLower(renderData, window);
    coverHeightLine(renderData, window);
}

//Draw the top of a triangle
export const drawUpperTriangle = (renderData: RenderData, triangle: Triangle) =>{
    const context = renderData.viewport.context;

    context!.beginPath();
    context!.moveTo(triangle.left.x, triangle.left.y);
    context!.lineTo(triangle.upper.x, triangle.upper.y);
    context!.lineTo(triangle.right.x, triangle.right.y);
    context!.stroke();
}

//Draw a triangle
export const drawTriangle = (renderData: RenderData, triangle: Triangle) =>{
    const context = renderData.viewport.context;
    outlineTriangle(renderData, triangle);
    context!.stroke();
}

//This is all to account for lines partially drawing off the canvas    
const drawRightTriangleSide = (renderData: RenderData, window: WindowFragment) =>{
    
    const context = renderData.viewport.context;
    context!.lineWidth = window.mask_window.line_width;
    context!.strokeStyle = window.mask_window.line_style;

    //Get the x and y of the upper and lower points of the tall side of the triangle
    const offset = 2;
    const top = window.mask_triangle.outer_triangle.upper.y;
    const bottom = window.mask_triangle.outer_triangle.right.y; //It can be left or right, it's the same
    let x = window.mask_triangle.outer_triangle.upper.x;
    if(window.model.shape_direction === ShapeDirection.LEFT){               
        if(window.model.line[Segment.RIGHT].x1 === renderData.viewport.width){
            x -= offset;
            drawLineCoord(x, top, x, bottom, renderData);    
        }
    }
    else{
        if(window.model.line[Segment.LEFT].x1 === 0){
            //For some reason, there's a little line off on right shaped right triangles
            //This just draws a white line to erase it, then redraw a black line
            context!.save();
            context!.strokeStyle = "rgba(256,256,256,1)";
            drawLineCoord(x, top, x, bottom, renderData);
            context!.restore();
            x += offset;
            drawLineCoord(x, top, x, bottom, renderData);    
        }   
    }
}

//Draw the slant of a right triangle
const drawSlant = (renderData: RenderData, window: WindowFragment, triangle: Triangle) =>{
    const context = renderData.viewport.context;
    context!.beginPath();
    context!.moveTo(triangle.upper.x, triangle.upper.y);
    if(window.model.shape_direction === ShapeDirection.LEFT){
        context!.lineTo(triangle.left.x, triangle.left.y);
    }
    else{
        context!.lineTo(triangle.right.x, triangle.right.y);
    }
    context!.stroke();
}

//Draw the angle side of the triangle
const drawAngleSide = (renderData: RenderData, window: WindowFragment, triangle: Triangle) =>{
    const context = renderData.viewport.context;
    context!.beginPath();
    context!.moveTo(triangle.upper.x, triangle.upper.y);
    if(window.model.shape_direction === ShapeDirection.LEFT){
        context!.lineTo(triangle.left.x, triangle.left.y);
        context!.lineTo(triangle.right.x, triangle.right.y);
    }
    else{
        context!.lineTo(triangle.right.x, triangle.right.y);
        context!.lineTo(triangle.left.x, triangle.left.y);
    }
    context!.stroke();
}

//Set the outline of a triangle
const outlineTriangle = (renderData: RenderData , triangle: Triangle) =>{
    const context = renderData.viewport.context;

    //Draw a triangle
    context!.beginPath();
    context!.moveTo(triangle.upper.x, triangle.upper.y);
    context!.lineTo(triangle.left.x, triangle.left.y);
    context!.lineTo(triangle.right.x, triangle.right.y);
    context!.closePath();
    
}

//This is to account for an anomoly that occurs between the top and bottom pieces
const coverHeightLine = (renderData: RenderData, window: WindowFragment) =>{
    const context = renderData.viewport.context;
    
    const inner = window.mask_triangle.inner_triangle;
    const offset = 3;

    const x1 = inner.left.x + offset;
    const y1 = inner.left.y;
    const x2 = inner.right.x - offset;
    const y2 = inner.right.y;
    
    context!.strokeStyle = "rgba(256,256,256,1)";
    context!.lineWidth = 3;
    drawLineCoord(x1, y1, x2, y2, renderData);    
    context!.strokeStyle = window.mask_window.color_window;
    drawLineCoord(x1, y1, x2, y2, renderData);
}