import Form from 'react-bootstrap/Form';
import {Fragment} from "react";
import {RecordData} from "../render/render_components/render_data/config_values";
import {ConstrainParameter} from "../constraints/constrain_value";

interface Props{
    id_item: string; //Name of the accordion item
    group_name: string; //The name of the group of controls     
    DataHandler:(
        id_item: string, 
        id_object: string, 
        form_object: string, 
        object_value: string, 
        id_key: number
    ) => void, //Data handling function for when something is selected
    parameter: Map<string, RecordData>, //The map containing all the parameter values pulled from the server
    constrain: Map<number, ConstrainParameter>
    select_array: number[]    
}

export const ControlCheck =({
    id_item,    
    group_name, 
    DataHandler,
    parameter,
    constrain,
    select_array
}: Props) =>{
    
    //Handle when a box is checked or unchecked
    const handleChange = (key_id: number, label_value: string, is_checked: boolean) =>{
        DataHandler(id_item, group_name, label_value, String(is_checked), key_id);
    }
    
    //Determine if a checkbox is hidden
    const isHidden = (record_id: number): boolean =>{
        const is_hidden = constrain.get(record_id)?.is_hidden;

        if(is_hidden !== undefined) return is_hidden;        

        //Return true if the record was not found; this shouldn't happen
        return true;
    }
    
    //Determine if a checkbox is disabled
    const isDisabled = (record_id: number): boolean =>{
        const is_disabled = constrain.get(record_id)?.is_disabled;

        if(is_disabled !== undefined) return is_disabled;

        //Return true if a record was not found; this shouldn't happen
        return true;
    }    

    //Determine if a check box is checked
    const isChecked = (record_id: number): boolean =>{        
        //If the record id is in the array        
        return select_array.includes(record_id);
    }
    
    return (      
        <Fragment>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', // Adjust number of columns and min width as needed
                gap: '5px', // Space between items
            }}>
                {Array.from(parameter.values())
                    .filter(param =>!isHidden(param.id))
                    .map(param =>(
                        <Form.Check 
                            key={String(param.id)}
                            type="checkbox"
                            name={group_name}
                            id={group_name + "_" + String(param.id) + "_" + param.description}
                            label={param.description}
                            checked={isChecked(param.id)}
                            onChange={(event) => handleChange(param.id, param.description, event.target.checked)}
                            disabled={isDisabled(param.id)}                            
                        />
                    ))
                }
            </div>
        </Fragment>
    );
}