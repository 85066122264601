import React from "react"

export interface OrderPrint{
    lines: string,
    line_count: number,
    img_canvas: string,
    img_width: number,
    img_height: number,
    canvas_ref: React.RefObject<HTMLCanvasElement>
}

export const initOrderPrint =(): OrderPrint =>({
    lines: "",
    line_count: 0,
    img_canvas: "",
    img_width: 0,
    img_height: 0,
    canvas_ref: React.createRef<HTMLCanvasElement>()
})

