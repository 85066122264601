import {RenderData} from "../../render/render_components/render_data/render_data";
import {setViewPortSize} from "../../render/render_components/viewport/functions_viewport";
import {defaultPatio} from "./functions_data";
import {calloutToNet} from "./functions_data";

const FrameType = (object_value: string, key_id: number, renderData: RenderData) =>{
    
    if(key_id === -1 && renderData.data.frame_type.id >0){
        renderData.data.frame_type.id =0;
        renderData.data.frame_type.value ="";
    }
    else{
        renderData.data.frame_type.value = object_value;
        renderData.data.frame_type.id = key_id;

        if(renderData.data.is_callout){
            //Set the size of the overall net based on the callout
            renderData.viewport.inch_width = calloutToNet(renderData.data.callout, renderData).width;
            renderData.viewport.inch_height = calloutToNet(renderData.data.callout, renderData).height;                

            switch(object_value){               
                case "Flush Fin":
                case "Nail On":
                case "NuWall":
                    //Adjust the overall size based on specific frames
                    renderData.viewport.inch_width -= 0.25;
                    renderData.viewport.inch_height -= 0.25;
                break;
            }

            //Recreate the window
            setViewPortSize(renderData);

            defaultPatio(renderData);
        }
    }    
}

export default FrameType;