import {useState, useEffect} from "react";
import Accordion from "react-bootstrap/Accordion";
import XOPProperties from "./static_xop_properties";
import WindowProperties from "./static_window_properties";
import WindowGridForm from "./static_window_grid_form";
import WindowSizeForm from "./static_window_size_form";
import GlassForm from "./static_glass_form";
import ScreenForm from "./static_screen";
import {ControlRadio} from "../controls/control_radio";
import {RecordData} from "../render/render_components/render_data/config_values";
import {RenderData} from "../render/render_components/render_data/render_data";
import {ConstrainValues, ConstrainParameter} from "../constraints/constrain_value";
import {OptionForm} from "./static_option";


interface DataHandlerProp{
    DataHandler: (
        id_item: string, 
        form_group: string,
        form_object: string, 
        object_value: string,
        key_id: number,        
    ) => void;   
    renderData: RenderData,
    constrainValues: ConstrainValues
}

export const ItemID ={
    WINDOW_SIZE: "window_size",
    PRODUCT_LINE: "product_line",
    XOPPROPERTIES: "xop_properties",
    PRODUCT_TYPE: "product_type",
    WINDOW_PROPERTIES: "window_properties",
    GLASS: "glass",
    GLASS_TREATMENT: "glass_treatment",
    WINDOW_GRID: "window_grid",
    FRAME_TYPE: "frame_type",
    FRAME_COLOR: "frame_color",
    SCREEN: "screen",
    OPTION: "option"    
}

export const ItemSize = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{    
    
    return (
        <Accordion.Item eventKey={ItemID.WINDOW_SIZE}>
            <Accordion.Header>Size</Accordion.Header>
            <Accordion.Body>
                <WindowSizeForm                    
                    id_item={ItemID.WINDOW_SIZE}
                    DataHandler={DataHandler}
                    renderData={renderData}
                />
            </Accordion.Body>
        </Accordion.Item>
    );    
}

//Product Line
export const ItemProductLine = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{   
    
    const [selectProduct, setSelectProduct] = useState<number>(-1);    

    useEffect(() =>{
        setSelectProduct(renderData.data.product_line.id);
    },[renderData])

    return ItemRadio(
        ItemID.PRODUCT_LINE,
        "Product Line",
        "group_product_line",
        DataHandler,
        renderData.config_values.product_line,
        constrainValues.product_line.parameter,        
        selectProduct        
    );    
}

//Frame Type
export const ItemFrameType = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{
    
    const [selectType, setSelectType] = useState<number>(-1);

    useEffect(() =>{
        setSelectType(renderData.data.frame_type.id);
    }, [renderData])
    
    return ItemRadio(
        ItemID.FRAME_TYPE, 
        "Frame Type",                 
        "group_frame_type",        
        DataHandler,
        renderData.config_values.frame_type,
        constrainValues.frame_type.parameter,
        selectType
    );
}

//Frame Color
export const ItemFrameColor = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{
    
    const [selectColor, setSelectColor] = useState<number>(-1);

    useEffect(() =>{
        setSelectColor(renderData.data.frame_color.id);
    }, [renderData])

    return ItemRadio(
        ItemID.FRAME_COLOR, 
        "Frame Color",                          
        "group_frame_color",      
        DataHandler,
        renderData.config_values.frame_color,
        constrainValues.frame_color.parameter,
        selectColor
    );
}

//XOP Properties
export const ItemXOPProperties = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{
    return (
        <Accordion.Item eventKey={ItemID.XOPPROPERTIES}>
            <Accordion.Header>Multiple Windows</Accordion.Header>
            <Accordion.Body>
                <XOPProperties                    
                    id_item={ItemID.XOPPROPERTIES}
                    DataHandler={DataHandler}
                    renderData={renderData}
                    constrainValues={constrainValues}                    
                />
            </Accordion.Body>
        </Accordion.Item>
    );
}

//Product Type
export const ItemProductType = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{               
    const [selected, setSelected] = useState<number>(-1);

    //Keep track of which product type was selected
    useEffect(() =>{        
        if(renderData.active_fragment_id >=0){
            const window = renderData.window[renderData.active_fragment_id];            
            setSelected(window.data.product_type.id);
        }
        else{
            setSelected(-1);
        }        
    }, [renderData])  
    
    return ItemRadio(
        ItemID.PRODUCT_TYPE, 
        "Product Type", 
        "group_product_type",
        DataHandler,
        renderData.config_values.product_type,
        constrainValues.product_type.parameter,
        selected
    );        
    
}

//Window Properties
export const ItemWindowProperties = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{
    return (
        <Accordion.Item eventKey={ItemID.WINDOW_PROPERTIES}>
            <Accordion.Header>Window Properties</Accordion.Header>
            <Accordion.Body>
                <WindowProperties
                    id_item={ItemID.WINDOW_PROPERTIES}
                    DataHandler={DataHandler}
                    renderData={renderData}
                    constrainValues={constrainValues}                    
                />
            </Accordion.Body>
        </Accordion.Item>
    );
}

//Glass
export const ItemGlass = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{
    
    return (
        <Accordion.Item 
            eventKey={ItemID.GLASS}            
        >
            <Accordion.Header>Glass</Accordion.Header>
            <Accordion.Body>
                <GlassForm 
                    id_item={ItemID.GLASS}
                    DataHandler={DataHandler}
                    renderData={renderData}
                    constrainValues={constrainValues}                    
                />
            </Accordion.Body>
        </Accordion.Item>
    );
}

//Grids
export const ItemGrids = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{
    return (
        <Accordion.Item eventKey={ItemID.WINDOW_GRID}>
            <Accordion.Header>Grids</Accordion.Header>
            <Accordion.Body>
                <WindowGridForm
                    id_item={ItemID.WINDOW_GRID}                                        
                    DataHandler={DataHandler}
                    renderData={renderData!}
                    constrainValues={constrainValues}
                 />
            </Accordion.Body>
        </Accordion.Item>
    );
}

//Screen
export const ItemScreen = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{
    

    return (
        <Accordion.Item eventKey={ItemID.SCREEN}>
        <Accordion.Header>Screen Options</Accordion.Header>
            <Accordion.Body>
                <ScreenForm
                    id_item={ItemID.SCREEN}                                        
                    DataHandler={DataHandler}
                    renderData={renderData!}
                    constrainValues={constrainValues}
                />
            </Accordion.Body>
        </Accordion.Item>
    );
}

//Option
export const ItemOption = ({DataHandler, renderData, constrainValues}: DataHandlerProp) =>{  
    return (
        <Accordion.Item eventKey={ItemID.OPTION}>
        <Accordion.Header>Options</Accordion.Header>
            <Accordion.Body>
                <OptionForm
                    id_item={ItemID.OPTION}                                        
                    DataHandler={DataHandler}
                    renderData={renderData!}
                    constrainValues={constrainValues}
                />
            </Accordion.Body>
        </Accordion.Item>
    );
}

//Create an Accordion Item with a Radio control
const ItemRadio = (
    id_item: string,
    item_header: string,
    group_name: string,
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, id_key: number) => void,
    parameter: Map<string, RecordData>,
    constrain: Map<number, ConstrainParameter>,
    id_select?: number
) =>{
    return(
        <Accordion.Item eventKey={id_item}>
        <Accordion.Header>{item_header}</Accordion.Header>
        <Accordion.Body>
            <ControlRadio 
                id_item={id_item}
                group_name={group_name}
                DataHandler={DataHandler}
                parameter={parameter}
                constrain={constrain}
                id_select={id_select}
            />
            </Accordion.Body>
        </Accordion.Item>
    );
}

