//The direction a shape faces
export enum ShapeDirection{
    NONE =0,
    RIGHT =1,
    LEFT =2
}

//Open directions
export enum OpenDirection{
    NONE =0,
    UP =1,
    DOWN =2,
    LEFT =3,
    RIGHT =4
}
export const OpenDirectionName: string[] = [
    "None",
    "Up",
    "Down",
    "Left",
    "Right"
]

//Line segments
export enum Segment {    
    TOP =0,
    BOTTOM =1,
    LEFT =2,
    RIGHT =3
}

export enum WindowColor{
    DEFAULT ="rgba(66, 135, 245, 0.1)"
}
export enum FrameColor{
    DEFAULT = "rgba(256, 256, 256, 1)",
    ALMOND = "rgba(214, 201, 178, 1)",
    BRONZE = "rgba(125, 105, 67, 1)",
    BLACK_CAP = "rgba(100, 100, 100, 1)",
    WHITE = "rgba(240, 237, 230, 1)"
}

export enum PanelType{
    FULL =0,
    LEFT_OF_TWO =0,
    RIGHT_OF_TWO =1,    
    LEFT_OF_THREE =0,
    CENTER_OF_THREE =1,
    RIGHT_OF_THREE =2,
    TOP_OF_TWO =0,
    BOTTOM_OF_TWO =1,
    LEFT_OF_FOUR =0,
    LEFT_CENTER_OF_FOUR =1,
    RIGHT_CENTER_OF_FOUR =2,
    RIGHT_OF_FOUR =3
}
