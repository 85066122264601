import {ConstrainValues} from "./constrain_value";
import {setConstraint} from "./functions_constrain";

//Constrain values for colors which don't require anything special
export const constrainGenericColor = (constrainValues: ConstrainValues) =>{
    //Grid Type
    let constrain_type = constrainValues.grid_type;
    setConstraint(constrain_type, "Two Tone", true, false);
}

//Constrain values based on frame color black capstock
export const constrainBlackCap = (constrainValues: ConstrainValues) =>{
    //Grid Type
    let constrain_type = constrainValues.grid_type;
    setConstraint(constrain_type, "Contour DVL", true, false);
    setConstraint(constrain_type, "Flat DVL", true, false);
}