import {RenderData} from "../render_data/render_data";
import {Segment, ShapeDirection} from "../render_data/enum_render";
import {WindowFragment} from "../window/window_fragment";
import {PanelFragment} from "../panel/panel_fragment";

export const maskDimensionOctagon = (renderData: RenderData, window: WindowFragment) =>{
    dimensionCenter(renderData, window);
}

//Draw the dimensions for a hexagon window
export const maskDimensionHexagon = (renderData: RenderData, window: WindowFragment) =>{
    dimensionCenter(renderData, window);
}

//Draw the dimensions for an oval window
export const maskDimensionOval = (renderData: RenderData, window: WindowFragment) =>{
    dimensionCenter(renderData, window);
}

//Draw the dimensions for a circle
export const maskDimensionCircle = (renderData: RenderData, window: WindowFragment) =>{
    dimensionCenter(renderData, window);
}

//Draw the dimensions for a straight right triangle / rake
export const maskDimensionStraightRightTriangle = (renderData: RenderData, window: WindowFragment) =>{
    dimensionBaseShapeDirection(renderData, window);
}

//Draw the dimensions for a peak
export const maskDimensionPeak = (renderData: RenderData, window: WindowFragment) =>{
    dimensionBaseShape(renderData, window);
}

//Draw the dimensions for a right triangle
export const maskDimensionRightTriangle = (renderData: RenderData, window: WindowFragment) =>{    
    dimensionShapeDirection(renderData, window);
}

//Draw the dimensions of the triangle
export const maskDimensionTriangle = (renderData: RenderData, window: WindowFragment) =>{
    dimensionShape(renderData, window);
}

//Draw the arch dimensions
export const maskDimensionArch = (renderData: RenderData, window: WindowFragment) =>{
    dimensionShape(renderData, window);
}

//Draw the dimensions for straight arches
export const maskDimensionStraightArch = (renderData: RenderData, window: WindowFragment) =>{
    dimensionBaseShape(renderData, window);
}

//Draw the dimensions for the half arch
export const maskDimensionHalfArch = (renderData: RenderData, window: WindowFragment) =>{
    dimensionShapeDirection(renderData, window);
}

//Draw the dimensions for the straight half arch
export const maskDimensionStraightHalfArch = (renderData: RenderData, window: WindowFragment) =>{
    dimensionBaseShapeDirection(renderData, window);
}

//Draw the dimensions of the fragment in text
export const maskDimensionStandard = (renderData: RenderData, window: WindowFragment, panel: PanelFragment) =>{
    const context = renderData.viewport.context;
    
    context!.save();
    setDimensionText(renderData, panel);
    dimensionStandardBottom(panel, window, renderData);    
    dimensionStandardRight(panel, window, renderData);        
    context!.restore();
}

//Draw the dimensions in the center; for example, for circles etc..
const dimensionCenter = (renderData: RenderData, window: WindowFragment) =>{
    const context = renderData.viewport.context;
    const panel = window.panel[0];

    const inner_top = panel.model.line[Segment.TOP].y1;
    const inner_bottom = panel.model.line[Segment.BOTTOM].y1;
    const inner_left = panel.model.line[Segment.LEFT].x1;
    const inner_right = panel.model.line[Segment.RIGHT].x1;

    const center_x = (inner_left + inner_right) /2;
    const center_y = (inner_top + inner_bottom) /2;    
    
    //Draw the dashed lines
    context!.save();
    context!.lineWidth =1;    
    context!.setLineDash([5, 10])

    //Draw vertical line
    context!.moveTo(center_x, inner_top);
    context!.lineTo(center_x, inner_bottom);
    context!.stroke();
    //Draw horizontal line
    context!.moveTo(inner_left, center_y);
    context!.lineTo(inner_right, center_y);
    context!.stroke();
    context!.restore();

    const offset = panel.mask_dimension.adjust; //Move the text off the line

    //Draw the text
    context!.save();
    setDimensionText(renderData, panel);
    //Horizontal text
    dimension(
        renderData,
        inner_right - (center_x /2),
        center_y - offset,
        ANGLE_STANDARD,
        String(window.model_inch.width)
    );
    //Vertical text
    dimension(
        renderData,
        center_x - offset,
        inner_top + (center_y /2),
        ANGLE_RIGHT,
        String(window.model_inch.height)
    );
    context!.restore();
}

//Draw a dimension for a basic bottom and right
const dimensionBasic = (renderData: RenderData, window: WindowFragment) =>{
    const context = renderData.viewport.context;
    const panel = window.panel[0];

    context!.save();
    setDimensionText(renderData, panel);
    dimensionBottom(window, renderData);
    dimensionRight(window, renderData, getY(window));
    context!.restore();
}

//Draw the dimensions for a window that has a base and a shape direction top
const dimensionBaseShapeDirection = (renderData: RenderData, window: WindowFragment) =>{
    const context = renderData.viewport.context;

    const y = getY(window);
    const second_y = getSecondY(window);

    context!.save();
    setDimensionText(renderData, window.panel[0]);
    if(window.model.shape_direction === ShapeDirection.LEFT){
        dimensionRight(window, renderData, y);
        dimensionLeft(window, renderData, second_y, displaySecondHeight(window));
    }
    else{
        dimensionRight(window, renderData, second_y, displaySecondHeight(window));
        dimensionLeft(window, renderData, y);
    }
    dimensionBottom(window, renderData);

    context!.restore();
}

//Draw the dimensions for a window that has a shape
const dimensionShape = (renderData: RenderData, window: WindowFragment) =>{
    const context = renderData.viewport.context;
    const panel = window.panel[0]; 

    context!.save();
    setDimensionText(renderData, panel);
    dimensionBottom(window, renderData);   
    dimensionRight(window, renderData, getCornerY(window));    
    context!.restore();
}

//Draw the dimensions for a window that has a shape direction
const dimensionShapeDirection = (renderData: RenderData, window: WindowFragment) =>{
    const context = renderData.viewport.context;
    
    const y = getY(window);

    context!.save();
    setDimensionText(renderData, window.panel[0]);
    if(window.model.shape_direction === ShapeDirection.LEFT){
        dimensionRight(window, renderData, y);
    }
    else{
        dimensionLeft(window, renderData, y);
    }
    dimensionBottom(window, renderData);            

    context!.restore();
}

//Draw the dimensions for an object that has a base and a top
const dimensionBaseShape = (renderData: RenderData, window: WindowFragment) =>{
    const context = renderData.viewport.context;
    const panel = window.panel[0];

    context!.save();
    setDimensionText(renderData, panel);
    dimensionBottom(window, renderData);
    dimensionRight(window, renderData, getCornerY(window), displayTopHeight(window));
    dimensionRight(window, renderData, getSecondY(window), displaySecondHeight(window));
    context!.restore();
}

//Draw the width of the panel at the bottom of the panel
const dimensionStandardBottom = (panel: PanelFragment, window: WindowFragment, renderData: RenderData) =>{    
    const context = renderData.viewport.context;
    
    let post_offset = 0;    
    
    //If the panel is not bordering the bottom frame
    if(panel.model.line[Segment.BOTTOM].y1 !== window.mask_window.bottom){
        post_offset = window.post_width /2;
    }
    
    //Find the center in pixels of the panel and set the y coordinate
    const x = (panel.model.line[Segment.LEFT].x1 + panel.model.line[Segment.RIGHT].x1) /2;
    const y = panel.model.line[Segment.BOTTOM].y1 - panel.mask_dimension.adjust - post_offset;

    const text = String(panel.model_inch.display_width);
    
    context!.save();
    context!.fillText(text, x, y);
    context!.restore();
}
//Draw the height of the panel at the right of the panel
const dimensionStandardRight = (panel: PanelFragment, window: WindowFragment, renderData: RenderData) =>{    
    let post_offset = 0;

    //If the panel is not bordering the right frame
    if(panel.model.line[Segment.RIGHT].x1 !== window.mask_window.right){
        post_offset = window.post_width /2;
    }

    //Coordinates for the text to display
    const x = panel.model.line[Segment.RIGHT].x1 - panel.mask_dimension.adjust - post_offset;
    const y = (panel.model.line[Segment.TOP].y1 + panel.model.line[Segment.BOTTOM].y1) /2;

    const text = String(panel.model_inch.display_height);    
    
    dimension(renderData, x, y, ANGLE_RIGHT, text);
}

//Draw the bottom dimension for an arch
const dimensionBottom = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;
    context!.save();
    
    const panel = window.panel[0];
    
    const x = (window.model.line[Segment.LEFT].x1 + window.model.line[Segment.RIGHT].x1)/2;
    const y = panel.model.line[Segment.BOTTOM].y1 - panel.mask_dimension.adjust;

    const text = String(window.model_inch.width);

    context!.fillText(text, x, y);

    context!.restore();
}

//Draw the height dimension on the left
const dimensionLeft = (window: WindowFragment, renderData: RenderData, y: number, text?: string) =>{
    if(text === undefined){
        text = String(window.model_inch.height);
    }

    const panel = window.panel[0];   
    const x = panel.model.line[Segment.LEFT].x1 + panel.mask_dimension.adjust;    

    dimension(renderData, x, y, ANGLE_LEFT, text);
}

//Draw the height dimension on the right
const dimensionRight = (window:WindowFragment, renderData: RenderData, y: number, text?: string) =>{    
    //Set the text if it wasn't defined
    if(text === undefined){
        text = String(window.model_inch.height);
    }

    const panel = window.panel[0];    
    const x = panel.model.line[Segment.RIGHT].x1 - panel.mask_dimension.adjust;    

    dimension(renderData, x, y, ANGLE_RIGHT, text);    
}

//Draw dimension text with any translate, rotatio or text
const dimension = (    
    renderData: RenderData, 
    x: number, 
    y: number, 
    angle: number, 
    text: string
) =>{
    const context =  renderData.viewport.context;

    context!.save();
    context!.translate(x, y);
    context!.rotate(angle);
    context!.fillText(text, 0, 0);    
    context!.restore();
}

//Set the text values for dimensions
const setDimensionText = (renderData: RenderData, panel: PanelFragment) =>{
    const context = renderData.viewport.context;

    context!.textAlign = "center";
    context!.textBaseline = "middle";
    context!.font = panel.mask_dimension.font;
    context!.fillStyle = panel.mask_dimension.fill_style;
}

//Get the display value for second height
const displaySecondHeight = (window: WindowFragment): string =>{
    return String(window.model_inch.height - window.model_inch.second_height);
}
const displayTopHeight = (window: WindowFragment): string =>{
    return String(window.model_inch.second_height);
}
//Get the Y position for side text
const getY = (window: WindowFragment): number =>{
    return (window.model.line[Segment.TOP].y1 + window.model.line[Segment.BOTTOM].y1) /2
}
//Get the Y position for second height text
const getSecondY = (window: WindowFragment) =>{
    return getY(window) + (window.model.second_height /2);
}
//Get the Y position for text in the corner
const getCornerY = (window: WindowFragment) =>{
    const offset = 20; //This is really just a guess, not sure what a good number would be
    return window.model.line[Segment.TOP].y1 + offset;
}

const ANGLE_STANDARD = 0;
const ANGLE_RIGHT = -Math.PI / 2;
const ANGLE_LEFT = Math.PI /2;


