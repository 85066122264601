import {RenderData} from "../render_data/render_data"

const ArrowDown =[    
    0, 56,
    23, 2,
    11, 2,
    11, -56,
    -11, -56,
    -11, 2,
    -23, 2,
    0, 56
]

const ArrowRight =[
    56, 0, 
    2, 23,
    2, 11, 
    -56, 11, 
    -56, -11, 
    2, -11, 
    2, -23, 
    56, 0, 
]

const Hexagon =[
    0.5, 0,
    0.25, 0.433,
    -0.25, 0.433,
    -0.5, 0,
    -0.25, -0.433,
    0.25, -0.433    
]

// Actual value: 0.20710678118654752440084436210485
const w = 0.2071;

const Octagon =[
    w, 0.5,
    -w, 0.5,
    -0.5, w,
    -0.5, -w,
    -w, -0.5,
    w, -0.5,
    0.5, -w,
    0.5, w
]

//A buffer containing models
export const Buffer =[
    ArrowDown,
    ArrowRight,
    Hexagon,
    Octagon
]

//Enum correlating to models in the buffer
export enum Model{
    ARROW_DOWN =0,
    ARROW_RIGHT =1,
    HEXAGON =2,
    OCTAGON =3
}

//Render a set of vertex data
export const renderModel = (
    renderData: RenderData, 
    x_coord: number, 
    y_coord: number, 
    model: number[],
    scale: number
) =>{
    const context = renderData.viewport.context;        

    context!.beginPath();
    context!.moveTo((model[0] * scale) + x_coord, (model[1] * scale) + y_coord)

    //Step through the vertex data 2 at a time
    for(let counter=2; counter <= model.length; counter +=2){
        const x = (model[counter] * scale) + x_coord;
        const y = (model[counter+1] * scale) + y_coord;

        context!.lineTo(x, y);
    }

    context!.stroke();   
}

//Pack a vertex buffer with vertices
/*
export const packVertexBuffer = () =>{

}
*/