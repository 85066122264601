import {Line} from "../window/window_fragment";
import {Triangle, initTriangle} from "../model/model_data";
import {VertexPoint, initVertexPoint} from "../model/vertex_point";
import {VertexBuffer, initVertexBuffer} from "../model/vertex_buffer";

//Mask for drawing grids
export interface MaskGrid{
    grid_x: number,
    grid_y: number,
    line_width: number,
    line_style: string
}
//Mask for drawing basic windows
export interface MaskWindow{
    top: number,
    bottom: number,
    left: number,
    right: number,
    line_width: number,
    line_style: string,
    gap: number,
    color_window: string
}
//Drawing the arrow that depicts how the window opens
export interface MaskOpen{
    direction: number,
    line_width: number,
    line_style: string
}
//Values for dimensions mask
export interface MaskDimension{
    font: string,
    fill_style: string
    adjust: number //Adjust the string output loction to move it a few pixels away from the border. Generally, make it half the font pixel size.
}
export interface MaskScreen{
    screen_color: string
}
export interface MaskArch{
    center_x: number //The center x of the arch
    center_y: number //The center y of the arch
    radius: number //The radius of the arch
    radian_start: number //The starting angle of the arch
    radian_end: number //The ending angle of the arch
    offset: number //An offset value for visual purposes
    inner_radius: number //Radius of the inne arch
    inner_start: number //Start of the inner arch
    inner_end: number //End of the inner arch
    inner_base: Line //The line accross the base of the inner window
    inner_triangle: Triangle //The inner triangle that needs to be filled on half arches
    outer_triangle: Triangle //The outer triangle that needs to be filled on half arches
}
export interface MaskLower{
    offset_left: number,
    offset_right: number
}
export interface MaskTriangle{
    outer_triangle: Triangle,
    inner_triangle: Triangle
}
export interface MaskCircle{
    center: VertexPoint,
    outer_radius: VertexPoint,
    inner_radius: VertexPoint   
}
export interface MaskBuffer{
    outer: VertexBuffer,
    inner: VertexBuffer
}

export const initMaskArch = (): MaskArch =>({
    center_x: 0,
    center_y: 0,
    radius: 0,
    radian_start: 0,
    radian_end: 0,
    offset: 2,
    inner_radius: 0,
    inner_start: 0,
    inner_end: 0,
    inner_base:{
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0
    },
    inner_triangle: initTriangle(),
    outer_triangle: initTriangle()
})

export const initMaskLower = (): MaskLower =>({
    offset_left: 0,
    offset_right: 0
})

export const initMaskTriangle = (): MaskTriangle =>({
    outer_triangle: initTriangle(),
    inner_triangle: initTriangle()
})

export const initMaskCircle = (): MaskCircle =>({
    center: initVertexPoint(),
    outer_radius: initVertexPoint(),
    inner_radius: initVertexPoint()
})

export const initMaskBuffer = (): MaskBuffer =>({
    outer: initVertexBuffer(),
    inner: initVertexBuffer()
})