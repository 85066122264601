import {
    MaskWindow, 
    MaskArch, 
    MaskLower,
    MaskTriangle,
    MaskCircle,
    MaskBuffer,    
    initMaskArch,
    initMaskLower,
    initMaskTriangle,
    initMaskCircle,
    initMaskBuffer
} from "../mask/mask_data";
import {WindowColor, ShapeDirection} from "../render_data/enum_render";
import {PanelFragment} from "../panel/panel_fragment";
import {DataPoint, initDataPoint} from "../render_data/render_data";

//Data for the window fragment
interface WindowData{
    product_type: DataPoint
    panel_size_changed: boolean
}

//Model of a fragment in pixels
export interface Model{
    line:Line [],
    second_height: number,
    shape_direction: ShapeDirection
}
//Model of a fragment in inches
export interface ModelInch{
    top: number,
    bottom: number,
    left: number,
    right: number,
    width: number,
    height: number,
    second_height: number,    
}

//Lines
export interface Line{
    x1: number
    y1: number
    x2: number
    y2: number
}

export const initLine = (): Line =>({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
});

//Initialize the window model
export const initModel = (): Model =>({
    line: [
        initLine(), // TOP
        initLine(), // BOTTOM
        initLine(), // LEFT
        initLine()  // RIGHT
    ],
    second_height: 0,
    shape_direction: ShapeDirection.NONE
});

//Window fragment
export interface WindowFragment{
    line_width: number
    line_style: string
    model: Model //Vertices of window in pixels
    model_inch: ModelInch //Dimension of window in inches
    id: number //The array id of the window fragment  
    mask_window: MaskWindow //Mask for the window    
    post: Line[] //The post separating panels
    post_width: number //How wide the posts are    
    active_panel_id: number //The panel which is active
    panel: PanelFragment[] //Panel fragments    
    data: WindowData //Data for the window fragment
    mask_arch: MaskArch //Mask for an arch
    mask_lower: MaskLower //Mask for lower portion of a window; like in an arch
    mask_triangle: MaskTriangle //Mask for a triangle
    mask_circle: MaskCircle //Mask for circle and oval
    mask_buffer: MaskBuffer //A vertex buffer for oddly shaped windows that have to be defined with vertices 
}

//Initialize the window fragment
export const initWindowFragment = ():WindowFragment =>({
    line_width: 2,
    line_style: "black", 
    model: initModel(),
    model_inch: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: 0,
        height: 0,
        second_height: 0        
    },
    id: -1,
    mask_window: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        line_width: 2,
        line_style: "black",
        gap: 10,
        color_window: WindowColor.DEFAULT
    },            
    post: [],
    post_width: 6,
    active_panel_id: -1,
    panel: [],
    data:{
        product_type: initDataPoint("PW"),
        panel_size_changed: false
    },
    mask_arch: initMaskArch(),
    mask_lower: initMaskLower(),
    mask_triangle: initMaskTriangle(),
    mask_circle: initMaskCircle(),
    mask_buffer: initMaskBuffer()
});
