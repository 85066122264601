import {ConstrainValues} from "./constrain_value";
import {setConstraint, setControl} from "./functions_constrain";

//Constrain values not associated to windows
export const constrainWindow = (constrainValues: ConstrainValues) =>{
    
    //Product Line
    let constrain_type = constrainValues.product_line;    
    setConstraint(constrain_type, "Aluminum Door", true, true);
    setConstraint(constrain_type, "Vinyl Door", true, true);
    setConstraint(constrain_type, "TMF XOP", true, true);

    //Product Type
    constrain_type = constrainValues.product_type;
    setConstraint(constrain_type, "XOO", true, true);
    setConstraint(constrain_type, "OXO", true, true);
    setConstraint(constrain_type, "OOX", true, true);    
    setConstraint(constrain_type, "OXXO", true, true);

    //Window Options
    constrain_type = constrainValues.option;
    setConstraint(constrain_type, "Fin Removal", true, true);
    setConstraint(constrain_type, "Special Sqrft", true, true);
    setConstraint(constrain_type, "Key Lock", true, true);
    setConstraint(constrain_type, "Jamb Extender", true, true);
}

//Constrain patio door values
export const constrainPatioDoor = (constrainValues: ConstrainValues) =>{    

    //Product Type
    let constrain_type = constrainValues.product_line;
    setConstraint(constrain_type, "MF-2", true, true);
    setConstraint(constrain_type, "SMF Vinyl", true, true);
    setConstraint(constrain_type, "TMF Vinyl", true, true);
    setConstraint(constrain_type, "TMF XOP", true, true);
    
    //Product Type
    constrain_type = constrainValues.product_type;
    constrainShapeWindows(constrainValues);
    setConstraint(constrain_type, "Single Hung", true, true);
    setConstraint(constrain_type, "PW", true, true);

    //Glass
    constrain_type = constrainValues.treatment;
    setConstraint(constrain_type, "Annealed", true, false);

    //Option
    constrain_type = constrainValues.option;
    setConstraint(constrain_type, "Vent Limiter", true, true);
    setConstraint(constrain_type, "Mull", true, true);
    setConstraint(constrain_type, "Auto Lock", true, true);
    setConstraint(constrain_type, "Special Sqrft", true, false);
}

//Constrain window shapes
const constrainShapeWindows = (constrainValues: ConstrainValues) =>{
    const constrain_type = constrainValues.product_type;
    
    constrainCircular(constrainValues);
    setConstraint(constrain_type, "Factory Arch", true, true);    
    setConstraint(constrain_type, "L 1/4 Circle", true, true);
    setConstraint(constrain_type, "L Rake", true, true);
    setConstraint(constrain_type, "L Straight Leg Arch", true, true);
    setConstraint(constrain_type, "L Triangle", true, true);
    setConstraint(constrain_type, "Peak", true, true);
    setConstraint(constrain_type, "R 1/4 Circle", true, true);
    setConstraint(constrain_type, "R Rake", true, true);
    setConstraint(constrain_type, "R Straight Leg Arch", true, true);
    setConstraint(constrain_type, "R Triangle", true, true);    
    setConstraint(constrain_type, "Straight Leg Arch", true, true);
    setConstraint(constrain_type, "Triangle", true, true);
}

//Constrain circular windows
const constrainCircular = (constrainValues: ConstrainValues) =>{
    const constrain_type = constrainValues.product_type;

    setConstraint(constrain_type, "Circle", true, true);
    setConstraint(constrain_type, "Hexagon", true, true);
    setConstraint(constrain_type, "Octagon", true, true);
    setConstraint(constrain_type, "Oval", true, true);
}

//Constrain XOP values
export const constrainXOP = (constrainValues: ConstrainValues) =>{
    //Controls
    constrainXOPProperties(constrainValues);

    //Product Line
    const constrain_type = constrainValues.product_line;
    setConstraint(constrain_type, "MF-2", true, false);
        
    //Use the same constraints for windows
    constrainWindow(constrainValues);

    //Product Type
    constrainShapeWindows(constrainValues);
}

//Constrain Stack values
export const constrainStack = (constrainValues: ConstrainValues) =>{
    constrainXOPProperties(constrainValues);
    constrainWindow(constrainValues);
    constrainCircular(constrainValues);
}

const constrainXOPProperties = (constrainValues: ConstrainValues) =>{    
    const control = constrainValues.control;
    
    //Property tab    
    setControl(control.xop, false, false);
    
    //Ranges    
    setControl(control.xop_width, false, false);
    setControl(control.xop_height, false, false);
}

