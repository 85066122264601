import {VertexBuffer} from "../model/vertex_buffer";
import {RenderData} from "../render_data/render_data";

export const drawBuffer = (buffer: VertexBuffer, renderData: RenderData, fillStyle?: string) =>{
    const context = renderData.viewport.context;

    context!.beginPath();
    const first_vertex = buffer.vertex[0];
    context!.moveTo(first_vertex.x, first_vertex.y);
    
    //Iterate through all the vertices in the buffer starting at the second vertex and draw a line
    for(let counter=1; counter < buffer.vertex.length; counter++){
        context!.lineTo(buffer.vertex[counter].x, buffer.vertex[counter].y);
    }
    context!.closePath();
    if(fillStyle){
        context!.fillStyle = fillStyle;                
        context!.fill();
    }
    else{
        context!.stroke();
    }
    
    
}