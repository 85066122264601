import {Fragment, useState, useEffect, useCallback} from "react"
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import {RenderData} from "../render/render_components/render_data/render_data";
import {BUTTON_TYPE} from "../../../settings";
import {ConstrainValues} from "../constraints/constrain_value";
import {ENABLE_ADVANCED} from "../../../settings";

interface Props{
    id_item: string;
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, key_id: number) => void;
    renderData: RenderData;
    constrainValues: ConstrainValues;    
}

const XOPProperties = ({
    id_item, 
    DataHandler, 
    renderData,
    constrainValues,    
}: Props) =>{

    const INPUT_MAX_WIDTH = "100px";
    const COL_SIZE_LABEL = "col-1";
    const COL_SIZE_TEXT = "col-2";
    const ROW_MARGIN = "row mb-1";

    //Input values for editing fragment dimensions
    const [inputTop, setInputTop] = useState<string>("");
    const [inputBottom, setInputBottom] = useState<string>("");
    const [inputLeft, setInputLeft] = useState<string>("");
    const [inputRight, setInputRight] = useState<string>("");
    const [inputWidth, setInputWidth] = useState<string>("");
    const [inputHeight, setInputHeight] = useState<string>("");    
    
    const handleButtonClick = (object_id: string) =>{        
        if(renderData.active_fragment_id <0 && object_id !== "clear_viewport"){
            return;
        }
        DataHandler(id_item, object_id, "", "", -1);        
    }

    //Handle a text control losing focus
    const handleBlur = (id: string, value: string) =>{
        processTextValue(id, value);
    }
    //Handle a key down event on a text control
    const handleKeyDown = (id: string, value: string, event: React.KeyboardEvent<HTMLElement>) =>{        
        if(event.key === 'Enter'){
            processTextValue(id, value);
        }
    }

    //Process a text value if it is valid, otherwise revert it
    const processTextValue = (id: string, value: string) =>{
        if(renderData.active_fragment_id >=0){
            if(Number(value) >0){
                DataHandler(id_item, id, "", value, -1);
            }
            else{            
                populateTextFromActiveWindow();   
            }
        }
    }

    //Handle the changing of a text field
    const handleTextChange = (id: string, value: string) =>{        
        
        if(renderData.active_fragment_id <0){
            return;
        }
        
        //If the value is a number
        const value_number = Number(value);
        if(!isNaN(value_number)){             
            let valid_value = true;
            const window = renderData.window[renderData.active_fragment_id];

            //Can't adjust top bordering viewport
            if(id === "input_top"){
                if(window.model_inch.top === 0){
                    valid_value = false;    
                }
            }            
            if(id === "input_bottom"){
                //Can't adjust bottom bordering viewport
                if(window.model_inch.bottom === renderData.viewport.inch_height){
                    valid_value = false;
                }
                //Value can't be equal or greater than viewport height
                if(value_number >= renderData.viewport.inch_height){
                    valid_value = false;
                }
            }
            //Can't adjust left bordering viewport
            if(id === "input_left"){
                if(window.model_inch.left === 0){
                    valid_value = false;
                }
            }
            //Can't adjust right bordering viewport
            if(id === "input_right"){
                if(window.model_inch.right === renderData.viewport.inch_width){
                    valid_value = false;
                }
                //Value can't be equal or greater than viewport width
                if(value_number >= renderData.viewport.inch_width){
                    valid_value = false;
                }
            }
            //Can't adjust width if it borders both the left and right of the viewport
            if(id === "input_width"){
                if(window.model_inch.width === renderData.viewport.inch_width){
                    valid_value = false;
                }
            }
            //Can't adjust height if it borders both the top and bottom
            if(id === "input_height"){
                if(window.model_inch.height === renderData.viewport.inch_height){
                    valid_value = false;
                }
            }

            //Update the input text with the value that was entered; allow for blanks
            if(valid_value || value === ""){
                switch(id){
                    case "input_top":
                        setInputTop(value);
                    break;
                    case "input_bottom":
                        setInputBottom(value);
                    break;
                    case "input_left":
                        setInputLeft(value);
                    break;
                    case "input_right":
                        setInputRight(value);
                    break;
                    case "input_width":
                        setInputWidth(value);
                    break;
                    case "input_height":
                        setInputHeight(value);
                    break;
                }
            }            
        }
    }

    //Populate the text box controls from the active window
    const populateTextFromActiveWindow = useCallback(() => {
        const window = renderData.window[renderData.active_fragment_id];
    
        //Flip the y coordinates to get out of canvas space
        const invert_top = renderData.viewport.inch_height - window.model_inch.top;
        const invert_bottom = renderData.viewport.inch_height - window.model_inch.bottom;
    
        //Set the text values based on the fragment dimensions
        setInputTop(String(invert_top));
        setInputBottom(String(invert_bottom));
        setInputLeft(String(window.model_inch.left));
        setInputRight(String(window.model_inch.right));
        setInputWidth(String(window.model_inch.width));
        setInputHeight(String(window.model_inch.height));
    }, [renderData]);

    //Check if the fragment id has changed and is different than the existing active fragment (or none)
    useEffect(() =>{

        if(renderData.active_fragment_id >=0){ //If a fragment is selected
            populateTextFromActiveWindow();
        }
        else{
            setInputTop("");
            setInputBottom("");
            setInputLeft("");
            setInputRight("");
            setInputWidth("");
            setInputHeight("");
        }
    }, [renderData, populateTextFromActiveWindow])    
    
    //Handle the changing of the advanced check box
    const handleCheckAdvanced = (name: string, isChecked: boolean) =>{
        DataHandler(id_item, name, "", String(isChecked), -1);        
    }

    return (
        <Fragment>
            <Button
                    variant={BUTTON_TYPE} 
                    id="split_horizontal"
                    onClick={(event) => handleButtonClick(event.currentTarget.id)}
                    disabled={constrainValues.control.xop_split_horizontal.is_disabled}                    
                >
                    Split Horizontal
            </Button>
            <Button
                    variant={BUTTON_TYPE} 
                    id="split_vertical"
                    onClick={(event) => handleButtonClick(event.currentTarget.id)}                    
                    disabled={constrainValues.control.xop_split_vertical.is_disabled}
                    style={{marginLeft: '0.5rem'}}
                >
                    Split Vertical
            </Button>
            <Button
                    variant={BUTTON_TYPE} 
                    id="clear_viewport"
                    onClick={(event) => handleButtonClick(event.currentTarget.id)}                    
                    style={{marginLeft: '0.5rem'}}
                >
                    Clear
            </Button>
            
            <Form className="mt-3">                
                <div className="container">
                    <Form.Label>Window Dimensions</Form.Label>
                    {!constrainValues.control.xop_width.is_hidden && (
                        <div className={ROW_MARGIN}>
                            <div className={COL_SIZE_LABEL + " d-flex align-items-center"}>
                                <Form.Label>Width</Form.Label>                      
                            </div>
                            <div className={COL_SIZE_TEXT}>
                                <Form.Control 
                                    id="input_width"
                                    type="text"
                                    value={inputWidth}                                
                                    style={{width: INPUT_MAX_WIDTH}}
                                    onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                                    disabled={constrainValues.control.xop_width.is_disabled}
                                    onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)}
                                />
                            </div>                        
                        </div>
                    )}

                    {!constrainValues.control.xop_height.is_hidden && (
                        <div className={ROW_MARGIN}>
                            <div className={COL_SIZE_LABEL + " d-flex align-items-center"}>
                                <Form.Label>Height</Form.Label>                      
                            </div>
                            <div className={COL_SIZE_TEXT}>
                                <Form.Control 
                                    id="input_height"
                                    type="text"
                                    value={inputHeight}                                
                                    style={{width: INPUT_MAX_WIDTH}}
                                    onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                                    disabled={constrainValues.control.xop_height.is_disabled}
                                    onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)}
                                />
                            </div>                        
                        </div>
                    )}                    
                    
                    {ENABLE_ADVANCED &&(                        
                        <Form.Check
                            label="Advanced"
                            name="group_advanced"
                            type="checkbox"
                            id="advanced"
                            onChange={(event) => handleCheckAdvanced("Advanced" , event.target.checked)}
                            className="mt-4 mb-2"
                        />                    
                    )}                    
                    
                    {!constrainValues.control.xop_advanced.is_hidden && (
                        <Form.Label>Window Edges</Form.Label>          
                    )}              
                    
                    {!constrainValues.control.xop_top.is_hidden &&(                        
                        <div className={ROW_MARGIN}>
                            <div className={COL_SIZE_LABEL + " d-flex align-items-center"}>
                                <Form.Label>Top</Form.Label>                      
                            </div>
                            <div className={COL_SIZE_TEXT}>
                                <Form.Control 
                                    id="input_top"
                                    type="text"
                                    value={inputTop}
                                    style={{width: INPUT_MAX_WIDTH}}
                                    onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                                    disabled={constrainValues.control.xop_top.is_disabled}
                                    onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)}                                
                                />
                            </div>
                        </div>
                    )}

                    {!constrainValues.control.xop_bottom.is_hidden &&(
                        <div className={ROW_MARGIN}>
                            <div className={COL_SIZE_LABEL + " d-flex align-items-center"}>
                                <Form.Label>Bottom</Form.Label>                      
                            </div>
                            <div className={COL_SIZE_TEXT}>
                                <Form.Control 
                                    id="input_bottom"
                                    type="text"
                                    value={inputBottom}                                
                                    style={{width: INPUT_MAX_WIDTH}}
                                    onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                                    disabled={constrainValues.control.xop_bottom.is_disabled}
                                    onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)}                                
                                />
                            </div>
                        </div>
                    )}

                    {!constrainValues.control.xop_left.is_hidden && (
                        <div className={ROW_MARGIN}>
                            <div className={COL_SIZE_LABEL + " d-flex align-items-center"}>
                                <Form.Label>Left</Form.Label>                      
                            </div>
                            <div className={COL_SIZE_TEXT}>
                                <Form.Control 
                                    id="input_left"
                                    type="text"
                                    value={inputLeft}                                
                                    style={{width: INPUT_MAX_WIDTH}}
                                    onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                                    disabled={constrainValues.control.xop_left.is_disabled}
                                    onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)}                                
                                />
                            </div>                        
                        </div>
                    )}

                    {!constrainValues.control.xop_right.is_hidden && (
                        <div className={ROW_MARGIN}>
                            <div className={COL_SIZE_LABEL + " d-flex align-items-center"}>
                                <Form.Label>Right</Form.Label>                      
                            </div>
                            <div className={COL_SIZE_TEXT}>
                                <Form.Control 
                                    id="input_right"
                                    type="text"
                                    value={inputRight}                                
                                    style={{width: INPUT_MAX_WIDTH}}
                                    onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                                    disabled={constrainValues.control.xop_right.is_disabled}
                                    onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)}
                                />
                            </div>                        
                        </div>
                    )}

                </div>
            </Form>    
        </Fragment>
    );
}

export default XOPProperties;