import {ConfigValues, RecordData} from "../render/render_components/render_data/config_values";
import {Limit, initLimit} from "./limit";

//Value interface for configurator parameters
export interface ConstrainParameter{
    id: number,
    description: string,
    is_hidden: boolean,
    is_disabled: boolean
}

//Initialize configurator parameter
const initConstrainParameter = (): ConstrainParameter =>({
    id: -1,
    description: "",
    is_hidden: true,
    is_disabled: true
})

export interface ConstrainType{
    parameter: Map<number, ConstrainParameter>; //A map containing all the constrain parameters
    string_id: Map<string, number>; //A map where the description is the key to the value id
}

export const initConstrainType = (): ConstrainType =>({
    parameter: new Map<number, ConstrainParameter>(),
    string_id: new Map<string, number>()
})

//Constraint properties for controls
export interface ConstrainControlProperty{
    is_disabled: boolean
    is_hidden: boolean
}

//Initialize constraint control properties
const initConstrainControlProperty = (): ConstrainControlProperty =>({
    is_disabled: true,
    is_hidden: true
})

interface ConstrainControl{
    layout: string
    configurator: ConstrainControlProperty
    xop: ConstrainControlProperty
    xop_advanced: ConstrainControlProperty
    xop_top: ConstrainControlProperty
    xop_bottom: ConstrainControlProperty
    xop_left: ConstrainControlProperty
    xop_right: ConstrainControlProperty
    xop_width: ConstrainControlProperty
    xop_height: ConstrainControlProperty
    xop_split_horizontal: ConstrainControlProperty
    xop_split_vertical: ConstrainControlProperty
    window: ConstrainControlProperty
    window_width: ConstrainControlProperty
    window_height: ConstrainControlProperty
    window_open_direction: ConstrainControlProperty
    window_second_height: ConstrainControlProperty
    grid: ConstrainControlProperty
    grid_dvl: ConstrainControlProperty
    screen: ConstrainControlProperty    
}

const initConstrainControl = (): ConstrainControl =>({
    layout: "",
    configurator: initConstrainControlProperty(),
    xop: initConstrainControlProperty(),
    xop_advanced: initConstrainControlProperty(),
    xop_top: initConstrainControlProperty(),
    xop_bottom: initConstrainControlProperty(),
    xop_left: initConstrainControlProperty(),
    xop_right: initConstrainControlProperty(),
    xop_width: initConstrainControlProperty(),
    xop_height: initConstrainControlProperty(),
    xop_split_horizontal: initConstrainControlProperty(),
    xop_split_vertical: initConstrainControlProperty(),
    window: initConstrainControlProperty(),
    window_width: initConstrainControlProperty(),
    window_height: initConstrainControlProperty(),
    window_open_direction: initConstrainControlProperty(),
    window_second_height: initConstrainControlProperty(),
    grid: initConstrainControlProperty(),
    grid_dvl: initConstrainControlProperty(),
    screen: initConstrainControlProperty()
})

//Interface for all the constrain values
export interface ConstrainValues{
    product_line: ConstrainType;
    frame_type: ConstrainType;
    frame_color: ConstrainType;
    product_type: ConstrainType;
    treatment: ConstrainType;
    glass: ConstrainType;
    grid_type: ConstrainType;
    grid_pattern: ConstrainType;
    screen: ConstrainType;
    option: ConstrainType;
    control: ConstrainControl;
    limit: Limit;
}

//Initialize all the constrain values
export const initConstrainValues = (): ConstrainValues =>({
    product_line: initConstrainType(),
    frame_type: initConstrainType(),
    product_type: initConstrainType(),
    frame_color: initConstrainType(),
    treatment: initConstrainType(),
    glass: initConstrainType(),
    grid_type: initConstrainType(),
    grid_pattern: initConstrainType(),
    screen: initConstrainType(),
    option: initConstrainType(),
    control: initConstrainControl(),
    limit: initLimit()  
})

//Seed the data from the configValues to the constrain values
export const seedConstrainValues = (configValue: ConfigValues, constrainValue: ConstrainValues) =>{
    
    //Seed the parameters of the constrain values from the config values
    seedParameter(configValue.product_line, constrainValue.product_line);
    seedParameter(configValue.frame_type, constrainValue.frame_type);
    seedParameter(configValue.frame_color, constrainValue.frame_color);
    seedParameter(configValue.product_type, constrainValue.product_type);
    seedParameter(configValue.treatment, constrainValue.treatment);
    seedParameter(configValue.glass, constrainValue.glass);
    seedParameter(configValue.grid_type, constrainValue.grid_type);
    seedParameter(configValue.grid_pattern, constrainValue.grid_pattern);
    seedParameter(configValue.screen, constrainValue.screen);
    seedParameter(configValue.option, constrainValue.option);
}

//Seed parameters into a constrain map
const seedParameter = (configMap: Map<string, RecordData>, constrainType: ConstrainType) =>{

    //Iterate through all the config map values and populate the constrain map values
    configMap.forEach(value =>{
        const parameter = initConstrainParameter();
        const string_id = constrainType.string_id;

        //Set the parameter values from config values
        parameter.id = value.id;
        parameter.description = value.description;
        parameter.is_disabled = false;
        parameter.is_hidden = false;

        //Set the name lookup value; maps the description to the parameter
        string_id.set(parameter.description, parameter.id);
        
        //Set the parameter into the constrain map
        constrainType.parameter.set(parameter.id, parameter);
    })
}