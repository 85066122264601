import {RenderData} from "../render_data/render_data";
import {Segment, ShapeDirection} from "../render_data/enum_render";
import {WindowFragment} from "../window/window_fragment";
import {drawLine, drawLineCoord, drawLinePoint} from "./mask_shape"
import {drawInnerLower, clearWindow} from "./mask_shape"
import {VertexPoint} from "../model/vertex_point";
import {drawTriangleFill} from "./mask_triangle";

//Draw the outside border of an arch window
export const maskArchWindow = (renderData: RenderData, window: WindowFragment) =>{
    
    //Draw the arches
    drawArchFull(renderData, window);

    const context = renderData.viewport.context;
    
    context!.strokeStyle = window.mask_window.line_style;
    context!.lineWidth = window.mask_window.line_width;

    //Draw the inner base
    drawLine(window.mask_arch.inner_base, renderData);
    
    //If there is only one window
    if(renderData.window.length === 1){
        drawLine(window.model.line[Segment.BOTTOM], renderData);
    }
}

//Draw an arch counter-clockwise starting from the right hand
export const maskArch = (    
    center_x: number, //The center x of the arch
    center_y: number, //The center y of the arch
    radius: number, //The radius of the arch
    radian_start: number, //The starting angle of the arch in radians
    radian_end: number, //The ending angle of the arch in radians
    renderData: RenderData, //Render data object
    fillStyle?: string //The fill style
) =>{
    const context = renderData.viewport.context;
    
    context!.beginPath();
    context!.arc(
        center_x, 
        center_y, 
        radius, 
        radian_start, 
        radian_end,
        true
        
    );
    if(fillStyle){
        context!.fillStyle = fillStyle;
        context!.fill();    
    }else{
        context!.stroke();
    }        
}

//Draw a straight arch
export const maskStraightArch = (window: WindowFragment, renderData: RenderData) =>{       
    drawInnerLower(renderData, window);
    drawArchFull(renderData, window);
    
    //Convert start angle to degrees
    const start = Math.abs(window.mask_arch.radian_start) * (180/Math.PI);
    
    //There's an anomoly line that appears roughly around 10 degrees
    if(start <=10){
        coverHeightLine(renderData, window);
    }
}

//Draw a straight half arch
export const maskStraightHalfArch = (window: WindowFragment, renderData: RenderData) =>{
    drawInnerLower(renderData, window);
    maskHalfArch(window, renderData);
    
    //All the code below is for covering the height line anomaly that appears.
    //Using the function for this doesn't work very well, so just made specific code for it.
    const context = renderData.viewport.context;
    const panel = window.panel[0];
    const x1 = panel.model.line[Segment.LEFT].x1;
    const x2 = panel.model.line[Segment.RIGHT].x1;

    const left = window.mask_arch.inner_triangle.left;
    const right = window.mask_arch.inner_triangle.right;

    context!.strokeStyle = "rgba(256,256,256,1)";
    context!.lineWidth = 2;    
    
    drawLineCoord(
        x1 +2, 
        left.y,
        x2 -2,
        right.y,
        renderData
    );    
    context!.strokeStyle = window.mask_window.color_window;
    drawLineCoord(
        x1 +2, 
        left.y,
        x2 -2,
        right.y,
        renderData
    );    
}

//Draw a half arch window
export const maskHalfArch = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;

    context!.lineWidth = window.mask_window.line_width;
    context!.strokeStyle = window.mask_window.line_style;

    //Clear entire window
    //Draw frame color with arch    
    //Draw frame color with outer triangle
    //Clear inner arch
    //Clear inner triangle
    //Fill inner arch glass
    //Fill inner triangle glass
    //Draw outer arch
    //Draw inner arch    
    
    clearWindow(renderData, window);
    drawArchFrameColor(renderData, window);
    drawTriangleFill(renderData, window.mask_arch.outer_triangle, renderData.all_window.color_frame);
    clearInnerArch(renderData, window);
    drawTriangleFill(renderData, window.mask_arch.inner_triangle, "rgba(256,256,256,1");
    drawInnerArchWindow(renderData, window);
    drawTriangleFill(renderData, window.mask_arch.inner_triangle, window.mask_window.color_window);    
    coverTriLine(window, renderData);    
    drawOuterArch(renderData, window);
    drawInnerArch(renderData, window);

    //Shorthand outer window dimensions
    const top = window.model.line[Segment.TOP].y1;
    let bottom = window.model.line[Segment.BOTTOM].y1;
    const left = window.model.line[Segment.LEFT].x1;
    const right = window.model.line[Segment.RIGHT].x1;
    //Shorthand inner window dimensions
    const inner_top = window.mask_window.top;    
    const inner_left = window.mask_arch.inner_triangle.left.x;
    const inner_right = window.mask_arch.inner_triangle.right.x;
    const inner_bottom = window.mask_arch.inner_triangle.right.y;
    
    //Set the inner bottom based on the second height
    if(window.model.second_height >0){
        bottom = window.model.second_height;    
    }
    
    //Offset for windows bordering the viewport
    const offset = renderData.viewport.line_width ;
    
    //Draw the inner and outer sides, based on direction
    if(window.model.shape_direction === ShapeDirection.LEFT){
        //Draw inner side
        drawLineCoord(
            inner_right,
            inner_top,
            inner_right,
            inner_bottom,
            renderData
        )
        //Draw Outer side
        if(right === renderData.viewport.width){
            drawLineCoord(
                right - offset,
                top,
                right - offset,
                bottom,
                renderData
            )
        }
    }
    else{
        //Draw inner side
        drawLineCoord(
            inner_left,
            inner_top,
            inner_left,
            inner_bottom,
            renderData
        )
        //Draw outer side
        if(left === 0){
            drawLineCoord(
                left + offset,
                top,
                left + offset,
                bottom,
                renderData
            )            
        }
    }        
    
    //If there isn't a second height, draw the base
    if(window.model.second_height === 0){    
        //Draw the inner lower
        drawLinePoint(
            window.mask_arch.inner_triangle.left,
            window.mask_arch.inner_triangle.right,
            renderData
        )        
        //Draw the outer bottom
        if(bottom === renderData.viewport.height){
            drawLineCoord(
                left,
                bottom - offset,
                right,
                bottom - offset,
                renderData
            )
        }
    }
}

//This is to account for an anomoly with a line appearing diagonally in half arches
const coverTriLine = (window: WindowFragment, renderData: RenderData) =>{
    const context = renderData.viewport.context;

    context!.save();
    
    const upper = window.mask_arch.inner_triangle.upper;    
    let outer: VertexPoint;    

    //Set the outer point depending on the shape direction
    if(window.model.shape_direction === ShapeDirection.LEFT){
        outer = window.mask_arch.inner_triangle.left;        
    }
    else{
        outer = window.mask_arch.inner_triangle.right;        
    }

    //Draw a white line first
    context!.strokeStyle = "rgba(256,256,256,1)";
    context!.lineWidth = 3;
    drawLineCoord(
        upper.x, 
        upper.y, 
        outer.x, 
        outer.y, 
        renderData
    );
    //Draw a window color line
    context!.strokeStyle = window.mask_window.color_window;
    drawLineCoord(
        upper.x, 
        upper.y, 
        outer.x, 
        outer.y, 
        renderData
    );

    context!.restore();
}

//This is to account for an anomoly that occurs between the top and bottom pieces
const coverHeightLine = (renderData: RenderData, window: WindowFragment) =>{
    const context = renderData.viewport.context;
    const panel = window.panel[0];
    
    const x1 = panel.model.line[Segment.LEFT].x1 +2;
    const y1 = window.model.second_height;
    const x2 = panel.model.line[Segment.RIGHT].x1 -2;
    const y2 = window.model.second_height;    
    
    context!.strokeStyle = "rgba(256,256,256,1)";
    context!.lineWidth = 3;
    drawLineCoord(x1, y1, x2, y2, renderData);    
    context!.strokeStyle = window.mask_window.color_window;
    drawLineCoord(x1, y1, x2, y2, renderData);
}

//Draw an arch including inner and outer arch
const drawArchFull = (renderData: RenderData, window: WindowFragment) =>{
    const context = renderData.viewport.context;
    
    context!.lineWidth = window.mask_window.line_width;
    context!.strokeStyle = window.mask_window.line_style;
    
    clearWindow(renderData, window);
    drawArchFrameColor(renderData, window);
    drawOuterArch(renderData, window);
    clearInnerArch(renderData, window);
    drawInnerArchWindow(renderData, window);        
    drawInnerArch(renderData, window);
}



//Draw the frame color in the shape of the arch
const drawArchFrameColor = (renderData: RenderData, window: WindowFragment) =>{
    //Fill the area with the frame color
    maskArch(
        window.mask_arch.center_x,
        window.mask_arch.center_y,
        window.mask_arch.radius - window.mask_arch.offset, //The offset is just for visual purposes, can be removed if necessary
        window.mask_arch.radian_start,
        window.mask_arch.radian_end,
        renderData,
        renderData.all_window.color_frame
    );
}

//Draw the outer arch border
const drawOuterArch = (renderData: RenderData, window: WindowFragment) =>{
    //Draw the outline of the outer border
    maskArch(
        window.mask_arch.center_x,
        window.mask_arch.center_y,
        window.mask_arch.radius - window.mask_arch.offset, //The offset is just for visual purposes, can be removed if necessary
        window.mask_arch.radian_start,
        window.mask_arch.radian_end,
        renderData
    );
}

//Clear the inner arch of the window
const clearInnerArch = (renderData: RenderData, window: WindowFragment) =>{
    //Blot out the inner window area before drawing
    maskArch(
        window.mask_arch.center_x,
        window.mask_arch.center_y,
        window.mask_arch.inner_radius - window.mask_arch.offset, //The offset is just for visual purposes, can be removed if necessary
        window.mask_arch.inner_start,
        window.mask_arch.inner_end,
        renderData,
        "rgba(256,256,256,1)"
    );
}

//Draw the arch inner window color
const drawInnerArchWindow = (renderData: RenderData, window: WindowFragment) =>{
    //Fill the area with the window color
    maskArch(
        window.mask_arch.center_x,
        window.mask_arch.center_y,
        window.mask_arch.inner_radius - window.mask_arch.offset, //The offset is just for visual purposes, can be removed if necessary
        window.mask_arch.inner_start,
        window.mask_arch.inner_end,
        renderData,
        window.mask_window.color_window
    );
}

//Draw the inner arch border
const drawInnerArch = (renderData: RenderData, window: WindowFragment) =>{
    //Draw the outline of the inner border
    maskArch(
        window.mask_arch.center_x,
        window.mask_arch.center_y,
        window.mask_arch.inner_radius - window.mask_arch.offset, //The offset is just for visual purposes, can be removed if necessary
        window.mask_arch.inner_start,
        window.mask_arch.inner_end,        
        renderData
    );
}
