import Form from "react-bootstrap/Form";
import {useState, useEffect} from "react";
import {RenderData} from "../render/render_components/render_data/render_data";
import {ControlRadio} from "../controls/control_radio";
import {ConstrainValues} from "../constraints/constrain_value";

interface ScreenProps{
    id_item: string;    
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, id_key: number) => void;
    renderData: RenderData;
    constrainValues: ConstrainValues;    
}

const ScreenForm = ({id_item, DataHandler, renderData, constrainValues}: ScreenProps) => {
    const [selected, setSelected] = useState(-1);

    //If the screen only is checked or not
    const isScreenOnly = (): boolean =>{
        return renderData.data.is_screen_only;
    }
    //Handle checking the screen only box
    const handleScreenOnly = (is_checked: boolean) =>{
        DataHandler(id_item, "screen_only", "screen_only", String(is_checked), -1);
    }
    
    //Select the screen option, based on which panel or window is selected
    useEffect(() =>{
        if(renderData.active_fragment_id >=0){
            const window = renderData.window[renderData.active_fragment_id];
            const panel = window.panel[window.active_panel_id];

            const screen = renderData.config_values.screen.get("With Screen");
            const no_screen = renderData.config_values.screen.get("No Screen");
            
            if(panel.data.has_screen){
                setSelected(screen!.id);
            }
            else{
                setSelected(no_screen!.id);
            }
        }
        else{
            setSelected(-1);
        }
    }, [renderData]);

    return(
        <Form>
            <div className="mb-3">
                <Form.Check
                    type="checkbox"
                    label="Screen Only"
                    checked={isScreenOnly()}
                    onChange={(event) => handleScreenOnly(event.target.checked)}
                />
            </div>
            <div key="screen">
                <ControlRadio
                    id_item={id_item}                    
                    group_name="group_screen"                
                    DataHandler={DataHandler}
                    parameter={renderData.config_values.screen}
                    id_select={selected}
                    constrain={constrainValues.screen.parameter}                    
                />
            </div>
        </Form>
    );

}

export default ScreenForm;