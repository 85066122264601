import {OrderList} from "../data/order/order_list";
import React, {useEffect, Fragment} from "react";
import {createRoot} from "react-dom/client";
import Form from "react-bootstrap/Form";
import {OrderPrint, initOrderPrint} from "../data/order/order_printing";
import 'bootstrap/dist/css/bootstrap.min.css';
import imgWD from "./../../../../assets/images/logo-wd.png"
import imgSI from "./../../../../assets/images/logo-si.png";

//Scaling for the image
const SCALE_IMAGE = 0.35;

export const printOrder = (orderList: OrderList) =>{
    
    const printWindow = window.open('', '', 'width=800,height=600');
    if(printWindow){
        //Inject Bootstrap CSS into the new window
        const bootstrapLink = printWindow.document.createElement('link');
        bootstrapLink.rel = 'stylesheet';
        bootstrapLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css';
        bootstrapLink.integrity = 'sha384-KyZXEAg3QhqLMpG8r+Knujsl5+5hb7ie0tv8j2gX2gk6DX5zKaTG01Q/wpuXlDcm';
        bootstrapLink.crossOrigin = 'anonymous';
        printWindow.document.head.appendChild(bootstrapLink);
        
        // Inject viewport meta tag
        const viewportMeta = printWindow.document.createElement('meta');
        viewportMeta.name = 'viewport';
        viewportMeta.content = 'width=device-width, initial-scale=1';
        printWindow.document.head.appendChild(viewportMeta);
        
        // Inject custom CSS to enforce flexbox layout
        const styleTag = printWindow.document.createElement('style');
        styleTag.textContent = 
        `
            .container-fluid {
                display: block !important;
            }
            .row {
                display: flex;
                flex-wrap: nowrap;
                width: 100%;
            }
            .col-5, .col-7 {
                flex-grow: 1;
                padding: 10px;
            }
            .col-7 {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%; 
            }            
        `;             

        printWindow.document.head.appendChild(styleTag);
        
        //Inject container for React rendering
        const container = printWindow.document.createElement('div');
        printWindow.document.body.appendChild(container);

        //Render React context
        createRoot(container).render(
            <React.StrictMode>
                <PrintForm orderList={orderList}/>
            </React.StrictMode>
        );

        //Trigger print dialog after a slight delay
        setTimeout (() =>{
            // Trigger print dialog
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        }, 200);        
    }
}

interface PropForm{
    orderList: OrderList
}

const PrintForm = ({
    orderList
}: PropForm) =>{
    
    //Array of the order printing objects
    let order_print: OrderPrint[] = [];

    let counter=0;
    orderList.list.forEach(entry =>{        
        const detail = entry.orderDetail;        
        
        //Init the order print
        const order = initOrderPrint();
        order.lines = detail.order_string.join("\n");
        order.line_count = detail.order_string.length;
        order.img_canvas = detail.img_canvas;
        order.img_width = detail.img_width;
        order.img_height = detail.img_height;        

        //If there is a comment
        if(entry.orderDetail.comment !== ""){
            order.lines += "\nComment: " + entry.orderDetail.comment;
            
            //******************** Will need to find out how to count the number of lines in a comment and add that too the line count
            order.line_count += 4 //This is just a rough guess at comment line count; not really sure how we would figure it out otherwise
        }
        
        order_print[counter] = order;
        counter++;
    });

    useEffect(() =>{
        order_print.forEach(order =>{
            
            //Get the canvas and the context
            const canvas = order.canvas_ref.current;
            if(!canvas) return;
            const context = canvas.getContext("2d");
            if (!context) return;

            //Set the image
            const img = new Image();
            img.src = order.img_canvas

            img.onload = () =>{

                //************* Need to actually set the scaled image size somehow
                //Set the image size
                const width = order.img_width * SCALE_IMAGE;
                const height = order.img_height * SCALE_IMAGE;

                canvas.width = width;
                canvas.height = height;

                context.drawImage(img, 0, 0, width, height);
            };
        })
    }, [order_print])
    
    return (
        <Fragment>
            <div 
                className="container-fluid" 
                style={{ 
                    position: "relative", 
                    width: "100vw", 
                    height: "auto",                     
                    padding: "10px" 
                }}
                >
                <img 
                    src={imgWD} 
                    alt="Window Depot Logo" 
                    style={{ 
                    position: "absolute", 
                    left: "0", 
                    top: "10px", 
                    width: "200px", 
                    height: "auto" 
                    }} 
                />
                <img 
                    src={imgSI} 
                    alt="Solar Industries Logo" 
                    style={{ 
                    position: "absolute", 
                    right: "50", 
                    top: "10px", 
                    width: "200px", 
                    height: "auto" 
                    }} 
                />
            </div> 
            
            <div className="container-fluid" style={{marginTop: "80px"}}>
                {order_print.map((order, index) =>{                    
                    return(                            
                        <div className="row align-items-center mb-3" style={{ border: "1px solid black" }} key={index}>
                            <div className="col-5" >
                                <Form.Control
                                    as="textarea"
                                    value={order.lines}
                                    readOnly
                                    rows={order.line_count}
                                    style={{ 
                                        width: "100%", 
                                        border: "none", 
                                        outline: "none",
                                        resize: "none"
                                    }}
                                    
                                />
                            </div>
                            <div className="col-7 d-flex align-items-center justify-content-center">
                                <canvas 
                                    ref={order.canvas_ref}
                                />
                            </div>
                        </div>                            
                    );
                })}
            </div>
        </Fragment>
    );    
}

