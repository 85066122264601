import {Fragment} from "react";
import {ConstrainValues} from "../constraints/constrain_value";
import {RenderData} from "../render/render_components/render_data/render_data";
import {ControlCheck} from "../controls/control_check";
import {useEffect, useState} from "react";
import {getOptionArray} from "../render/render_components/panel/functions_panel";

interface Props{
    id_item: string;
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, key_id: number) => void;
    renderData: RenderData;
    constrainValues: ConstrainValues;
}

export const OptionForm = ({id_item, DataHandler, renderData, constrainValues}: Props) =>{
    
    //Use state array of Option ID's
    const [selectArray, setSelectArray] = useState<number[]>([]);

    useEffect(() =>{        
        //If there is an actively selected window
        if(renderData.active_fragment_id >=0){
            //Short handing
            const window = renderData.window[renderData.active_fragment_id];
            const panel = window.panel[window.active_panel_id];
            
            //Get the array of option id's and set it as the selected array
            const id_array = getOptionArray(panel);
            setSelectArray(id_array);
        }
        else{
            setSelectArray([]);
        }

    }, [renderData])    
    
    return (
        <Fragment>            
            <ControlCheck 
                id_item={id_item}
                group_name="group_option"
                DataHandler={DataHandler}
                parameter={renderData.config_values.option}
                constrain={constrainValues.option.parameter}
                select_array={selectArray}
            />
        </Fragment>
    );
}