//___________________________________________________________________________________________________________________________________________________________________________________
//  Front-End Entry Point
//
//  This is essentially the entry point for the front end.
//___________________________________________________________________________________________________________________________________________________________________________________


import React, {Fragment} from "react";
//import logo from './logo.svg';
import "./App.css";

import Configurator from "./components/configurator/configurator";

function App() {
  return (
    <Fragment>
      <Configurator />
    </Fragment>
  );
}

export default App;
