import {RenderData} from "../render_data/render_data";
import {WindowFragment, initWindowFragment, Line} from "./window_fragment";
import {Segment} from "../render_data/enum_render";
import {updateMaskWindow} from "../mask/mask_standard";
import {createOnePanel, updatePanelLines} from "../panel/functions_panel";
import {getScaleCoord} from "../model/matrix_functions";
import {
    updateWindowArch, 
    updateStraightArch,
    updateHalfArch,
    updateStraightHalfArch
} from "./functions_arc";
import { 
    getWidthInch, 
    getHeightInch,
    getWidthPixel,
    getHeightPixel
} from "../viewport/functions_viewport";
import {
    updatePeak,
    updateTriangle,
    updateRightTriangle,
    updateStraightRightTriangle
} from "./functions_triangle";
import {
    updateCircle,
    updateOval,
    updateHexagon,
    updateOctagon
} from "./functions_circular";

import {initSecondHeight} from "./functions_shape_window";

//Add a window to the rendering object
export const createWindowFragment = (        
    renderData: RenderData,
    left: number,
    right: number,
    top: number,
    bottom: number
) =>{

    let window: WindowFragment = initWindowFragment();
    
    window.id = renderData.window.length;

    //Top
    window.model.line[Segment.TOP].x1 = left;
    window.model.line[Segment.TOP].y1 = top;
    window.model.line[Segment.TOP].x2 = right;
    window.model.line[Segment.TOP].y2 = top;
    //Bottom
    window.model.line[Segment.BOTTOM].x1 = left;
    window.model.line[Segment.BOTTOM].y1 = bottom;
    window.model.line[Segment.BOTTOM].x2 = right;
    window.model.line[Segment.BOTTOM].y2 = bottom;
    //Left
    window.model.line[Segment.LEFT].x1 = left;
    window.model.line[Segment.LEFT].y1 = top;
    window.model.line[Segment.LEFT].x2 = left;
    window.model.line[Segment.LEFT].y2 = bottom;
    //Right
    window.model.line[Segment.RIGHT].x1 = right;
    window.model.line[Segment.RIGHT].y1 = top;
    window.model.line[Segment.RIGHT].x2 = right;
    window.model.line[Segment.RIGHT].y2 = bottom;
        
    updateModelInch(renderData, window);
    updateWindow(renderData, window);
    
    //Set the default Product Type as the current product type
    const record = renderData.config_values.product_type.get(window.data.product_type.default_value);
    window.data.product_type.value = record!.description;
    window.data.product_type.id = record!.id;

    renderData.window[window.id] = window;    
    
    //Create the panel fragment based on the mask_dimensions (the inner part of the window fragment)    
    createOnePanel(renderData, window);
}

export const updateModelInch = (renderData: RenderData, window: WindowFragment) =>{
    //Sides
    window.model_inch.top = getHeightInch(window.model.line[Segment.TOP].y1, renderData);
    window.model_inch.bottom = getHeightInch(window.model.line[Segment.BOTTOM].y1, renderData);
    window.model_inch.left = getWidthInch(window.model.line[Segment.LEFT].x1, renderData);
    window.model_inch.right = getWidthInch(window.model.line[Segment.RIGHT].x1, renderData);
    //Width and Height
    window.model_inch.width = window.model_inch.right - window.model_inch.left;
    window.model_inch.height = window.model_inch.bottom - window.model_inch.top;

    //Round the numbers to 4 decimal places
    const deci = 10000;
    window.model_inch.top = Math.round(window.model_inch.top * deci) / deci;
    window.model_inch.bottom = Math.round(window.model_inch.bottom * deci) / deci;
    window.model_inch.left = Math.round(window.model_inch.left * deci) / deci;
    window.model_inch.right = Math.round(window.model_inch.right * deci) / deci;
    window.model_inch.width = Math.round(window.model_inch.width * deci) / deci;
    window.model_inch.height = Math.round(window.model_inch.height * deci) / deci;
}

//Update the coordinates of a line and all other lines which need to be updated as well.
//Ex: If the bottom of a fragment gets moved, other tops and bottoms may need to adjust
export const updateFragmentLines = (    
    new_coordinate_value: number,
    adjust_line: Line,
    renderData: RenderData,    
) =>{    
    //The line for comparing whether or not other lines overlap with it
    const compare_line = getCompareLine(renderData, adjust_line);        
    let is_horizontal = false;    
    
    //Check if it's a horizontal line, if not, it's a vertical line
    if((adjust_line.y1 - adjust_line.y2) === 0){
        is_horizontal = true;
    }    
        
    //Iterate through the windows
    renderData.window.forEach((window) =>{        
        //Iterate over the lines, to check if an overlap exists
        let overlap: boolean = false;        
        window.model.line.forEach((line) =>{            
            if(lineOverlap(compare_line, line)){
                overlap = true;
            }
        })
        
        //If an overlap exists, update all vertices with a coordinate that matches
        if(overlap){
            window.model.line.forEach((line) =>{
                
                //***********************************************************************
                //Need to add functionality to delete a window, if it's collapsed
                //beyond it's boundaries.
                //***********************************************************************
                
                //Note, the coordinates only need to match one y or x value respectively
                //If it's horizontal, check/change the y axis values
                if(is_horizontal){
                    //Update any coordinate with a matching y value
                    if(line.y1 === compare_line.y1){
                        line.y1 = new_coordinate_value;                        
                    }
                    if(line.y2 === compare_line.y1){
                        line.y2 = new_coordinate_value;
                    }       
                }
                //It's vertical, check/change the x axis values
                else{
                    //Update any coordinate with a matching x value
                    if(line.x1 === compare_line.x1){
                        line.x1 = new_coordinate_value;
                    }
                    if(line.x2 === compare_line.x1){
                        line.x2 = new_coordinate_value;
                    }                    
                }
            })
            updateModelInch(renderData, window);            
            updateWindow(renderData, window);            
        }   
    })    
}

//Update a window, based on its product type
export const updateWindow = (renderData: RenderData, window: WindowFragment) =>{
    updateMaskWindow(window, renderData);
    
    switch(window.data.product_type.value){
        case "Factory Arch":            
            if(!updateWindowArch(window, renderData)) revertToPW(renderData, window);
        break;
        case "Straight Leg Arch":
            checkSecondHeight(window);
            updateStraightArch(window, renderData);
        break;
        case "L 1/4 Circle":
        case "R 1/4 Circle":                        
            if(!updateHalfArch(window, renderData)) revertToPW(renderData, window);        
        break;
        case "L Straight Leg Arch":
        case "R Straight Leg Arch":
            checkSecondHeight(window);
            updateStraightHalfArch(window, renderData);
        break;
        case "Peak":
            checkSecondHeight(window);
            updatePeak(window, renderData);
        break;
        case "Triangle":            
            updateTriangle(window, renderData);
        break;
        case "R Triangle":
        case "L Triangle":            
            updateRightTriangle(window, renderData);
        break;
        case "R Rake":
        case "L Rake":
            updateStraightRightTriangle(window, renderData);
        break;
        case "Circle":
            updateCircle(window);            
        break;
        case "Oval":
            updateOval(window);
        break;
        case "Hexagon":
            updateHexagon(window);
        break;
        case "Octagon":
            updateOctagon(window);
        break;
    }
}

//Change the width of a panel
export const changePanelWidth = (
    renderData: RenderData,
    value: string
) =>{      
    const window = renderData.window[renderData.active_fragment_id];
    const active_panel = window.panel[window.active_panel_id];

    //Convert the value and width to pixels
    const pixel_value = getWidthPixel(Number(value), renderData); //Convert inch value to pixels
    const pixel_width = getWidthPixel(active_panel.model_inch.width, renderData); //Convert the active panels width to pixels    

    //Scale the input value. Have to reduce everything to a 0 origin before scaling.    
    const current_min = 0
    const current_max = window.model.line[Segment.RIGHT].x1 - window.model.line[Segment.LEFT].x1;    
    const new_min = window.mask_window.left - window.model.line[Segment.LEFT].x1;
    const new_max = window.mask_window.right - window.model.line[Segment.LEFT].x1;
    const scale_value = getScaleCoord(current_min, current_max, new_min, new_max, pixel_value) + window.model.line[Segment.LEFT].x1;
    
    //If it borders the left
    if(active_panel.model.line[Segment.LEFT].x1 === window.mask_window.left){
        updatePanelLines(scale_value, active_panel.model.line[Segment.RIGHT], renderData, window);
    }
    else if(active_panel.model.line[Segment.RIGHT].x1 === window.mask_window.right){ //It borders the right                                    
        const pixel_left = 
            active_panel.model.line[Segment.LEFT].x1 + 
            window.mask_window.left + 
            pixel_width - 
            scale_value; 
        updatePanelLines(pixel_left, active_panel.model.line[Segment.LEFT], renderData, window);
    }
    else{ //Panel is in the middle        
        
        const half_window = window.model.line[Segment.LEFT].x1 /2;

        const half_border = (window.model.line[Segment.LEFT].x1 - window.mask_window.left)/2;
        const half_value = (scale_value - pixel_width)/2;        
        const pixel_right = (active_panel.model.line[Segment.RIGHT].x1 + half_value) + half_border - half_window 
        const pixel_left = (active_panel.model.line[Segment.LEFT].x1 - half_value) - half_border + half_window;

        updatePanelLines(pixel_left, active_panel.model.line[Segment.LEFT], renderData, window);
        updatePanelLines(pixel_right, active_panel.model.line[Segment.RIGHT], renderData, window);
    }
    
    window.data.panel_size_changed = true;
    
    renderData.render_frame = true;
}

//Change the height of a panel
export const changePanelHeight = (
    renderData: RenderData,
    value: string
) =>{
    const window = renderData.window[renderData.active_fragment_id];
    const active_panel = window.panel[window.active_panel_id];

    //Convert the value and height to pixels
    const pixel_value = getHeightPixel(Number(value), renderData); //Convert inch value to pixels
    const pixel_height = getHeightPixel(active_panel.model_inch.height, renderData); //Convert the active panels height to pixels    

    //Scale the input value. Have to reduce everything to a 0 origin before scaling.    
    const current_min = 0
    const current_max = window.model.line[Segment.BOTTOM].y1 - window.model.line[Segment.TOP].y1;    
    const new_min = window.mask_window.top - window.model.line[Segment.TOP].y1;
    const new_max = window.mask_window.bottom - window.model.line[Segment.TOP].y1;
    const scale_value = getScaleCoord(current_min, current_max, new_min, new_max, pixel_value) + window.model.line[Segment.TOP].y1;
    
    //If it borders the top
    if(active_panel.model.line[Segment.TOP].y1 === window.mask_window.top){
        updatePanelLines(scale_value, active_panel.model.line[Segment.BOTTOM], renderData, window);
    }
    else { //It borders the bottom
        const pixel_top = 
            active_panel.model.line[Segment.TOP].y1 +
            window.mask_window.top +
            pixel_height -
            scale_value; 
        
        updatePanelLines(pixel_top, active_panel.model.line[Segment.TOP], renderData, window);            
    }   
    
    window.data.panel_size_changed = true;

    renderData.render_frame = true;
}

//Change the second height of the window
export const changeSecondHeight = (renderData: RenderData, value: string) =>{
    const window = renderData.window[renderData.active_fragment_id];

    //Invert the value to match the canvas coordinates
    const invert_secondary = window.model_inch.height - Number(value);    
    window.model.second_height = getHeightPixel(invert_secondary, renderData);        
    switch(window.data.product_type.value){
        case "Straight Leg Arch":
            updateStraightArch(window, renderData);
        break;
        case "L Straight Leg Arch":
        case "R Straight Leg Arch":
            updateStraightHalfArch(window, renderData);            
        break;
        case "Peak":
            updatePeak(window, renderData);            
        break;
        case "R Rake":
        case "L Rake":
            updateStraightRightTriangle(window, renderData);
        break;
        default:
            window.model.second_height =0;
        break;
    }

    renderData.render_frame = true;
}

//Check to see if the second height needs to be initialized again
const checkSecondHeight = (window: WindowFragment) =>{
    const height = window.model.line[Segment.BOTTOM].y1 - window.model.line[Segment.TOP].y1;
    
    if(height <= window.model.second_height){
        initSecondHeight(window);
    }
}

//Revert a window to a PW window
export const revertToPW = (renderData: RenderData, window: WindowFragment) =>{
    createOnePanel(renderData, window);
    const product_type = renderData.config_values.product_type.get("PW");
    
    window.data.product_type.value = product_type!.description;
    window.data.product_type.id = product_type!.id
}

//Get a comparison line for helping determine which fragment borders overlap
const getCompareLine = (renderData: RenderData, adjust_line: Line) =>{
    let compare_line: Line = {x1: 0, y1: 0, x2: 0, y2: 0};
    
    //**** The proper way to do this would be to construct a line of all overlapping lines.
    //**** Basically, if any line overlaps the adjust_line, make a new line out of their end points.
    //**** Then find any line that overlaps that line and repeat the process until no more lines are found to overlap.
    //**** The code below just makes a line accross the viewport.

    let is_horizontal = false;

    //Check if it's a horizontal line
    if((adjust_line.y1 - adjust_line.y2) === 0){
        //Offset by 1 to avoid overlapping end points        
        compare_line = {
            x1: adjust_line.x1 +1,            
            y1: adjust_line.y1,
            x2: adjust_line.x2 -1,
            y2: adjust_line.y1
        }

        is_horizontal = true;

    }
    else{ //It's a vertical line
        
        //Offset by 1 to avoid overlapping points
        compare_line ={
            x1: adjust_line.x1,
            x2: adjust_line.x1,
            y1: adjust_line.y1 + 1,
            y2: adjust_line.y2 -1
        }

        //Iterate through all the windows
        renderData.window.forEach(window =>{
            //Iterate through all the lines
            window.model.line.forEach(line =>{
                //If the window line overlaps with the compare line
                if(lineOverlap(line, compare_line)){
                    //If any of the end points are greater than the compare line, make them the new end point
                    if(line.y1< compare_line.y1){
                        compare_line.y1 = line.y1 +1;
                    }
                    if(line.y2> compare_line.y2){
                        compare_line.y2 = line.y2 -1;
                    }                    
                }
            })
        })
    }

    //Iterate through all the windows. Have to recheck all windows when the compare line is modified
    for(let index =0; index < renderData.window.length; index++){
        const window = renderData.window[index];
        
        //Iterate through all the lines
        window.model.line.forEach(line =>{
            //If the window line overlaps with the compare line
            if(lineOverlap(line, compare_line)){
                //If any of the end points are greater than the compare line, make them the new end point                
                if(is_horizontal){
                    if(line.x1 < compare_line.x1){
                        //Must be a difference greater than 1 in order to add
                        if(compare_line.x1 - line.x1 > 1){
                            compare_line.x1 = line.x1 +1;
                            index = -1;
                        }
                    }
                    if(line.x2 > compare_line.x2){
                        //Must be a difference greater than 1 in order to add
                        if(line.x2 - compare_line.x2 > 1){
                            compare_line.x2 = line.x2 -1;
                            index = -1;
                        }
                    }
                }
                else{
                    if(line.y1< compare_line.y1){
                        //Must be a difference greater than 1 in order to add
                        if(compare_line.y1 - line.y1 > 1){
                            compare_line.y1 = line.y1 +1;
                            index = -1;
                        }
                        
                    }                    
                    if(line.y2> compare_line.y2){
                        if(line.y2 - compare_line.y2 > 1){
                            compare_line.y2 = line.y2 -1;
                            index = -1;
                        }
                    }
                }
            }
        })
    }

    return compare_line;
}

//Check for overlap between two lines
const lineOverlap = (line1: Line, line2: Line): boolean => {
    //Calculations
    //Collinear
    //(x2-x1)(y3-y1)-(y2-y1)(x3-x1) =0 &&
    //(x2-x1)(y4-y1)-(y2-y1)(x4-x1) =0
    //Projection X and Y axis
    //max(x1, x2) >= min(x3, x4) && max(x3, x4) >= min(x1, x2)
    //max(y1, y2) >= min(y3, y4) && max(y3, y4) >= min(y1, y2)
    
    //Reassign to make this easier
    const x1 = line1.x1;
    const x2 = line1.x2;
    const x3 = line2.x1;
    const x4 = line2.x2;
    const y1 = line1.y1;
    const y2 = line1.y2;
    const y3 = line2.y1;
    const y4 = line2.y2;

    //Collinear check
    if(
        ((x2-x1)*(y3-y1)-(y2-y1)*(x3-x1) === 0) && 
        ((x2-x1)*(y4-y1)-(y2-y1)*(x4-x1) === 0)
    ){
        //Projection on the X and Y axis
        if(
            (Math.max(x1, x2) >= Math.min(x3, x4)) &&
            (Math.max(x3, x4) >= Math.min(x1, x2)) &&
            (Math.max(y1, y2) >= Math.min(y3, y4)) &&
            (Math.max(y3, y4) >= Math.min(y1, y2))
        ) return true;
    }
    
    //Return false if it's not collinear and or not overlapping on an axis
    return false;
}



