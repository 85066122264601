import {RenderData} from "../../render/render_components/render_data/render_data";
import {initDataPoint} from "../../render/render_components/render_data/render_data";

const Option = (form_object: string, object_value: string, key_id: number, renderData: RenderData) =>{   
    
    //Make sure a window is selected
    if(renderData.active_fragment_id >=0){
        //Shorthanding
        const window = renderData.window[renderData.active_fragment_id];
        const panel = window.panel[window.active_panel_id];
        const option = panel.data.option;

        if(object_value === "true"){
            //If the object doesen't exist in the map; really, it shouldn't; this is just to be sure it doesn't throw an error
            if(!option.has(form_object)){
                const data_point = initDataPoint("");
                data_point.value = form_object;
                data_point.id = key_id;

                panel.data.option.set(form_object, data_point);                
            }            
        }
        else{
            option.delete(form_object);
        }
    }
}

export default Option;