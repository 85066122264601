import {useState} from "react";
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

interface ButtonProps{
    buttons: string[];
    id_item: string;
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, key_id: number) => void;
}

const ToggleButtons = ({buttons, id_item, DataHandler}: ButtonProps) => {
    const [activeButton, setActiveButton] = useState<number | null>(null);

    const handleButtonClick = (index: number, button: string) =>{
        setActiveButton(index);
        DataHandler(id_item, "", button, "", -1);
    }

    return (
        <Row>
            {buttons.map((button, index) => (
                <Col key={index} xs="auto">
                    <Button
                        variant={activeButton === index ? "primary" : "secondary"} // Change variant based on active state
                        active={activeButton === index}
                        id={button}
                        onClick={() => handleButtonClick(index, button)}
                    >
                        {button}
                    </Button>
                </Col>
            ))}
        </Row>        
    );
}

export default ToggleButtons;