import { ConstrainValues } from "./constrain_value";
import { RenderData } from "../render/render_components/render_data/render_data";
import { WindowFragment, initLine, Line } from "../render/render_components/window/window_fragment";
import { getWidthInch, getHeightInch } from "../render/render_components/viewport/functions_viewport";
import { initVertexPoint, getLineLength} from "../render/render_components/model/vertex_point";
import { Segment, ShapeDirection } from "../render/render_components/render_data/enum_render";

export const limitSide = (constrainValues: ConstrainValues, renderData: RenderData) =>{    
    //Iterate through all the windows and perform error checks based on their type
    renderData.window.forEach(window =>{
        switch(window.data.product_type.value){
            case "Triangle":
            case "Factory Arch":
                limitBottom(window, renderData, constrainValues);
            break;            
            case "Hexagon":            
            case "Octagon":
                limitBuffer(window, renderData, constrainValues);
            break;
            case "L Triangle":
            case "L 1/4 Circle":
                limitBottom(window, renderData, constrainValues);
                limitRight(window, renderData, constrainValues);
            break;
            case "R Triangle":
            case "R 1/4 Circle":
                limitBottom(window, renderData, constrainValues);
                limitLeft(window, renderData, constrainValues);
            break;
            case "L Rake":
            case "L Straight Leg Arch":
            case "Peak":
            case "R Rake":
            case "R Straight Leg Arch":
                limitLower(window, renderData, constrainValues);                
            break;
            case "Circle":
            case "Oval":
                //Do nothing for circles and ovals
            break;
            default:
                limitStandard(window, renderData, constrainValues);
            break;            
        }
    })
}

//Check the limits of a lower half, for windows that have shapes on top
const limitLower = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{
    
    //Check the bottom horizontal line
    limitBottom(window, renderData, constrainValues);

    //This only checks the short side of the window, don't really need to check the long side    
    let x = 0;
    let y1 = window.model.second_height;
    let y2 = window.model.line[Segment.BOTTOM].y1;
    if(window.model.shape_direction === ShapeDirection.LEFT){
        x = window.model.line[Segment.LEFT].x1;
    }
    else if(window.model.shape_direction === ShapeDirection.RIGHT){
        x = window.model.line[Segment.RIGHT].x1;
    }

    //Line will always be vertical, thus same x values
    checkError( //Check if the line is an error
        x,
        y1,
        x,
        y2,
        renderData,
        constrainValues
    )    
}

//Check for limits with a hexagon window
const limitBuffer = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{
    //Iterate through all the vertices
    const vertex = window.mask_buffer.outer.vertex;    
    for(let counter =1; counter < vertex.length; counter ++){
        const v1 = vertex[counter-1];
        const v2 = vertex[counter];

        checkError( //Check for an error and add it if it exists
            v1.x,
            v1.y,
            v2.x,
            v2.y,
            renderData,
            constrainValues
        )        
    }

    //Check the last segment
    const v1 = vertex[0];
    const v2 = vertex[vertex.length -1];
    checkError(
        v1.x,
        v1.y,
        v2.x,
        v2.y,
        renderData,
        constrainValues
    )
}

//Check for limits on the right side of the window
const limitRight = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{
    limitLine(window.model.line[Segment.RIGHT], renderData, constrainValues);
}

//Check for limits on the left side of the window
const limitLeft = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{
    limitLine(window.model.line[Segment.LEFT], renderData, constrainValues);
}

//Check for limits on the bottom of the window
const limitBottom = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{
    limitLine(window.model.line[Segment.BOTTOM], renderData, constrainValues);
}

//Perform a limit check on a specific line
const limitLine = (line: Line, renderData: RenderData, constrainValues: ConstrainValues) =>{
    checkError(
        line.x1,
        line.y1,
        line.x2,
        line.y2,
        renderData,
        constrainValues
    )
}

//Check for limits within a standard rectangular window
const limitStandard = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{
    
    //Iterate through all the lines in the window
    window.model.line.forEach(line =>{        
        checkError( //Check for an error and add it if needed
            line.x1,
            line.y1,
            line.x2,
            line.y2,
            renderData,
            constrainValues
        )
    })
}

//Check for an error and add it if necessary. This is mostly to shorthand some code
const checkError = (x1: number, y1: number, x2: number, y2: number, renderData: RenderData, constrainValues: ConstrainValues) =>{
    
    if(!isValidLength( //If the line is not a valid length
        x1,
        y1,
        x2,
        y2,
        renderData,
        constrainValues
    )){
        addLineError( //Add the line as an error
            x1,
            y1,
            x2,
            y2,
            renderData
        )
    }
    
}

//Add a line to the list of errors
const addLineError = (x1: number, y1: number, x2: number, y2: number, renderData: RenderData) =>{
    const line = initLine();
    line.x1 = x1;
    line.y1 = y1;
    line.x2 = x2;
    line.y2 = y2;

    //Add the line to the list of error lines for sides
    renderData.error.side.line[renderData.error.side.line.length] = line;
}

//Determine if a line is a valid length in inches
const isValidLength = (x1: number, y1: number, x2: number, y2: number, renderData: RenderData, constrainValues: ConstrainValues): boolean =>{    
    const v1 = initVertexPoint();
    const v2 = initVertexPoint();

    //Get vertices in terms of inches
    v1.x = getWidthInch(x1, renderData);
    v1.y = getHeightInch(y1, renderData);
    v2.x = getWidthInch(x2, renderData);
    v2.y = getHeightInch(y2, renderData);
        
    //If the length is less than the limit
    if(getLineLength(v1, v2) < constrainValues.limit.min_side_length){
        return false;
    }

    return true;
}