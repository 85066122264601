import {RenderData} from "../render_data/render_data";
import {WindowFragment, Line, initLine} from "./window_fragment";
import {Segment} from "../render_data/enum_render";
import {initSecondHeight} from "./functions_shape_window";
import {getHeightInch} from "../viewport/functions_viewport";
import {ShapeDirection} from "../render_data/enum_render";
import {initVertexPoint} from "../model/vertex_point";

//Update the straight half arch
export const updateStraightHalfArch = (window: WindowFragment, renderData: RenderData) =>{
    
    //Init the second height if it's too small
    if(window.model.second_height <=0){
        initSecondHeight(window);
    }
    //Update the half arch
    updateHalfArch(window, renderData);
}

//Update the half arch
export const updateHalfArch = (    
    window: WindowFragment,
    renderData: RenderData
): boolean =>{
    //Shorthand some values for ease or reading
    const left = window.model.line[Segment.LEFT].x1;
    const right = window.model.line[Segment.RIGHT].x1;
    const top = window.model.line[Segment.TOP].y1;
    const height = window.model.line[Segment.BOTTOM].y1;
    const width = right - left;
    const second_height = window.model.second_height; 
    
    //Height cannot be greater than the width
    if(window.model.second_height === 0){        
        if(height > width) return false;
    }    
    
    //Shorthand panel values
    const panel = window.panel[0]; //Get the only panel
    const inner_left = panel.model.line[Segment.LEFT].x1;
    const inner_right = panel.model.line[Segment.RIGHT].x1;
    const inner_top = panel.model.line[Segment.TOP].y1;    

    //Set the bottom to either the window or the second height
    let bottom = window.model.line[Segment.BOTTOM].y1
    let inner_bottom = panel.model.line[Segment.BOTTOM].y1
    if(second_height >0){
        bottom = second_height;
        inner_bottom = bottom;
    }
    
    const adjust_x = 2; //This is kentucky windage to account for some pixel oddities
    
    //Set the side values, based on the direction of the shape
    let outer_tall_side = right;
    let inner_tall_side = inner_right;
    let inner_short_side = inner_left - adjust_x;
    if(window.model.shape_direction === ShapeDirection.RIGHT){
        outer_tall_side = left;
        inner_tall_side = inner_left;
        inner_short_side = inner_right + adjust_x;
    }

    //Init the side and bottom intersects
    const intersect_x = initLine();
    const intersect_y = initLine();

    //Set the intersect bottom values
    intersect_x.x1 = left;
    intersect_x.x2 = right;
    intersect_x.y1 = bottom;
    intersect_x.y2 = bottom;   
    //Set the side intersects
    intersect_y.x1 = outer_tall_side;
    intersect_y.x2 = outer_tall_side;
    intersect_y.y1 = top;
    intersect_y.y2 = bottom;    
    
    //Get the radius 
    const radius = getArcRadius(bottom, width *2);        
    
    //Second height cannot be greater than the radius
    if(second_height > radius){
        window.model.second_height = width;
        updateHalfArch(window, renderData);
        return false;
    }
    
    //Set the center of the circle
    const center_x = outer_tall_side;
    const center_y = top + radius;
    
    //Get the top and side angles in radians
    const radian_top = getArcAngles(
        center_x,
        center_y,
        radius,
        intersect_y
    );
    const radian_side = getArcAngles(
        center_x,
        center_y,
        radius,
        intersect_x
    );
        
    const intersect_top = initLine();
    const intersect_side = initLine();
    //Set the intersect top
    intersect_top.x1 = inner_tall_side;
    intersect_top.x2 = inner_tall_side;
    intersect_top.y1 = top;
    intersect_top.y2 = bottom;
    //Set the intersect side to the inner side or inner bottom    
    if(second_height >0){
        //Set it to the side
        intersect_side.x1 = inner_short_side;
        intersect_side.x2 = inner_short_side;
        intersect_side.y1 = top;
        intersect_side.y2 = bottom;
    }
    else{
        //Set it to the bottom
        intersect_side.x1 = left;
        intersect_side.x2 = right;
        intersect_side.y1 = inner_bottom;
        intersect_side.y2 = inner_bottom;
    }
    
    const inner_radius = radius - (window.mask_window.gap * (1 + 1/3));
    //Get the top and side angle for the inner arc
    const inner_radian_top = getArcAngles(
        center_x,
        center_y,
        inner_radius,
        intersect_top
    )
    const inner_radian_side = getArcAngles(
        center_x,
        center_y,
        inner_radius,
        intersect_side
    )
    
    //Set the inner side angle
    let inner_side_angle =0;    
    if(second_height >0){
        //We're using a vertical intercept, the angle is always angle2        
        if(!Number.isNaN(inner_radian_side.angle2)){
            inner_side_angle = inner_radian_side.angle2;            
        }
        else{  //Catch the NaN radius as a result of kentucky windage, and correct it
            if(window.model.shape_direction === ShapeDirection.LEFT){
                inner_side_angle = radian_side.angle2;
            }
            else{
                inner_side_angle = radian_side.angle1;
            }
        }
    }
    else{
        //We're using a horizontal intercept, set based on shape direction
        if(window.model.shape_direction === ShapeDirection.LEFT){
            inner_side_angle = inner_radian_side.angle2;
        }
        else{
            inner_side_angle = inner_radian_side.angle1;
        }
    }    
    
    //Get the coordinates of the side point and set it to the inner side point
    const inner_side_point = initVertexPoint();
    const inner_side_coord = getCoordinateFromAngle(
        center_x,
        center_y,
        inner_radius,
        inner_side_angle
    )
    inner_side_point.x = inner_side_coord.x;
    inner_side_point.y = inner_side_coord.y;

    //Set the lower mask offsets if needed
    if(second_height >0){
        const lower_offset = inner_side_point.y - window.model.second_height;
        window.mask_lower.offset_left = lower_offset;
        window.mask_lower.offset_right = lower_offset;
    }

    //Set the outer values
    window.mask_arch.center_x = center_x;
    window.mask_arch.center_y = center_y;
    window.mask_arch.radius = radius;        
    //Set the inner values
    window.mask_arch.inner_radius = inner_radius;
    //Set most of the outer triangle values
    window.mask_arch.outer_triangle.upper.y = top;    
    window.mask_arch.outer_triangle.left.x = left;
    window.mask_arch.outer_triangle.left.y = bottom;
    window.mask_arch.outer_triangle.right.x = right;
    window.mask_arch.outer_triangle.right.y = bottom;
    //Set inner triangle y values
    window.mask_arch.inner_triangle.upper.y = inner_top;
    window.mask_arch.inner_triangle.left.y = inner_side_point.y;
    window.mask_arch.inner_triangle.right.y = inner_side_point.y;
    //Set the start and end angles; set the triangle fill coordinates
    if(window.model.shape_direction === ShapeDirection.LEFT){
        //Set the start and end angles for the inner and outer arcs
        window.mask_arch.radian_start = radian_top.angle2;
        window.mask_arch.radian_end = radian_side.angle2;
        window.mask_arch.inner_start = inner_radian_top.angle2;        
        window.mask_arch.inner_end = inner_side_angle;

        //Set the outer triangle top x
        window.mask_arch.outer_triangle.upper.x = outer_tall_side;        
        
        //Set the inner triangle x values
        window.mask_arch.inner_triangle.upper.x = inner_tall_side;
        window.mask_arch.inner_triangle.right.x = inner_tall_side;
        window.mask_arch.inner_triangle.left.x = inner_side_point.x;
    }else{
        //Set the start and end angles for the inner and outer arcs
        window.mask_arch.radian_start = radian_side.angle1;
        window.mask_arch.radian_end = radian_top.angle2;        
        window.mask_arch.inner_start = inner_side_angle;
        window.mask_arch.inner_end = inner_radian_top.angle2;

        //Set the outer triangle top x
        window.mask_arch.outer_triangle.upper.x = outer_tall_side;
        
        //Set the inner triangle x values
        window.mask_arch.inner_triangle.upper.x = inner_left;
        window.mask_arch.inner_triangle.left.x = inner_left;
        window.mask_arch.inner_triangle.right.x = inner_side_point.x;
    }

    //Set the inches for second height
    const deci = 10000;
    window.model_inch.second_height = getHeightInch(second_height, renderData);    
    window.model_inch.second_height = Math.round(window.model_inch.second_height * deci) / deci;    

    return true;
}

//Update the straight arch 
export const updateStraightArch = (window: WindowFragment, renderData: RenderData): boolean =>{
    //Set the left right and top, to make things easier
    const left = window.model.line[Segment.LEFT].x1;
    const right = window.model.line[Segment.RIGHT].x1;
    const top = window.model.line[Segment.TOP].y1;    

    //Calc height and width
    const width = right - left;    
    
    if(window.model.second_height <=0){
        initSecondHeight(window);
    }
    
    const second_height = window.model.second_height;        

    //Create the intersect line
    const intersect = initLine();
    intersect.x1 = left
    intersect.x2 = right
    intersect.y1 = second_height;
    intersect.y2 = second_height;

    //Calculate the arch values and get the start end angles to draw the arc
    const radius = getArcRadius(second_height, width);    
    const center_x = (left + right) /2;
    const center_y = top + radius;
    const radian = getArcAngles(
        center_x,
        center_y,
        radius,
        intersect
    )
    
    //Second height cannot be greater than the radius
    if(second_height > radius){
        window.model.second_height = width/2;
        updateStraightArch(window, renderData);
        return false;
    }   

    //Set the arc values to the window arch
    window.mask_arch.center_x = center_x;
    window.mask_arch.center_y = center_y;
    window.mask_arch.radius = radius;
    window.mask_arch.radian_start = radian.angle1;
    window.mask_arch.radian_end = radian.angle2;    
    
    //Get the gap and adjust the radius a little for a better visual border
    const gap = window.mask_window.gap;
    const inner_radius = radius - (gap * (1 + 1/3));  
    
    //Get the only panel
    const panel = window.panel[0];

    //Create the left and right vertical intersect lines
    const intersect_left = initLine();
    const intersect_right = initLine();
    
    const adjust_x = 2; //This is kentucky windage to account for some pixel oddities
    
    //Set the left intersect line values
    intersect_left.x1 = panel.model.line[Segment.LEFT].x1 - adjust_x;
    intersect_left.x2 = panel.model.line[Segment.LEFT].x2 - adjust_x;
    intersect_left.y1 = panel.model.line[Segment.LEFT].y1;
    intersect_left.y1 = panel.model.line[Segment.LEFT].y2;

    //Sett he right intersect line values
    intersect_right.x1 = panel.model.line[Segment.RIGHT].x1 + adjust_x;
    intersect_right.x2 = panel.model.line[Segment.RIGHT].x2 + adjust_x;
    intersect_right.y1 = panel.model.line[Segment.RIGHT].y1;
    intersect_right.y1 = panel.model.line[Segment.RIGHT].y2;

    //Get the left and right arc angles
    const radian_left = getArcAngles(
        center_x,
        center_y,
        inner_radius,
        intersect_left
    );
    const radian_right = getArcAngles(
        center_x,
        center_y,
        inner_radius,
        intersect_right
    );
    
    //Get the coordinates of one of the inner arc intercepts, we only need one
    const offset = getCoordinateFromAngle(
        center_x,
        center_y,
        inner_radius,
        radian_left.angle2    
    )

    //Set the y value of the coordinate to the lower offset; it's the same for both
    window.mask_lower.offset_left = offset.y - second_height;
    window.mask_lower.offset_right = offset.y - second_height; 
    
    //Set the inner arc values
    window.mask_arch.inner_radius = inner_radius;
    window.mask_arch.inner_start = radian_right.angle2;
    window.mask_arch.inner_end = radian_left.angle2;

    //Set the inches for second height
    const deci = 10000;
    window.model_inch.second_height = getHeightInch(second_height, renderData);    
    window.model_inch.second_height = Math.round(window.model_inch.second_height * deci) / deci;    

    //This is to account for the kentucky windage.
    //The left and right intercept can produce a NaN, because the circle is too small I believe
    if(Number.isNaN(radian_right.angle2)){
        window.mask_arch.inner_start = radian.angle1;
        window.mask_arch.inner_end = radian.angle2
        window.mask_lower.offset_left =0;
        window.mask_lower.offset_right =0;
    }
    
    return true;
}

//Update the window fragment arch values, return values if it can't be made into an arch due to dimensions
export const updateWindowArch = (window: WindowFragment, renderData: RenderData): boolean =>{
    
    //Set the left right top and bottom, to make things easier
    const left = window.model.line[Segment.LEFT].x1;
    const right = window.model.line[Segment.RIGHT].x1;
    const top = window.model.line[Segment.TOP].y1;
    const bottom = window.model.line[Segment.BOTTOM].y1;
    const intersect = window.model.line[Segment.BOTTOM]

    //Calc height and width
    const height = bottom - top;
    const width = right - left;
    
    //Height cannot be greater than the width
    if(height > width) return false;    

    //Set the center and get the outer angles
    const radius = getArcRadius(height, width);    
    const center_x = (left + right) /2;
    const center_y = top + radius;
    const radian = getArcAngles(
        center_x,
        center_y,
        radius,
        intersect
    );
    
    //Set the outer window values
    window.mask_arch.center_x = center_x;
    window.mask_arch.center_y = center_y;
    window.mask_arch.radius = radius;
    window.mask_arch.radian_start = radian.angle1;
    window.mask_arch.radian_end = radian.angle2;
            
    //Create an intersectline where the base will be
    let intersect_bottom = bottom - window.mask_window.gap;        
    if(renderData.window.length === 1){ //If it's the only window
        intersect_bottom -= (window.mask_window.gap * 0.5);
    }

    //Create the inner intersect line
    const inner_intersect = initLine();
    inner_intersect.x1 = left;
    inner_intersect.x2 = right;
    inner_intersect.y1 = intersect_bottom;
    inner_intersect.y2 = intersect_bottom;

    //Find the arc angles for the inner base intersect
    window.mask_arch.inner_radius = radius - (window.mask_window.gap * 1.4);
    const inner_radian = getArcAngles(
        center_x,
        center_y,
        window.mask_arch.inner_radius,
        inner_intersect
    )
    //Get the length between the two points on the circle
    const length = getChordLength(inner_radian.angle1, inner_radian.angle2, window.mask_arch.inner_radius);
    
    //Create the inner base line: the minus and plus 2 are just for small visual adjustments
    const inner_base = initLine();
    inner_base.x1 = center_x - (length /2) - 2;
    inner_base.x2 = center_x + (length /2) + 2;
    inner_base.y1 = inner_intersect.y1;
    inner_base.y2 = inner_intersect.y1;

    //Set the inner window properties
    window.mask_arch.inner_start = inner_radian.angle1;
    window.mask_arch.inner_end = inner_radian.angle2;
    window.mask_arch.inner_base = inner_base;
    
    return true;
}

//Get the x and y coordinate based on an angle in a circle
const getCoordinateFromAngle = (
    center_x: number,
    center_y: number,
    radius: number,
    radian: number
) =>{
    const x = center_x + (radius * Math.cos(radian));
    const y = center_y + (radius * Math.sin(radian));

    return {
        x: x, 
        y: y
    };
}

//Get intersect angles for a horizontal or vertical line
const getArcAngles = (
    center_x: number,
    center_y: number,
    radius: number,
    intersect: Line
) =>{
    //Calculations
    //deltaA = coord - center
    //deltaB = sqrt(r^2 - (deltaA)^2) 
    //theta_x1 = arctan2(deltaB, deltaA)
    //theta_x2 = arctan2(deltaB, -deltaA)
    //theta_y1 = arctan2(deltaA, deltaB)
    //theta_y2 = arctan2(-deltaA, deltaB)

    //Intercept angles where the intersect line crosses
    let theta1 =0;
    let theta2 =0;

    //If the intersect line is horizontal
    if(intersect.y1 - intersect.y2 === 0){
        const delta_y = intersect.y1 - center_y
        const delta_x = Math.sqrt(radius * radius - delta_y * delta_y);
        theta1 = Math.atan2(delta_y, delta_x);
        theta2 = Math.atan2(delta_y, -delta_x);
    }
    else{ //Intersect line is vertical        
        const delta_x = intersect.x1 - center_x;
        const delta_y = Math.sqrt(radius * radius - delta_x * delta_x);
        theta1 = Math.atan2(delta_y, delta_x);
        theta2 = Math.atan2(-delta_y, delta_x);
    }

    return{
        angle1: theta1,
        angle2: theta2
    }    
}

//Get the radius of a circle based on the delta and the range of the intersect
const getArcRadius = (delta: number, intersect_range: number): number =>{
    //For horizontal intersect lines:
    //Delta = height
    //Intersect range = width
    
    //For vertical intersect lines:
    //Delta = width
    //Intersect range = height

    //Calculations
    //xc = delta x
    //yc = delta y
    //
    //      xc^2 + yc^2
    // r = ----------------
    //         2yc
    
    const xc = intersect_range /2;
    const yc = delta;

    const radius = ((xc * xc) + (yc * yc)) / (2 * yc);

    return radius;
}

//Calculate the length between two points on a circle, given the angles in radians and a radius
const getChordLength = (radian1: number, radian2: number, radius: number): number =>{
    const delta = radian2 - radian1;

    const distance = 2 * radius * Math.sin(delta/2);

    return distance
}