import {RenderData} from "../../render/render_components/render_data/render_data";
import {constrainProductLine} from "../../constraints/functions_constrain";
import {ConstrainValues} from "../../constraints/constrain_value";

const ProductLine = (object_value: string, key_id: number, renderData: RenderData, constrainValues: ConstrainValues) =>{
    constrainProductLine(object_value, constrainValues, renderData);

    if(key_id === -1 && renderData.data.product_line.id >0){
        renderData.data.product_line.id =0;
        renderData.data.product_line.value = "";
    }
    else{
        renderData.data.product_line.value = object_value;
        renderData.data.product_line.id = key_id;
    }
}

export default ProductLine;