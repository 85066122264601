import {RenderData} from "../../render/render_components/render_data/render_data";
import {PanelFragment} from "../../render/render_components/panel/panel_fragment";

const Glass = (id_object: string, object_value: string, key_id: number, renderData: RenderData) =>{
    
    const fragment = renderData.window[renderData.active_fragment_id];
    const panel = fragment.panel[fragment.active_panel_id];
    
    //Outer Treatment
    if(id_object === "treatment_outer"){
        if(object_value === "Tempered"){            
            panel.data.glass.outer.tempered = true;
        }
        else{            
            panel.data.glass.outer.tempered = false;
        }
        applyAllValue(renderData, panel);
    }
    //Inner Treatment
    if(id_object === "treatment_inner"){
        if(object_value === "Tempered"){
            panel.data.glass.inner.tempered = true;
        }
        else{            
            panel.data.glass.inner.tempered = false;
        }
        applyAllValue(renderData, panel);
    }
    //Outer glass
    if(id_object === "group_outer_glass"){
        panel.data.glass.outer.value = object_value;
        panel.data.glass.outer.id = key_id;
        applyAllValue(renderData, panel);
    }
    //Inner glass 
    if(id_object === "group_inner_glass"){
        panel.data.glass.inner.value = object_value;
        panel.data.glass.inner.id = key_id
        applyAllValue(renderData, panel);
    }
    
    if(id_object === "apply_all"){
        if(object_value === "false"){
            renderData.all_window.apply_all_glass = false;
        }
        else{
            renderData.all_window.apply_all_glass = true;
        }
    }
}

//Apply all glass values to all windows and panels
const applyAllValue = (renderData: RenderData, panel: PanelFragment) =>{
    //If it needs to be applied to all the panels
    if(renderData.all_window.apply_all_glass){     
        //Iterate through all the windows
        renderData.window.forEach((window) =>{
            //Iterate through all the panels
            window.panel.forEach((current_panel) =>{
                //No need to set the panel that was just changed
                if(current_panel.id !== panel.id){
                    //Set the inner and outer glass values of the panel
                    current_panel.data.glass.inner.tempered = panel.data.glass.inner.tempered;
                    current_panel.data.glass.inner.value = panel.data.glass.inner.value;
                    current_panel.data.glass.inner.id = panel.data.glass.inner.id;
                    current_panel.data.glass.outer.tempered = panel.data.glass.outer.tempered;
                    current_panel.data.glass.outer.value = panel.data.glass.outer.value;
                    current_panel.data.glass.outer.id = panel.data.glass.outer.id;
                }
            })
        })
    }
}

export default Glass;