import Form from 'react-bootstrap/Form';
import {Fragment, useState, useEffect} from "react";
import {RecordData} from "../render/render_components/render_data/config_values";
import {ConstrainParameter} from "../constraints/constrain_value";

interface RadioProp {
    
    id_item: string; //Name of the accordion item
    group_name: string; //The name of the group of controls     
    DataHandler:(
        id_item: string, 
        id_object: string, 
        form_object: string, 
        object_value: string, 
        id_key: number
    ) => void, //Data handling function for when something is selected
    parameter: Map<string, RecordData>, //The map containing all the parameter values pulled from the server
    constrain: Map<number, ConstrainParameter>,
    id_select?: number //The ID of a selected radio button
}

export const ControlRadio = ({
        id_item,    
        group_name, 
        DataHandler,
        parameter,
        constrain,
        id_select
    }: RadioProp) => {  
        
    const [currentSelect, setCurrentSelect] = useState(-1); //The current selected radio button    
    
    //Handle when a change/selection is made
    const handleChange = (key_id: number, label_value: string) => {
        setCurrentSelect(key_id);
        DataHandler(id_item, group_name, "radio_group", label_value, key_id);        
    }    
    
    /* DO NOT DELETE THIS. It's part of a likely future solution.
    //Deselect an option if it was previously selected, but set to hidden or disabled.
    useEffect(() =>{
        const parameter = constrain.get(currentSelect);
        if(parameter !== undefined){
            if(parameter.is_disabled || parameter.is_hidden){
                setCurrentSelect(-1);
            }
        }
    }, [currentSelect, constrain])
    */
    
    //Determine if a radio button is checked
    const isChecked = (record_id: number): boolean =>{        
        
        
        /*********************************
        This is causing an error with set states during rendering.
        It can be replicated by creating a stack/mull, splitting, setting the top window
        to be a peak and then clicking on the bottom window. It doesn't happen all the time,
        but seems to happy quite frequently.
        The point of this code is meant to uncheck an option if the option was previously selected,
        but the user clicked something that hid or disabled the option. 
        Currently the code is calling the handleChange function to ultimately set the necessary
        values in renderData. I'm not sure how to address that at the moment.
        The commented out useEffect addresses removing an option being selected, but does not
        correct the data in renderData. This means the user could select an option, make it disabled and
        still submit the order with that option set from renderData.
        I think the solution may have to be in the hide and disabled functions. I think they're going to have to
        remove necessary values from renderData and then have a useEffect handle the deselection.
        For now, we'll leave the code below for handling this disabled.
        *********************************/
        /*
        //If the control is disabled or hidden, uncheck it
        const parameter = constrain.get(record_id);
        if(parameter !== undefined){                       
            if(currentSelect === record_id){
                if(parameter.is_disabled || parameter.is_hidden){                    
                    handleChange(-1, "");
                    return false;
                }
            }
        }
        */
        
        if(id_select !== undefined){ //If an id_select was provided
            if(record_id === id_select){                
                return true;
            }
        }
        else{ //Use the internal selection tracker            
            if(record_id === currentSelect){
                return true;
            }            
        }
        
        return false;
    }

    //Determine if a radio button is visible, based on constrain
    const isHidden = (record_id: number): boolean =>{
        const is_hidden = constrain.get(record_id)?.is_hidden;
        
        if(is_hidden !== undefined) return is_hidden;        

        //Return true if the record was not found; this shouldn't happen
        return true;
    }

    //Determine if a radio button is enabled, based on constrain
    const isDisabled = (record_id: number): boolean =>{
        const is_disabled = constrain.get(record_id)?.is_disabled;        

        if(is_disabled !== undefined) return is_disabled;         
        
        //Return true if a record was not found; this shouldn't happen
        return true;        
    }

    return (
        <Fragment>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', // Adjust number of columns and min width as needed
                gap: '5px', // Space between items
            }}>
                {Array.from(parameter.values())                
                    .filter(param =>!isHidden(param.id))
                    .map(param => (                  
                        <Form.Check
                            key={String(param.id)}
                            type="radio"
                            name={group_name}
                            id={group_name + "_" + String(param.id) + "_" + param.description}
                            label={param.description}
                            checked={isChecked(param.id)}
                            onChange={() => handleChange(param.id, param.description)}
                            disabled={isDisabled(param.id)}                            
                        />
                ))}
            </div>            
        </Fragment>     
    );
}