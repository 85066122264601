import {RenderData, initDataPoint} from "../../render/render_components/render_data/render_data";
import {resetWindow} from "../../render/render_components/window/functions_reset";
import {
    createOnePanel, 
    createTwoPanel, 
    createThreePanel, 
    createTopBottomPanel,
    createFourPanel, 
    setPanelOpen
} from "../../render/render_components/panel/functions_panel";
import {
    setWindowArch,
    setStraightArch,
    setHalfArch,
    setStraightHalfArch,
    setPeak,
    setTriangle,
    setRightTriangle,
    setStraightRightTriangle,
    setCircle,
    setOval,
    setHexagon,
    setOctagon
} from "../../render/render_components/window/functions_shape_window";
import {
    OpenDirection, 
    PanelType,
    ShapeDirection
} from "../../render/render_components/render_data/enum_render";
import {ConstrainValues} from "../../constraints/constrain_value";
import {constrainProductType} from "../../constraints/functions_constrain";
import {validatePosts} from "../../constraints/functions_validate_posts";
import {WindowFragment} from "../../render/render_components/window/window_fragment";
import {PanelFragment} from "../../render/render_components/panel/panel_fragment";

const ProductType = (
    object_value: string, 
    key_id: number,     
    renderData: RenderData,
    constrainValues: ConstrainValues    
) =>{
    
    //Get a copy of the window and reset any values that need to be reverted  
    const window = renderData.window[renderData.active_fragment_id];   
    resetWindow(window, renderData);

    window.data.product_type.value = object_value;
    window.data.product_type.id = key_id;
    
    //Execute functions, based on the product type that was selected
    switch(object_value){
        case "PW":
            createOnePanel(renderData, window);
        break;
        case "XO":            
            createTwoPanel(renderData, window);            
            setPanelOpen(window, PanelType.LEFT_OF_TWO, OpenDirection.RIGHT);
            validatePosts(renderData);
            applyAllScreen(window);
            setSpecialPatioDoor(renderData, window);
        break;
        case "OX":
            createTwoPanel(renderData, window);            
            setPanelOpen(window, PanelType.RIGHT_OF_TWO, OpenDirection.LEFT);
            validatePosts(renderData);
            applyAllScreen(window);
            setSpecialPatioDoor(renderData, window);
        break;
        case "XOO":
            createThreePanel(renderData, window);            
            setPanelOpen(window, PanelType.LEFT_OF_THREE, OpenDirection.RIGHT);
            validatePosts(renderData);
            applyAllScreen(window);
            setSpecialPatioDoor(renderData, window);
        break;
        case "OXO":
            createThreePanel(renderData, window);            
            setPanelOpen(window, PanelType.CENTER_OF_THREE, OpenDirection.LEFT);
            validatePosts(renderData);
            applyAllScreen(window);
            setSpecialPatioDoor(renderData, window);
        break;
        case "OOX":
            createThreePanel(renderData, window);            
            setPanelOpen(window, PanelType.RIGHT_OF_THREE, OpenDirection.LEFT);
            validatePosts(renderData);
            applyAllScreen(window);
            setSpecialPatioDoor(renderData, window);
        break;
        case "XOX":
            createThreePanel(renderData, window);            
            setPanelOpen(window, PanelType.LEFT_OF_THREE, OpenDirection.RIGHT);
            setPanelOpen(window, PanelType.RIGHT_OF_THREE, OpenDirection.LEFT);
            validatePosts(renderData);
            applyAllScreen(window);
            setSpecialPatioDoor(renderData, window);
        break;
        case "OXXO":
            createFourPanel(renderData, window);
            setPanelOpen(window, PanelType.LEFT_CENTER_OF_FOUR, OpenDirection.LEFT);
            setPanelOpen(window, PanelType.RIGHT_CENTER_OF_FOUR, OpenDirection.RIGHT);
            validatePosts(renderData);
            applyAllScreen(window);
            setSpecialPatioDoor(renderData, window);
        break;
        case "Single Hung":
            createTopBottomPanel(renderData, window);            
            setPanelOpen(window, PanelType.BOTTOM_OF_TWO, OpenDirection.UP);
            validatePosts(renderData);
            applyAllScreen(window);
        break;
        case "Factory Arch":
            setWindowArch(renderData);
        break;
        case "Straight Leg Arch":
            setStraightArch(renderData);
        break;
        case "R 1/4 Circle":
            setHalfArch(renderData, ShapeDirection.RIGHT);
        break;
        case "L 1/4 Circle":
            setHalfArch(renderData, ShapeDirection.LEFT);
        break;
        case "L Straight Leg Arch":
            setStraightHalfArch(renderData, ShapeDirection.LEFT);
        break;
        case "R Straight Leg Arch":
            setStraightHalfArch(renderData, ShapeDirection.RIGHT);
        break;
        case "Peak":
            setPeak(renderData);
        break;
        case "Triangle":
            setTriangle(renderData);
        break;
        case "R Triangle":
            setRightTriangle(renderData, ShapeDirection.RIGHT);
        break;
        case "L Triangle":
            setRightTriangle(renderData, ShapeDirection.LEFT);
        break;
        case "R Rake":
            setStraightRightTriangle(renderData, ShapeDirection.RIGHT);
        break;
        case "L Rake":
            setStraightRightTriangle(renderData, ShapeDirection.LEFT);
        break;
        case "Circle":
            setCircle(renderData);
        break;
        case "Oval":
            setOval(renderData);
        break;
        case "Hexagon":
            setHexagon(renderData);
        break;
        case "Octagon":
            setOctagon(renderData);
        break;
    }
    
    constrainProductType(object_value, constrainValues, renderData);

    renderData.render_frame = true;
}

//Apply a screen to all windows that can have one
const applyAllScreen = (window: WindowFragment) =>{
    
    //Iterate through all the panels of the window
    window.panel.forEach((panel: PanelFragment) =>{
        panel.data.has_screen = true;
    })
    
}

//Apply the special sqr ft option to patio doors with a special size
const setSpecialPatioDoor = (renderData: RenderData, window: WindowFragment) =>{
    //If it's a special size
    if(renderData.all_window.layout === "Patio Door"){
        if(!renderData.data.is_callout){
            renderData.window.forEach((window) =>{
                window.panel.forEach((panel) =>{
                    const key_name = "Special Sqrft";                   
                    const value = renderData.config_values.option.get(key_name);
                    
                    const data_point = initDataPoint("");
                    data_point.id = value!.id;
                    panel.data.option.set(key_name, data_point);
                    
                })
            })
        }
    }
}

export default ProductType;