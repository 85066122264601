import {WindowFragment} from "./window_fragment";
import {
    initMaskArch,
    initMaskLower,
    initMaskTriangle,
    initMaskCircle,
    initMaskBuffer
} from "../mask/mask_data";
import {createOnePanel} from "../panel/functions_panel";
import {RenderData} from "../render_data/render_data";
import {ShapeDirection} from "../render_data/enum_render";

//Reset a window 
export const resetWindow = (window: WindowFragment, renderData: RenderData) =>{    
    
    window.model.second_height =0;
    window.model.shape_direction = ShapeDirection.NONE;
    
    window.mask_arch = initMaskArch();
    window.mask_lower = initMaskLower();
    window.mask_triangle = initMaskTriangle();
    window.mask_circle = initMaskCircle();
    window.mask_buffer = initMaskBuffer();    

    createOnePanel(renderData, window);
}