import {ConstrainValues} from "./constrain_value";
import {setConstraint} from "./functions_constrain";
import {RenderData} from "../render/render_components/render_data/render_data";

const FLUSH = "Flush Fin";
const NAIL = "Nail On";
const BLACK = "Black Capstock";
const BRONZE = "Bronze";
const ALMOND = "Almond";
const MASON = "Mas Block";

//Constrain values associated with MF2
export const constrainMF2 = (constrainValues: ConstrainValues) =>{
    //Frame Type
    let constrain_type = constrainValues.frame_type;
    setConstraint(constrain_type, FLUSH, true, false);
    setConstraint(constrain_type, NAIL, true, false);

    //Frame Color
    constrain_type = constrainValues.frame_color;
    setConstraint(constrain_type, BLACK, true, false);
    setConstraint(constrain_type, ALMOND, true, false);
}

//Constrain SMF related values
export const constrainSMF = (constrainValues: ConstrainValues) =>{
    //Frame Type
    let constrain_type = constrainValues.frame_type;
    setConstraint(constrain_type, FLUSH, true, false);

    //Frame Color
    constrain_type = constrainValues.frame_color;
    setConstraint(constrain_type, BLACK, true, false);
    setConstraint(constrain_type, BRONZE, true, false);
}

//Constrain TMF related values
export const constrainTMF = (constrainValues: ConstrainValues) =>{
    //Color
    let constrain_type = constrainValues.frame_color;
    setConstraint(constrain_type, BRONZE, true, false);
}

//Constrain Aluminium Patio Doors
export const constrainMF2Door = (constrainValues: ConstrainValues, renderData: RenderData) =>{
    //Frame Type
    let constrain_type = constrainValues.frame_type;    
    setConstraint(constrain_type, NAIL, true, false);
    setConstraint(constrain_type, FLUSH, true, false);
    if(renderData.data.is_callout){
        setConstraint(constrain_type, MASON, true, false);
    }

    //Frame Color
    constrain_type = constrainValues.frame_color;
    setConstraint(constrain_type, ALMOND, true, false);
    setConstraint(constrain_type, BLACK, true, false);  
}

//Constrain Vinyl Patio Doors
export const constrainVinylDoor = (constrainValues: ConstrainValues, renderData: RenderData) =>{
    //Frame Type
    let constrain_type = constrainValues.frame_type;
    if(renderData.data.is_callout){
        setConstraint(constrain_type, MASON, true, false);
        setConstraint(constrain_type, FLUSH, true, false);
    }

    //Frame Color
    constrain_type = constrainValues.frame_color;
    setConstraint(constrain_type, BRONZE, true, false);
}

