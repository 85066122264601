import {OrderDetail, initOrderDetail} from "./order_detail";
import {OrderRequest} from "./order_request";
import {RenderData} from "../../render/render_components/render_data/render_data";
import {packOrderDetail} from "./functions_detail";

//Interface for a specific order
export interface OrderEntry{
    orderDetail: OrderDetail,
    index: number    
}

//Init the order entry object
export const initOrderEntry = (): OrderEntry =>({
    orderDetail: initOrderDetail(),
    index: -1,    
})

//Oder list interface
export interface OrderList{
    list: Map<number, OrderEntry>,
    selected_index: number,
    next_index: number
}

//Init the order list object
export const initOrderList =(): OrderList =>({
    list: new Map<number, OrderEntry>(),
    selected_index: -1,
    next_index: 0,    
})

//Add an order to the order list
export const addOrder = (renderData: RenderData, orderRequest: OrderRequest, orderList: OrderList, quantity: number) =>{
    //Init the entry and then set the order as the order request
    const entry = initOrderEntry();
    entry.index = orderList.next_index;
    
    //Pack the data into an order detail and assign it to the order entry
    entry.orderDetail = packOrderDetail(renderData, orderRequest);
    entry.orderDetail.quantity = quantity;
    
    //Set the entry in the map and iterate the index
    orderList.list.set(entry.index, entry);
    orderList.next_index ++;
}

//Remove an order from the order list
export const removeOrder = (order: OrderEntry, orderList: OrderList) =>{
    const entry = orderList.list.get(order.index);
    orderList.list.delete(entry!.index);
}