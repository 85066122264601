import {RenderData} from "../render_data/render_data";
import {WindowFragment} from "./window_fragment";
import {Segment, ShapeDirection} from "../render_data/enum_render";
import {
    updateWindowArch, 
    updateStraightArch, 
    updateHalfArch,
    updateStraightHalfArch
} from "./functions_arc";
import {
    updatePeak,
    updateTriangle,
    updateRightTriangle,
    updateStraightRightTriangle
} from "./functions_triangle";
import {
    updateCircle,
    updateOval,
    updateHexagon,
    updateOctagon
} from "./functions_circular";

//Set a window as an octagon
export const setOctagon = (renderData: RenderData) =>{
    const window = renderData.window[renderData.active_fragment_id];       
    updateOctagon(window);
}

//set a window as a hexagon
export const setHexagon = (renderData: RenderData) =>{
    const window = renderData.window[renderData.active_fragment_id];
    updateHexagon(window);
}

//Set a window as an oval
export const setOval = (renderData: RenderData) =>{
    const window = renderData.window[renderData.active_fragment_id];
    updateOval(window)
}

//Set a window as a circle
export const setCircle = (renderData: RenderData) =>{
    const window = renderData.window[renderData.active_fragment_id];
    updateCircle(window);
}

//Set a straight right triangle and orientate it based off the shape direction
export const setStraightRightTriangle = (renderData: RenderData, direction: ShapeDirection ) =>{
    const window = renderData.window[renderData.active_fragment_id];
    window.model.shape_direction = direction;    
    updateStraightRightTriangle(window, renderData);
}
//Set a right triangle, and orientate it based off the shape direction
export const setRightTriangle = (renderData: RenderData, direction: ShapeDirection) =>{
    const window = renderData.window[renderData.active_fragment_id];
    window.model.shape_direction = direction;
    window.model.second_height =0;
    updateRightTriangle(window, renderData);
}

//Set a window fragment to be a straight half arch
export const setStraightHalfArch = (renderData: RenderData, direction: ShapeDirection) =>{
    const window = renderData.window[renderData.active_fragment_id];
    window.model.shape_direction = direction;
    initSecondHeight(window);
    updateStraightHalfArch(window, renderData);
}

//Set a window fragment to be a half arch
export const setHalfArch = (renderData: RenderData, direction: ShapeDirection) =>{
    const window = renderData.window[renderData.active_fragment_id];
    window.model.shape_direction = direction;
    window.model.second_height =0;
    updateHalfArch(window, renderData);
}

//Set a window fragment to be an arch
export const setWindowArch = (renderData: RenderData) =>{
    const window = renderData.window[renderData.active_fragment_id];
    window.model.second_height =0;
    updateWindowArch(window, renderData);
}

//Set a window fragment to be a straight arch
export const setStraightArch = (renderData: RenderData) =>{
    const window = renderData.window[renderData.active_fragment_id];    
    updateStraightArch(window, renderData);
}

//Set a window fragment to be a peak
export const setPeak = (renderData: RenderData) =>{
    const window = renderData.window[renderData.active_fragment_id];        
    updatePeak(window, renderData);
}
//Set a window fragment to be a triangle
export const setTriangle = (renderData: RenderData) =>{
    const window = renderData.window[renderData.active_fragment_id];
    window.model.second_height =0;
    updateTriangle(window, renderData);
}

//Initialize the second height of the window
export const initSecondHeight = (window: WindowFragment) =>{
        
    const width = window.model.line[Segment.RIGHT].x1 - window.model.line[Segment.LEFT].x1;
    const height = window.model.line[Segment.BOTTOM].y1 - window.model.line[Segment.TOP].y1;
    
    //Set the second height, based on half and full top shapes
    if(width < height){
        switch(window.data.product_type.value){
            case "L Straight Leg Arch":
            case "R Straight Leg Arch":
            case "R Rake":
            case "L Rake":
                window.model.second_height = width;
            break;
            default:
                window.model.second_height = width /2;
        }
    }
    else{
        window.model.second_height = height/2;
    }
}